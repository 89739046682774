import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { Box, Chip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Button, Input, Select } from "antd";
import { useRecoilValue } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { FORWARDING_AUTH_SELECTORS } from "@sellernote/_shared/src/states/forwarding/auth";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { Port } from "@sellernote/_shared/src/types/common/common";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import {
  FetchTrelloListParams,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  checkIfCustomsClearanceHasStarted,
  checkIfETDExpiresWhenMoving,
  checkIfETDExpiresWhenScheduling,
  checkIsBlNumberHasRegistered,
  checkIsConfirmFlag,
  checkIsCustomsPartnerHasRegistered,
  checkIsInlandFarePaymentRequest,
  checkIsInlandPartnerHasRegistered,
} from "@sellernote/_shared/src/utils/forwarding/trello";

import CheckIsPaymentModal from "./CheckPaymentModal";
import ScheduleConfirmModal from "./ScheduleConfirmModal";
import TrelloCardCheckBox from "./TrelloCardCheckBox";
import TrelloCardList, { CardListData } from "./TrelloCardList";
import TrelloDetail from "./TrelloDetail";

const { Option } = Select;

const TrelloList = ({
  trelloListData,
  portList,
  adminData,
  fetchTrelloListParams,
  setIsAutoFetch,
  isAutoFetch,
  setFetchTrelloListParams,
}: {
  trelloListData: TrelloBidList[];
  portList: Port[];
  adminData: ForwardingAdminUserListItem[];
  fetchTrelloListParams: FetchTrelloListParams;
  setIsAutoFetch: React.Dispatch<React.SetStateAction<boolean>>;
  isAutoFetch: boolean;
  setFetchTrelloListParams: (value: FetchTrelloListParams) => void;
}) => {
  const trelloCardId = useRecoilValue(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const history = useHistory();

  const currentUser = useRecoilValue(FORWARDING_AUTH_SELECTORS.CURRENT_MANAGER);

  const [showModal, setShowModal] = useState(false);
  const [bidId, setBidId] = useState(0);
  const [showScheduleConfirmModal, setShowScheduleConfirmModal] =
    useState(false);
  const [showCheckIsPaymentModal, setShowCheckIsPaymentModal] = useState(false);
  const [cardEndAddress, setCardEndAddress] = useState<null | string>(null);

  const openTrelloCardDetail = useCallback((bidId: number) => {
    setBidId(bidId);
    setShowModal(true);
  }, []);

  useEffect(() => {
    if (trelloCardId) {
      openTrelloCardDetail(trelloCardId);
    }
  }, [history.action, openTrelloCardDetail, trelloCardId]);

  const onClickSearch = useCallback(() => {
    setIsAutoFetch(true);
  }, [setIsAutoFetch]);

  const handleChangeManagerFilter = useCallback(
    (e: number) => {
      setFetchTrelloListParams({ ...fetchTrelloListParams, adminId: e });
    },
    [fetchTrelloListParams, setFetchTrelloListParams]
  );

  const handleChangeCompanyFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isAutoFetch) {
        setIsAutoFetch(false);
      }
      setFetchTrelloListParams({
        ...fetchTrelloListParams,
        company: e.target.value,
      });
    },
    [
      fetchTrelloListParams,
      isAutoFetch,
      setFetchTrelloListParams,
      setIsAutoFetch,
    ]
  );

  const handleChangeBidIdFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isAutoFetch) {
        setIsAutoFetch(false);
      }

      setFetchTrelloListParams({
        ...fetchTrelloListParams,
        bidId: Number(e.target.value) ? Number(e.target.value) : undefined,
      });
    },
    [
      fetchTrelloListParams,
      isAutoFetch,
      setFetchTrelloListParams,
      setIsAutoFetch,
    ]
  );

  const handleChangeBLFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isAutoFetch) {
        setIsAutoFetch(false);
      }
      setFetchTrelloListParams({
        ...fetchTrelloListParams,
        BL: e.target.value,
      });
    },
    [
      fetchTrelloListParams,
      isAutoFetch,
      setFetchTrelloListParams,
      setIsAutoFetch,
    ]
  );

  const handleTrelloCardClick = useCallback(
    (cardData: TrelloBidList) => {
      if (
        APP_NAME === "partner-admin" ||
        currentUser?.authority === "finance"
      ) {
        openTrelloCardDetail(cardData.id);
        return;
      }

      if (
        checkIfETDExpiresWhenScheduling(cardData) ||
        checkIfETDExpiresWhenMoving(cardData)
      ) {
        setShowScheduleConfirmModal(true);
        setBidId(cardData.id);
        return;
      }

      if (checkIsInlandFarePaymentRequest(cardData)) {
        setShowCheckIsPaymentModal(true);
        setBidId(cardData.id);
        setCardEndAddress(cardData.endAddress);
        return;
      }

      openTrelloCardDetail(cardData.id);
    },
    [currentUser?.authority, openTrelloCardDetail]
  );

  const getTrelloCardCustomsChip = useCallback((cardData: TrelloBidList) => {
    if (cardData.management.customsStatus === "inProgress") {
      return (
        <Chip component="div" size="small" label={"통관 중"} color={"info"} />
      );
    }
    if (cardData.management.customsStatus === "reviewDocuments") {
      return (
        <Chip
          component="div"
          size="small"
          label={"서류검토 요청"}
          color={"success"}
        />
      );
    }
    if (cardData.management.customsStatus === "quarantine") {
      return (
        <Chip
          component="div"
          size="small"
          label={"검역 진행 중"}
          color={"warning"}
        />
      );
    }
    if (cardData.management.customsStatus === "selectivity") {
      return (
        <Chip
          component="div"
          size="small"
          label={"검사 선별"}
          color={"error"}
        />
      );
    }
  }, []);

  const getTrelloCardCommonBody = useCallback(
    (trelloListData: TrelloBidList) => {
      return (
        <>
          <Typography sx={{ fontSize: 12 }} variant="body2">
            {trelloListData.management.memo}
          </Typography>

          <Typography
            variant="body2"
            component={"div"}
            sx={{ fontWeight: "bold", color: grey[700] }}
          >
            {trelloListData.id}{" "}
            {trelloListData.poNumber.length > 0 &&
              `${
                trelloListData.poNumber.length > 1
                  ? `/ ${trelloListData.poNumber[0]} 외 ${trelloListData.poNumber.length}`
                  : `/ ${trelloListData.poNumber[0]}`
              }`}
          </Typography>

          {trelloListData.management.ETA && trelloListData.management.ETD && (
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", color: grey[700] }}
            >
              {trelloListData.management.ETD} ~ {trelloListData.management.ETA}
            </Typography>
          )}
        </>
      );
    },
    []
  );

  const getTrelloCardTitle = useCallback((cardData: TrelloBidList) => {
    return (
      <Box>
        <Typography
          sx={{
            fontSize: 14,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontWeight: "bold",
          }}
        >
          {cardData.team.company ?? "-"}
        </Typography>

        <Typography
          sx={{
            fontSize: 12,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          ({cardData.team.name ?? "-"})
        </Typography>
      </Box>
    );
  }, []);

  const salesName = useMemo(() => {
    return (
      adminData &&
      adminData.filter((v: ForwardingAdminUserListItem) => {
        return v.isForwardingManager === true;
      })
    );
  }, [adminData]);

  const cardListData: CardListData[] = useMemo(() => {
    return [
      {
        lineKey: "1",
        lineTitle: "수출자 컨택 전",
        cards: trelloListData
          .filter(
            (v: TrelloBidList) => v.projectStatus === "beforeContactPartner"
          )
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {getTrelloCardCustomsChip(v)}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "2",
        lineTitle: "수출자 컨택 중",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "contactingPartner")
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {getTrelloCardCustomsChip(v)}
                  {APP_NAME === "shipda-admin" && (
                    <TrelloCardCheckBox label="스케줄 안내" />
                  )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "3",
        lineTitle: "출항 준비 중",
        cards: trelloListData
          .filter(
            (v: TrelloBidList) =>
              v.projectStatus === "scheduling" ||
              v.projectStatus === "containerCarryOut" ||
              v.projectStatus === "containerPickup" ||
              v.projectStatus === "gateIn" ||
              v.projectStatus === "loaded"
          )
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              projectStatus: v.projectStatus,
              isRedBorder:
                APP_NAME === "shipda-admin" &&
                checkIfETDExpiresWhenScheduling(v),
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {getTrelloCardCustomsChip(v)}
                  {APP_NAME === "shipda-admin" && (
                    <>
                      <TrelloCardCheckBox
                        label="스케줄 재확인"
                        checkedValue={checkIsConfirmFlag(v)}
                      />

                      <TrelloCardCheckBox
                        label="BL(AWB) 입력"
                        checkedValue={checkIsBlNumberHasRegistered(v)}
                      />

                      <TrelloCardCheckBox
                        label="BL(AWB) 업로드"
                        checkedValue={v.attachmentFlag}
                      />

                      {v.freightType === "FCL" && (
                        <TrelloCardCheckBox
                          label="공 컨테이너 반출"
                          checkedValue={
                            v.projectStatus === "containerCarryOut" ||
                            v.projectStatus === "containerPickup" ||
                            v.projectStatus === "gateIn" ||
                            v.projectStatus === "loaded" ||
                            v.projectStatus === "moving"
                          }
                        />
                      )}

                      {v.freightType === "FCL" && (
                        <TrelloCardCheckBox
                          label="현지 컨테이너 픽업완료"
                          checkedValue={
                            v.projectStatus === "containerPickup" ||
                            v.projectStatus === "gateIn" ||
                            v.projectStatus === "loaded" ||
                            v.projectStatus === "moving"
                          }
                        />
                      )}

                      {/* TODO: 운송 리뉴얼에서 빠진 기능 추후에 추가 예정 */}
                      {v.freightType !== "FCL" && (
                        <TrelloCardCheckBox label="현지 CFS 반입완료" />
                      )}

                      <TrelloCardCheckBox
                        label="현지 터미널 반입완료"
                        checkedValue={
                          v.projectStatus === "gateIn" ||
                          v.projectStatus === "loaded" ||
                          v.projectStatus === "moving"
                        }
                      />

                      <TrelloCardCheckBox
                        label="선적 완료"
                        checkedValue={
                          v.projectStatus === "loaded" ||
                          v.projectStatus === "moving"
                        }
                      />

                      <TrelloCardCheckBox
                        label="출항 완료"
                        checkedValue={v.projectStatus === "moving"}
                      />
                    </>
                  )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "4",
        lineTitle: "출항 완료",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "moving")
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              isRedBorder:
                (APP_NAME === "shipda-admin" &&
                  checkIfETDExpiresWhenMoving(v)) ||
                (APP_NAME === "partner-admin" &&
                  checkIfCustomsClearanceHasStarted(v)),
              isBlueBorder:
                APP_NAME === "shipda-admin" &&
                v.management.shippingScheduleUpdate,
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {getTrelloCardCustomsChip(v)}

                  {APP_NAME === "shipda-admin" && (
                    <TrelloCardCheckBox
                      label="스케줄 재확인"
                      checkedValue={checkIsConfirmFlag(v)}
                    />
                  )}
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "5",
        lineTitle: "입항완료 및 통관 준비",
        cards: trelloListData
          .filter(
            (v: TrelloBidList) =>
              v.projectStatus === "portEntryAndPrepareCustoms"
          )
          .map((v: TrelloBidList) => {
            const customsAccountPayable = v.accountPayables.filter(
              ({ domain }) => domain === "customs"
            )[0];

            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              isRedBorder:
                APP_NAME === "partner-admin" &&
                checkIfCustomsClearanceHasStarted(v),

              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {getTrelloCardCustomsChip(v)}

                  <TrelloCardCheckBox
                    label="통관 필수서류 구비완료"
                    checkedValue={v.attachmentFlag}
                  />

                  <TrelloCardCheckBox
                    label="관세사 지정하기"
                    checkedValue={checkIsCustomsPartnerHasRegistered(v)}
                  />

                  <TrelloCardCheckBox
                    label="통관 서류 및 거래명세서 발송(관세사)"
                    checkedValue={v.management.detailStatus === "sendedCustoms"}
                  />

                  <TrelloCardCheckBox label="수입결재통보" />
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "6",
        lineTitle: "정산 요청",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "payment")
          .map((v: TrelloBidList) => {
            const customsAccountPayable = v.accountPayables.filter(
              ({ domain }) => domain === "customs"
            )[0];

            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {getTrelloCardCustomsChip(v)}

                  {customsAccountPayable.customsReviewRequestedFlag && (
                    <Box
                      sx={{
                        background: "#50C878",
                        width: "100px",
                        textAlign: "center",
                        border: "1px solid white",
                      }}
                    >
                      <p className="review-request">서류검토 요청</p>
                    </Box>
                  )}

                  <TrelloCardCheckBox
                    label="거래명세서 발송(고객)"
                    checkedValue={
                      v.management.customsPaymentFlag || v.invoices[0]?.isSended
                    }
                  />

                  <TrelloCardCheckBox label="수입신고수리" />
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "7",
        lineTitle: "통관 완료",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "completeCustoms")
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              isRedBorder:
                APP_NAME === "shipda-admin" &&
                checkIsInlandFarePaymentRequest(v),
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  {v.endAddress && (
                    <TrelloCardCheckBox
                      label="기사 정보 입력"
                      checkedValue={checkIsInlandPartnerHasRegistered(v)}
                    />
                  )}

                  {v.containCustoms && (
                    <TrelloCardCheckBox
                      label="수입신고필증/계산서 업로드"
                      checkedValue={v.attachmentFlag}
                    />
                  )}

                  <TrelloCardCheckBox
                    label="반출 신고"
                    checkedValue={v.management.confirmFlag}
                  />
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "8",
        lineTitle: "내륙 운송 중",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "delivering")
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: <Box>{getTrelloCardCommonBody(v)}</Box>,
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
      {
        lineKey: "9",
        lineTitle: "종료",
        cards: trelloListData
          .filter((v: TrelloBidList) => v.projectStatus === "finished")
          .map((v: TrelloBidList) => {
            return {
              forwardingManagerId: v.management.forwardingManagerId,
              key: v.id.toString(),
              title: getTrelloCardTitle(v),
              freightType: `${v.freightType}/${v.incoterms}`,
              description: (
                <Box>
                  {getTrelloCardCommonBody(v)}

                  <TrelloCardCheckBox
                    label="정산 하기"
                    checkedValue={checkIsInlandPartnerHasRegistered(v)}
                  />
                </Box>
              ),
              handleTrelloCardClick: () => handleTrelloCardClick(v),
            };
          }),
      },
    ];
  }, [
    getTrelloCardCommonBody,
    getTrelloCardCustomsChip,
    getTrelloCardTitle,
    handleTrelloCardClick,
    trelloListData,
  ]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Select
          style={{
            width: "100%",
            maxWidth: 224,
            fontWeight: "normal",
            marginLeft: 16,
          }}
          value={fetchTrelloListParams.adminId}
          onSelect={(e: number) => handleChangeManagerFilter(e)}
        >
          <Option value={undefined}>전체</Option>
          {salesName.map((v: ForwardingAdminUserListItem, index: number) => {
            return (
              <Option key={v.id} value={v.id}>
                {v.name}
              </Option>
            );
          })}
        </Select>

        <Input
          value={fetchTrelloListParams.company}
          onChange={handleChangeCompanyFilter}
          style={{ width: 200, marginLeft: 16 }}
          placeholder="회사명"
          onPressEnter={onClickSearch}
          allowClear
        ></Input>
        <Button onClick={onClickSearch}>
          <SearchOutlined />
        </Button>

        <Input
          onChange={handleChangeBidIdFilter}
          value={fetchTrelloListParams.bidId}
          style={{ width: 200, marginLeft: 16 }}
          placeholder="의뢰번호 입력"
          onPressEnter={onClickSearch}
          allowClear
        ></Input>

        <Button onClick={onClickSearch}>
          <SearchOutlined />
        </Button>

        <Input
          onChange={handleChangeBLFilter}
          value={fetchTrelloListParams.BL}
          style={{ width: 200, marginLeft: 16 }}
          placeholder="BL번호 입력"
          onPressEnter={onClickSearch}
          allowClear
        ></Input>

        <Button onClick={onClickSearch}>
          <SearchOutlined />
        </Button>
      </div>

      <TrelloCardList cardListData={cardListData} />

      {showModal && (
        <TrelloDetail
          showModal={showModal}
          setShowModal={setShowModal}
          bidId={bidId}
          portList={portList}
          trelloListData={trelloListData}
          fetchTrelloListParams={fetchTrelloListParams}
        ></TrelloDetail>
      )}

      {showScheduleConfirmModal && (
        <ScheduleConfirmModal
          onTrelloDetailOpen={openTrelloCardDetail}
          showScheduleConfirmModal={showScheduleConfirmModal}
          setShowScheduleConfirmModal={setShowScheduleConfirmModal}
          bidId={bidId}
        />
      )}

      {showCheckIsPaymentModal && (
        <CheckIsPaymentModal
          onTrelloDetailOpen={openTrelloCardDetail}
          showCheckIsPaymentModal={showCheckIsPaymentModal}
          setShowCheckIsPaymentModal={setShowCheckIsPaymentModal}
          bidId={bidId}
          endAddress={cardEndAddress}
        ></CheckIsPaymentModal>
      )}
    </div>
  );
};

export default TrelloList;
