import { useCallback, useMemo } from "react";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { InvoiceDataFeeItem } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import {
  InvoiceType,
  IssuedInvoice,
  TrelloBidDetail,
  TrelloBidInvoice,
  TrelloSalesManagementTableData,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { removeCommaAndToNumber } from "@sellernote/_shared/src/utils/common/string";
import {
  FARECON_DOMESTIC_PARTNER_ID,
  FARECON_FOREIGN_PARTNER_ID,
  GLOBELINK_KOREA_PARTNER_ID,
  GLOBELINK_SHANGHAI_EXPRESS_PARTNER_ID,
  GLOBELINK_SHANGHAI_FCL_PARTNER_ID,
  GLOBELINKSHA_PARTNER_ID,
} from "@sellernote/_shared/src/utils/forwarding/partner";

export default function useTrelloSalesManagementTableData(
  trelloDetailData: TrelloBidDetail
) {
  const isPartnerAdmin = APP_NAME === "partner-admin";

  /**
   * 대납세금 계산 함수
   * 대납세금 taxFee 배열에서 itemPrice 모든 합
   */
  const calculateTotalTaxFee = (taxFeeArr: InvoiceDataFeeItem[]) => {
    if (taxFeeArr.length === 0) return 0;

    const itemPriceArr = taxFeeArr.map((v) =>
      removeCommaAndToNumber(v.itemPrice)
    );

    return itemPriceArr.reduce((acc, cur) => acc + cur, 0);
  };

  /**
   * 공급가액 계산 함수
   * 공급가액은  totalPrice - taxFee 배열에서 itemPrice 모든 합
   */
  const calculateTotalPrice = useCallback(
    (totalPrice: string, taxFeeArr: InvoiceDataFeeItem[]) => {
      return toThousandUnitFormat(
        removeCommaAndToNumber(totalPrice) - calculateTotalTaxFee(taxFeeArr)
      );
    },
    []
  );

  const hasRefundPartner = useMemo(() => {
    return trelloDetailData.accountPayables.reduce((acc, cur) => {
      if (
        cur.partner?.id === GLOBELINK_SHANGHAI_FCL_PARTNER_ID ||
        cur.partner?.id === FARECON_FOREIGN_PARTNER_ID ||
        cur.partner?.id === GLOBELINKSHA_PARTNER_ID ||
        cur.partner?.id === GLOBELINK_SHANGHAI_EXPRESS_PARTNER_ID ||
        cur.partner?.id === FARECON_DOMESTIC_PARTNER_ID ||
        cur.partner?.id === GLOBELINK_KOREA_PARTNER_ID
      ) {
        acc = true;
      }
      return acc;
    }, false);
  }, [trelloDetailData.accountPayables]);

  const checkIsIssued = (issuedInvoice: IssuedInvoice[]) => {
    if (!issuedInvoice) {
      return false;
    }
    if (issuedInvoice.length < 1) {
      return false;
    }

    //issuedInvoice의 isIssued 중 하나라도 false가 있으면 false다
    const isIssued = issuedInvoice.every((v) => {
      return v.isIssued;
    });

    return isIssued;
  };

  const getSalesManagementTableData = useCallback(
    (invoiceData: TrelloBidInvoice | null, invoiceType: InvoiceType) => {
      if (!invoiceData) {
        return {
          invoiceType,
        };
      }

      return {
        invoiceId: invoiceData.id,
        invoiceType: invoiceType,
        isCustomSended: invoiceData.isCustomSended,
        isTemporary: invoiceData.isTemporary,
        totalPrice: calculateTotalPrice(
          invoiceData.totalPrice,
          invoiceData.taxFee
        ),
        vatPrice: invoiceData.vatPrice,
        taxFee: toThousandUnitFormat(calculateTotalTaxFee(invoiceData.taxFee)),
        finalPrice: invoiceData.finalPrice,
        name: invoiceData.name,
        lastUpdatedAt: invoiceData.lastUpdatedAt,
        depositDate: invoiceData.depositDate,
        issuedInvoices: invoiceData.issuedInvoices?.length > 0 ? true : false,
        isIssued: checkIsIssued(invoiceData.issuedInvoices),
        isSended: invoiceData.isSended,
        downloadKey: invoiceData.attachment?.key,
        invoiceResult: invoiceData?.invoiceResult,
        paymentSubInvoice: invoiceData.paymentSubInvoice,
      };
    },
    [calculateTotalPrice]
  );

  const getMultipleSalesManagementTableData = useCallback(
    (invoiceData: TrelloBidInvoice[]) => {
      /**
       * 파트너 어드민에서는 etcDeposit, wareHouse invoice가 없을 때 빈배열로 오지 않음
       * 따라서 첫 번째 배열의 id 여부로 유무를 판단
       */
      if (isPartnerAdmin && !invoiceData[0].id) return [];

      return invoiceData
        .map((v) => {
          return {
            invoiceId: v.id,
            invoiceType: v.invoiceType,
            isCustomSended: v.isCustomSended,
            isTemporary: v.isTemporary,
            totalPrice: calculateTotalPrice(v.totalPrice, v.taxFee),
            vatPrice: v.vatPrice,
            taxFee: toThousandUnitFormat(calculateTotalTaxFee(v.taxFee)),
            finalPrice: v.finalPrice,
            name: v.name,
            lastUpdatedAt: v.lastUpdatedAt,
            depositDate: v.depositDate,
            issuedInvoices: v.issuedInvoices?.length > 0 ? true : false,
            isIssued: checkIsIssued(v.issuedInvoices),
            isSended: v.isSended,
            downloadKey: v.attachment?.key,
            invoiceResult: v?.invoiceResult,
            paymentSubInvoice: v.paymentSubInvoice,
          };
        })
        .reverse();
    },
    [calculateTotalPrice, isPartnerAdmin]
  );

  const trelloSalesManagementTableData = useMemo(() => {
    let tableData: TrelloSalesManagementTableData[] = [
      getSalesManagementTableData(trelloDetailData.bidInvoice, "invoice"),
    ];

    if (hasRefundPartner && !isPartnerAdmin) {
      tableData = [
        ...tableData,
        getSalesManagementTableData(trelloDetailData.refundInvoice, "refund"),
      ];
    }

    if (trelloDetailData.warehouseInvoice) {
      tableData = [
        ...tableData,
        ...getMultipleSalesManagementTableData(
          trelloDetailData.warehouseInvoice
        ),
      ];
    }

    if (trelloDetailData.etcDepositInvoice) {
      tableData = [
        ...tableData,
        ...getMultipleSalesManagementTableData(
          trelloDetailData.etcDepositInvoice
        ),
      ];
    }

    return tableData;
  }, [
    getMultipleSalesManagementTableData,
    getSalesManagementTableData,
    hasRefundPartner,
    isPartnerAdmin,
    trelloDetailData.bidInvoice,
    trelloDetailData.etcDepositInvoice,
    trelloDetailData.refundInvoice,
    trelloDetailData.warehouseInvoice,
  ]);

  return { trelloSalesManagementTableData };
}
