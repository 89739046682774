import { TableHeadFilterOption } from "@sellernote/_shared-for-admin/src/hooks/common/useTableHeadFilter";

import {
  FreightType,
  Incoterms,
  VolumeSizeUnit,
  WeightUnit,
} from "../../types/common/common";
import {
  AdminBidApplyAirBasicFare,
  AdminBidApplyConsolidationBasicFare,
  AdminBidApplyFclBasicFare,
  AdminBidApplyLclBasicFare,
  AdminBidApplyOceanTicketBasicFare,
  BidCopyStatus,
  BidCopyTarget,
} from "../../types/forwarding/adminBid";
import { BidServiceType } from "../../types/forwarding/bid";

const ADMIN_BID_CONTAINER_TYPE_OPTION_LIST = [
  { label: "20DRY", value: "20DRY" },
  { label: "40DRY", value: "40DRY" },
  { label: "40HQ", value: "40HQ" },
  { label: "20RF", value: "20RF" },
  { label: "40RF", value: "40RF" },
  { label: "20OT", value: "20OT" },
  { label: "40OT", value: "40OT" },
  { label: "20OT(OH)", value: "20OT(OH)" },
  { label: "40OT(OH)", value: "40OT(OH)" },
  { label: "20FR", value: "20FR" },
  { label: "40FR", value: "40FR" },
  { label: "20FR(OH)", value: "20FR(OH)" },
  { label: "40FR(OH)", value: "40FR(OH)" },
  { label: "20FR(OW)", value: "20FR(OW)" },
  { label: "40FR(OW)", value: "40FR(OW)" },
  { label: "20FR(OWH)", value: "20FR(OWH)" },
  { label: "40FR(OWH)", value: "40FR(OWH)" },
];

const ADMIN_BID_WAREHOUSE_OPTION_LIST = [
  { label: "위해", value: 16 },
  { label: "상해", value: 12 },
  { label: "광저우", value: 14 },
  { label: "이우", value: 13 },
  { label: "심천", value: 15 },
];

const ADMIN_BID_PRODUCTS_INFO_BASIC_FORM = {
  isDangerous: false,
  isDouble: false,
  name: "",
  packingType: "pallets",
  quantity: 0,
  unitSupply: 0,
  weight: 0,
  weightUnit: "KG" as WeightUnit,
  height: 0,
  horizontal: 0,
  vertical: 0,
  volumeUnit: "CM" as VolumeSizeUnit,
};

const ADMIN_BID_SERVICE_TYPE_OPTION_LIST: TableHeadFilterOption<BidServiceType>[] =
  [
    {
      label: "일반 의뢰",
      value: "general",
    },
    {
      label: "오션티켓",
      value: "oceanTicket",
    },
    {
      label: "특송",
      value: "consolidation",
    },
  ];

const ADMIN_BID_APPLY_FCL_BASIC_FARE: Record<
  AdminBidApplyFclBasicFare,
  number
> = {
  oceanFreight: 1,
  bafCaf: 1,
  lss: 1,
  totalAdditionalFee: 1,
  thc: 1,
  wfg: 1,
  docFee: 1,
  doFee: 1,
  hc: 1,
  ccc: 1,
  shuttleFee: 1,
  cfsCharge: 1,
  tc: 1,
  ftaCertificateAgencyFee: 1,
  customsFee: 1,
  VATFee: 1,
  inspectionFee: 1,
};

const ADMIN_BID_APPLY_LCL_BASIC_FARE: Record<
  AdminBidApplyLclBasicFare,
  number
> = {
  oceanFreight: 1,
  bafCaf: 1,
  lss: 1,
  totalAdditionalFee: 1,
  thc: 1,
  wfg: 1,
  docFee: 1,
  doFee: 1,
  hc: 1,
  ccc: 1,
  shuttleFee: 1,
  cfsCharge: 1,
  tc: 1,
  ftaCertificateAgencyFee: 1,
  customsFee: 1,
  VATFee: 1,
  inspectionFee: 1,
};

const ADMIN_BID_APPLY_AIR_BASIC_FARE: Record<
  AdminBidApplyAirBasicFare,
  number
> = {
  airFreight: 1,
  totalAdditionalFee: 1,
  thc: 1,
  docFee: 1,
  hc: 1,
  tc: 1,
  ftaCertificateAgencyFee: 1,
  customsFee: 1,
  VATFee: 1,
  inspectionFee: 1,
};

const ADMIN_BID_APPLY_OCEANTICKET_SG_BASIC_FARE: Record<
  AdminBidApplyOceanTicketBasicFare,
  number
> = {
  oceanPerFee: 1,
  tc: 1,
  basicRate: 120,
  dutyTaxFee: 1,
  insuranceFee: 1,
  ftaCertificateAgencyFee: 50,
  customsFee: 1,
  VATFee: 1,
  inspectionFee: 1,
};

const ADMIN_BID_APPLY_OCEANTICKET_BASIC_FARE: Record<
  AdminBidApplyOceanTicketBasicFare,
  number
> = {
  oceanPerFee: 1,
  tc: 1,
  basicRate: 100000,
  dutyTaxFee: 1,
  inspectionFee: 1,
  insuranceFee: 1,
  ftaCertificateAgencyFee: 50,
  customsFee: 1,
  VATFee: 1,
};

const ADMIN_BID_APPLY_CONSOLIDATION_BASiC_FARE: Record<
  AdminBidApplyConsolidationBasicFare,
  number
> = {
  oceanFreight: 1,
  docFee: 25000,
  tc: 1,
  ftaCertificateAgencyFee: 50,
  customsFee: 1,
  VATFee: 1,
  inspectionFee: 1,
};

const ADMIN_BID_APPLY_CURRENCY_OPTION_LIST = [
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "CNY",
    value: "CNY",
  },
  {
    label: "GBP",
    value: "GBP",
  },
  {
    label: "KRW",
    value: "KRW",
  },
];

const ADMIN_BID_FREIGHT_TYPE_OPTION_LIST: TableHeadFilterOption<FreightType>[] =
  [
    {
      label: "FCL",
      value: "FCL",
    },
    {
      label: "LCL",
      value: "LCL",
    },
    {
      label: "AIR",
      value: "AIR",
    },
  ];

const ADMIN_BID_INCOTERMS_OPTION_LIST: TableHeadFilterOption<Incoterms>[] = [
  {
    label: "FOB",
    value: "FOB",
  },
  {
    label: "CFR",
    value: "CFR",
  },
  {
    label: "CPT",
    value: "CPT",
  },
  {
    label: "EXW",
    value: "EXW",
  },
  {
    label: "FCA",
    value: "FCA",
  },
  {
    label: "CIF",
    value: "CIF",
  },
  {
    label: "CIP",
    value: "CIP",
  },
  {
    label: "DDP",
    value: "DDP",
  },
  {
    label: "DAP",
    value: "DAP",
  },
];

const ADMIN_BID_COPY_STATUS: {
  name: BidCopyStatus;
  label: string;
}[] = [
  { name: "committed", label: "의뢰 받음" },
  { name: "estimating", label: "견적 산출 중" },
  { name: "waiting", label: "사용자 선택 대기 중" },
  { name: "waitingForExporterInfo", label: "수출자 정보 입력 대기 중" },
  { name: "beforeContactPartner", label: "수출자 컨택 전" },
  { name: "contactingPartner", label: "수출자 컨택 중" },
];

const BID_COPY_TARGET_FOR_CHECKBOX: TableHeadFilterOption<
  keyof BidCopyTarget
>[] = [
  {
    value: "defaultInfo",
    label: "기본 정보, 운송 정보(운영/체크포인트), 물품상세",
  },
  {
    value: "copyEstimatePartner",
    label: "견적파트너",
  },
  {
    value: "contactEstimatePartner",
    label: "견적파트너 컨택",
  },
  {
    value: "copyEstimate",
    label: "견적서",
  },

  {
    value: "submitEstimate",
    label: "견적서 제출",
  },
  {
    value: "acceptEstimate",
    label: "견적서 수락",
  },
  {
    value: "copyExporterInfo",
    label: "수출자 정보",
  },
  {
    value: "copyBookingPartner",
    label: "지급유형, 부킹파트너",
  },
  {
    value: "contactBookingPartner",
    label: "부킹파트너 컨택",
  },
];
const ADMIN_BID_QUOTATION_FCL_COMMENT =
  "* 견적서 내 회색 행은 VAT 별도입니다.\n* 견적서 내 WFG,PFS,보험료는 비과세 항목입니다.\n- 관부가세는 HS-CODE에 따라 실비청구됩니다.\n- 환율은 외환은행 기준 전신환매도율 평균값으로 청구됩니다.\n- 실제 선적된 부피/중량 기준으로 청구됩니다.\n- 국내 DEM&DET는 별도 협의 없을 시, 기본요율로 청구됩니다.\n- 국내 내륙운송료는 국가에서 지정한 '2022년 안전운임제'에 적용 받습니다.";

const ADMIN_BID_QUOTATION_OCEANTICKET_COMMENT =
  "* 견적서 내 회색 행은 VAT 별도입니다.\n* 견적서 내 WFG,PFS,보험료는 비과세 항목입니다.\n1. 위험물, 2단 적재 불가한 상품의 경우 해당운임을 적용 받지 않습니다. 별도 운임 확인이 필요하고 의뢰 내용에 맞춰 확인 후 안내드립니다.\n2. 원산지 표기 오기 및 미부착 등에 의해 세관에서 적발되었을 경우 발생하는 일체 비용은 실비청구 됩니다.\n3. 물류비 대금정산은 국내 항구 도착 후, 실제 선적된 부피/중량에 따라 청구됩니다.\n4. 창고보관료는 반입일 기준 '1주일 무료'입니다. 이후 7일 단위로 보관료가 R.TON 당 15,000원씩 청구됩니다.\n\n예시)\n2R.TON 보관 시,\n1) 창고 반입 후 1주일: 무료\n※ 1주일은 주말이 포함되어 있습니다.\n2) 8일 보관(=7일 초과, 14일 이내): 15,000원/R.TON * 2R.TON * 초과 1주차 = 30,000원\n3) 13일 보관(=7일 초과, 14일 이내): 15,000원/R.TON * 2R.TON * 초과 1주차 = 30,000원\n4) 18일 보관(=14일 초과, 21일 이내): 15,000원/R.TON * 2R.TON * 초과 2주차 = 60,000원\n\n5. 해외 내륙에서부터 쉽다가 운송을 시작하는 경우 인코텀즈는 EXW로 설정되고, 해외 항구에서부터 운송을 시작하는 경우 인코텀즈는 FOB로 설정됩니다.\n6. 견적은 업무 의뢰 후 30일간 유효합니다.\n7. 0.1 R.TON 단위로 계산합니다(소수 둘째자리 올림처리).\n8. 수출자 라이선스 대행 요청 시, 관련 비용($50/BL)을 수출자에게 청구합니다.\n9. FTA C/O 발행대행 요청 시, $50/BL이 수입자에게 추가청구 됩니다.";

const ADMIN_BID_QUOTATION_CONSOLIDATION_COMMENT =
  "* 견적서 내 회색 행은 VAT 별도입니다.\n* 견적서 내 WFG,PFS,보험료는 비과세 항목입니다.\n1. 원산지 표기 미부착 및 오기 등 이슈가 발생하여 세관에 적발되었을 경우, 비용은 실비청구되고 수입자가 부담합니다.\n2. 세관 무작위 검사 적발 시, 관세사 수수료 50,000원(VAT 별도)가 추가 발생됩니다.\n3. 실제 부피/중량에 따라 견적이 변동될 수 있습니다.\n4. 위험물, 2단 적재 등 특수 화물일 경우 선적이 불가할 수 있고, 견적이 상이할 수 있습니다. (단, 해당 내용은 업무 진행 이전 사전 고지합니다.)\n5. 마이페이지에서 입력하신 판매자(수출자)에게 사전 컨택 후 입고일정을 수립합니다. 판매자 정보는 [마이페이지]에서 입력 가능합니다. 사전 동의없이 화물을 입고시킬 경우 창고에서 입고 거절할 수 있고 쉽다는 이에 책임지지 않습니다.\n\n기본적으로 소상품 신고로 수출신고가 이루어집니다. 이를 통해 진행하시는 경우 묶음운송이 가능합니다. 단, 한국 수입통관 후 환불 등 사유로 인한 SHIPBACK은 불가합니다.\n 수출자 명의로 통관 희망 시에도 진행 가능합니다. 단, 이럴 경우 묶음 운송은 불가합니다.";

const ADMIN_BID_QUOTATION_LCL_AND_AIR_COMMENT =
  "* 견적서 내 회색 행은 VAT 별도입니다.\n* 견적서 내 WFG,PFS,보험료는 비과세 항목입니다.\n- 관부가세는 HS-CODE에 따라 실비청구됩니다.\n- 환율은 외환은행 기준 전신환매도율 평균값으로 청구됩니다.\n- 실제 선적된 부피/중량 기준으로 청구됩니다.\n- 국내 창고보관료는 실비로 청구됩니다.";

const ADMIN_BID_QUOTATION_SG_COMMENT =
  "* The gray rows in the estimate sheet are GST excluded.\n* WFG, PFS, Cargo Insurance are tax-free.\n- With no consultation, the destination freetime(DEM&DET) will be calculated by the basic tariff of each liner.\n- The actual exchange rate will be set at the telegraphic transfer selling rate(TTSR).\n- Any fees that were not selected in the real-time quote checkpoint but still occur will be charged at cost.\n- If the final destination is CBD AREA, SGD40 will be charged at cost.";

const ADMIN_BID_SCHEDULE_CIF_CFR_COMMENT =
  "* 견적서 내 회색 행은 VAT 별도입니다.\n* 견적서 내 WFG,PFS,보험료는 비과세 항목입니다.\n- 관부가세는 HS-CODE에 따라 실비청구됩니다.\n- 환율은 외환은행 기준 전신환매도율 평균값으로 청구됩니다.\n- 실제 선적된 부피/중량 기준으로 청구됩니다.\n- 국내 DEM&DET는 별도 협의 없을 시, 기본요율로 청구됩니다.\n- 국내 내륙운송료는 국가에서 지정한 '2022년 안전운임제'에 적용 받습니다.\n\n위 견적은 수출지 포워더가 쉽다를 수입 파트너로 지정한 경우에 적용됩니다.\n※ 수입 파트너로 쉽다를 지정하지 않는 경우 위 견적은 변동될 수 있습니다.";

// 견적 확정 시 접안항이 있는 항구 모음
const ADMIN_BID_QUOTATION_ANOTHER_COMMENT_PORT_ID = [
  {
    name: "인천항",
    id: 9,
  },
  {
    name: "부산항",
    id: 1,
  },
  {
    name: "부산신항",
    id: 11,
  },
  {
    name: "인천신항",
    id: 336,
  },
];

const ADMIN_BID_SCHEDULE_STATUS_OPTION_LIST = [
  {
    label: "출항 준비 중",
    value: "scheduling",
  },
  {
    label: "공 컨테이너 반출",
    value: "containerCarryOut",
  },
  {
    label: "현지 화물 픽업 완료",
    value: "containerPickup",
  },
  {
    label: "터미널 반입 완료",
    value: "gateIn",
  },
  {
    label: "선적 완료",
    value: "loaded",
  },
  {
    label: "출항완료",
    value: "moving",
  },
];
export {
  ADMIN_BID_CONTAINER_TYPE_OPTION_LIST,
  ADMIN_BID_WAREHOUSE_OPTION_LIST,
  ADMIN_BID_PRODUCTS_INFO_BASIC_FORM,
  ADMIN_BID_SERVICE_TYPE_OPTION_LIST,
  ADMIN_BID_APPLY_FCL_BASIC_FARE,
  ADMIN_BID_APPLY_LCL_BASIC_FARE,
  ADMIN_BID_APPLY_AIR_BASIC_FARE,
  ADMIN_BID_APPLY_OCEANTICKET_SG_BASIC_FARE,
  ADMIN_BID_APPLY_OCEANTICKET_BASIC_FARE,
  ADMIN_BID_APPLY_CONSOLIDATION_BASiC_FARE,
  ADMIN_BID_APPLY_CURRENCY_OPTION_LIST,
  ADMIN_BID_FREIGHT_TYPE_OPTION_LIST,
  ADMIN_BID_INCOTERMS_OPTION_LIST,
  ADMIN_BID_COPY_STATUS,
  BID_COPY_TARGET_FOR_CHECKBOX,
  ADMIN_BID_QUOTATION_FCL_COMMENT,
  ADMIN_BID_QUOTATION_OCEANTICKET_COMMENT,
  ADMIN_BID_QUOTATION_CONSOLIDATION_COMMENT,
  ADMIN_BID_QUOTATION_LCL_AND_AIR_COMMENT,
  ADMIN_BID_QUOTATION_SG_COMMENT,
  ADMIN_BID_SCHEDULE_CIF_CFR_COMMENT,
  ADMIN_BID_QUOTATION_ANOTHER_COMMENT_PORT_ID,
  ADMIN_BID_SCHEDULE_STATUS_OPTION_LIST,
};
