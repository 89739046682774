import { MutableRefObject, useState } from "react";

import { noop } from "@sellernote/_shared/src/utils/common/etc";

import { COLOR } from "../../../styles/colors";

import SelectButton from "../../form/SelectButton";
import ExpandArrowLessCircleIcon from "../../svgIcons/ExpandArrowLessCircleIcon";
import ExpandArrowMoreCircleIcon from "../../svgIcons/ExpandArrowMoreCircleIcon";
import { SelectableTableRow, TableCellInfo, TableRow as TableRowType } from ".";
import Styled from "./index.styles";

export default function Row<
  CellKey extends string,
  RowKey extends string | number
>({
  tableDataCellRef,
  cellInfoList,
  row,
  rowIndex,
  hoveredRowKey,
  setHoveredRowKey,
  propsByType,
}: {
  tableDataCellRef:
    | MutableRefObject<(HTMLTableCellElement | null)[]>
    | undefined;
  cellInfoList: TableCellInfo<CellKey>[];
  row: TableRowType<CellKey, RowKey> | SelectableTableRow<CellKey, RowKey>;
  rowIndex: number;
  hoveredRowKey: RowKey | undefined;
  setHoveredRowKey: (rowKey: RowKey | undefined) => void;
  propsByType:
    | {
        selectableType?: undefined;
      }
    | {
        selectableType: "single" | "multi";
        allSelectInfo?:
          | {
              onAllSelect: () => void;
              isAllSelected: boolean;
            }
          | undefined;
      };
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const isHoveredRow = hoveredRowKey === row.rowKey;

  const rowBackgroundColor = row.rowBackgroundColor;

  const isSubPanelTable = !!row.subPanel;
  const isSelectableTable = !!propsByType.selectableType;

  const totalColumnCount = cellInfoList.length;

  return (
    <>
      <Styled.tableRow
        key={row.rowKey}
        onMouseEnter={() => setHoveredRowKey(row.rowKey)}
        onMouseOut={() => setHoveredRowKey(undefined)}
      >
        {isSubPanelTable && (
          // SubPanel 테이블인 경우, 첫 dataCell에 panelToggle UI를 추가
          <Styled.tableDataCell
            ref={(ref) => {
              if (tableDataCellRef) {
                tableDataCellRef.current[rowIndex] = ref;
              }
            }}
            key={`subPanelToggle-${row.rowKey}`}
            isFirstAndSelectionCell={false}
            hasClickAction
            isSubPanelToggle
            isHovered={isHoveredRow}
            isDisabled={
              isSelectableTable &&
              (row as { isNotSelectable?: boolean }).isNotSelectable
            }
            isSelected={
              isSelectableTable && (row as { isSelected?: boolean }).isSelected
            }
            rowBackgroundColor={rowBackgroundColor}
          >
            {isExpanded ? (
              <ExpandArrowMoreCircleIcon
                width={20}
                height={20}
                color={COLOR.grayScale_400}
                onClick={() => setIsExpanded(false)}
              />
            ) : (
              <ExpandArrowLessCircleIcon
                width={20}
                height={20}
                color={COLOR.grayScale_400}
                onClick={() => setIsExpanded(true)}
              />
            )}
          </Styled.tableDataCell>
        )}

        {isSelectableTable && (
          // 선택가능한 테이블인 경우, dataCell에 선택 UI를 추가
          <Styled.tableDataCell
            ref={(ref) => {
              if (tableDataCellRef) {
                tableDataCellRef.current[rowIndex] = ref;
              }
            }}
            onClick={() => {
              // selectableType이 있으면 SelectableTableRow임 (타입컴파일러가 정확히 인식 못해 as로 처리)
              const selectableRow = row as SelectableTableRow<CellKey, RowKey>;

              if (selectableRow.isNotSelectable) return;

              selectableRow.onRowSelect();
            }}
            key={`dataCellOfSelectableType-${row.rowKey}`}
            isFirstAndSelectionCell={!isSubPanelTable}
            hasClickAction
            isHovered={isHoveredRow}
            // selectableType이 있으면 isNotSelectable, isSelected이 있음. (타입컴파일러가 정확히 인식 못해 as로 처리)
            isDisabled={(row as { isNotSelectable?: boolean }).isNotSelectable}
            isSelected={(row as { isSelected?: boolean }).isSelected}
            rowBackgroundColor={rowBackgroundColor}
          >
            {propsByType.selectableType === "single" && (
              <SelectButton
                uiType="radio"
                size="default"
                isLabelHidden
                label="select button"
                // onRowSelect에서 대신 처리
                onSelect={noop}
                // selectableType이 있으면 isNotSelectable, isSelected이 있음. (타입컴파일러가 정확히 인식 못해 as로 처리)
                disabled={
                  (row as { isNotSelectable?: boolean }).isNotSelectable
                }
                selected={(row as { isSelected?: boolean }).isSelected}
                value=""
              />
            )}

            {propsByType.selectableType === "multi" && (
              <SelectButton
                uiType="checkboxOutlined"
                size="default"
                isLabelHidden
                label="select button"
                // onRowSelect에서 대신 처리
                onSelect={noop}
                // selectableType이 있으면 isNotSelectable, isSelected이 있음. (타입컴파일러가 정확히 인식 못해 as로 처리)
                disabled={
                  (row as { isNotSelectable?: boolean }).isNotSelectable
                }
                selected={(row as { isSelected?: boolean }).isSelected}
                value=""
              />
            )}
          </Styled.tableDataCell>
        )}

        {cellInfoList.map((cellInfo, i) => {
          return (
            <Styled.tableDataCell
              ref={(ref) => {
                if (tableDataCellRef) {
                  tableDataCellRef.current[rowIndex] = ref;
                }
              }}
              key={cellInfo.cellKey}
              horizontalAlign={cellInfo.horizontalAlign}
              verticalAlign={cellInfo.verticalAlign}
              {...(row.onRowClick ? { onClick: row.onRowClick } : {})}
              {...(isSelectableTable
                ? {
                    // selectableType이 있으면 isSelected이 있음. (타입컴파일러가 정확히 인식 못해 as로 처리)
                    isSelected: (row as { isSelected?: boolean }).isSelected,
                  }
                : {})}
              isFirstAndSelectionCell={false}
              hasClickAction={!!row.onRowClick}
              isHovered={isHoveredRow}
              rowBackgroundColor={rowBackgroundColor}
              isDisabled={
                isSelectableTable &&
                (row as { isNotSelectable?: boolean }).isNotSelectable
              }
            >
              {row[cellInfo.cellKey]}
            </Styled.tableDataCell>
          );
        })}
      </Styled.tableRow>

      {isSubPanelTable && isExpanded && (
        <Styled.tableRow>
          <Styled.tableDataCell
            ref={(ref) => {
              if (tableDataCellRef) {
                tableDataCellRef.current[rowIndex] = ref;
              }
            }}
            key={`subPanel-${row.rowKey}`}
            isFirstAndSelectionCell={false}
            isHovered={false}
            hasClickAction={false}
            isSubPanelCell
            isSelectableTable={isSelectableTable}
            totalColumnCount={
              isSelectableTable ? totalColumnCount + 1 : totalColumnCount
            }
          >
            {row.subPanel}
          </Styled.tableDataCell>
        </Styled.tableRow>
      )}
    </>
  );
}
