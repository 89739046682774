import { useState } from "react";

import useHistoryState from "@sellernote/_shared/src/hooks/common/useHistoryState";
import { FetchTrelloListParams } from "@sellernote/_shared/src/types/forwarding/trello";

export default function useTrelloListFetchParams() {
  const [fetchTrelloListParams, setFetchTrelloListParams] =
    useHistoryState<FetchTrelloListParams>("fetchTrelloListParams", {
      adminId: undefined,
      company: undefined,
      bidId: undefined,
      BL: undefined,
    });
  const [isAutoFetch, setIsAutoFetch] = useState(true);

  return {
    fetchTrelloListParams,
    setFetchTrelloListParams,
    isAutoFetch,
    setIsAutoFetch,
  };
}
