import React from "react";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { Port } from "@sellernote/_shared/src/types/common/common";
import {
  FetchTrelloListParams,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";

import PartnerAdminTrelloDetail from "./PartnerAdminTrelloDetail";
import ShipdaAdminTrelloDetail from "./ShipdaAdminTrelloDetail";

const TrelloDetail = ({
  showModal,
  setShowModal,
  bidId,
  portList,
  trelloListData,
  fetchTrelloListParams,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  bidId: number | undefined;
  portList: Port[];
  trelloListData?: TrelloBidList[];
  fetchTrelloListParams?: FetchTrelloListParams;
}) => {
  if (APP_NAME === "shipda-admin") {
    return (
      <ShipdaAdminTrelloDetail
        showModal={showModal}
        setShowModal={setShowModal}
        bidId={bidId}
        portList={portList}
        trelloListData={trelloListData}
        fetchTrelloListParams={fetchTrelloListParams}
      />
    );
  }

  return (
    <PartnerAdminTrelloDetail
      showModal={showModal}
      setShowModal={setShowModal}
      bidId={bidId}
      portList={portList}
    />
  );
};

export default TrelloDetail;
