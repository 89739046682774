import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useSetRecoilState } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import {
  InvoiceResult,
  InvoiceType,
  PaymentSubInvoiceItem,
  TrelloBidDetail,
  TrelloSalesManagementTableData,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { isEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import { removeComma } from "@sellernote/_shared/src/utils/common/string";
import { changeInvoiceResultToKr } from "@sellernote/_shared/src/utils/forwarding/adminSettlement";
import {
  changeInvoiceTypeToKr,
  checkInvoiceStatus,
  checkIsCustomSended,
} from "@sellernote/_shared/src/utils/forwarding/trello";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-admin/src/components/Table";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

import CancelInvoiceModal from "./CancelInvoiceModal";
import DepositCommentModal from "./DepositCommentModal";
import { useDepositCommentModalProps } from "./DepositCommentModal/useDepositCommentModalProps";
import DepositHistoryModal from "./DepositHistoryModal";
import useTrelloSalesManagementTableData from "./useTrelloSalesManagementTableData";

type CellId = keyof TrelloSalesManagementTableData | "comment";

function SalesManagement({
  trelloDetailData,
}: {
  trelloDetailData: TrelloBidDetail;
}) {
  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const queryClient = useQueryClient();

  const history = useHistory();

  const [showsCancelInvoiceModal, setShowsCancelInvoiceModal] = useState(false);
  const [invoiceType, setInvoiceType] = useState<InvoiceType>("invoice");
  const [showsInvoicePopover, setShowsInvoicePopover] =
    useState<HTMLButtonElement | null>(null);
  const [showsDepositHistoryModal, setShowsDepositHistoryModal] =
    useState(false);
  const [invoiceId, setInvoiceId] = useState(0);
  const [billingAmount, setBillingAmount] = useState(0);
  const [paymentInvoiceId, setPaymentInvoiceId] = useState(0);

  const { trelloSalesManagementTableData } =
    useTrelloSalesManagementTableData(trelloDetailData);

  const { UploadResponseSnackBar, setShowsErrorSnackBar, setErrorMessage } =
    useUploadResponseSnackBar();

  const {
    commentModalProps,
    showsDepositCommentModal,
    handleModalOpen,
    handleModalClose,
  } = useDepositCommentModalProps({
    trelloDetailData,
    invoiceType,
    invoiceId,
    paymentInvoiceId,
  });

  const {
    mutate: requestInvoiceIssue,
    ResponseHandler: ResponseHandlerOfRequestInvoiceIssue,
  } = TRELLO_BID_QUERY.useRequestInvoiceIssue({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({
            bidId: trelloDetailData.id,
          })
        );
      },
      customizeMessage: () => ({
        title: "발행을 요청했습니다.",
      }),
    },
  });

  const { mutate: getFileDownloadUrl } =
    ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const handleFileDownload = useCallback(
    (downloadKey: string | undefined) => {
      if (!downloadKey) {
        return;
      }
      return () => {
        getFileDownloadUrl(
          {
            pathParams: {
              key: downloadKey,
            },
          },
          {
            onSuccess: ({ data }) => {
              window.location.href = data.url;
            },

            onError: () => {
              setShowsErrorSnackBar(true);
            },
          }
        );
      };
    },
    [getFileDownloadUrl, setShowsErrorSnackBar]
  );

  const handleInvoiceIssueClick = useCallback(
    (invoiceId: number | undefined) => {
      return () => {
        if (!invoiceId) {
          setErrorMessage("거래명세서 아이디가 없습니다.");
          setShowsErrorSnackBar(true);
          return;
        }

        requestInvoiceIssue({ pathParams: { invoiceId } });
      };
    },
    [requestInvoiceIssue, setErrorMessage, setShowsErrorSnackBar]
  );

  const handleCancelInvoiceModalOpen = useCallback((domain: InvoiceType) => {
    return () => {
      setInvoiceType(domain);
      setShowsCancelInvoiceModal(true);
    };
  }, []);

  const handleMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    setTrelloCardId(trelloDetailData.id);
    sessionStorage.setItem("invoiceId", "none");

    history.push(
      `/tradingStatement/${e.currentTarget.id}/${trelloDetailData.id}`
    );
  };

  const handlePopoverClose = () => {
    setShowsInvoicePopover(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowsInvoicePopover(event.currentTarget);
  };

  const getDepositStatusInfo = (invoiceResult: InvoiceResult | undefined) => {
    if (!invoiceResult) {
      return (
        <Typography variant="body2" component={"span"} color="error">
          입금대기
        </Typography>
      );
    }

    if (invoiceResult === "complete") {
      return (
        <Typography variant="body2" component={"span"} color="error">
          입금완료
        </Typography>
      );
    }

    return (
      <>
        <Typography variant="body2" component={"span"}>
          입금
        </Typography>

        <Typography variant="body2" component={"span"} color="error">
          ({changeInvoiceResultToKr(invoiceResult)})
        </Typography>
      </>
    );
  };
  const getInvoiceResult = useCallback(
    ({
      paymentSubInvoice,
      invoiceResult,
      finalPrice,
      invoiceId,
      isSended,
    }: {
      paymentSubInvoice: PaymentSubInvoiceItem[];
      invoiceResult: InvoiceResult | undefined;
      finalPrice: string;
      invoiceId: number;
      isSended: boolean | undefined;
    }) => {
      if (!isSended) {
        return "-";
      }

      if (isEmptyObjectOrArray(paymentSubInvoice)) {
        return (
          <>
            {getDepositStatusInfo(invoiceResult)}

            <IconButton
              size="small"
              onClick={() => {
                setBillingAmount(Number(finalPrice));
                setInvoiceId(invoiceId);
                setShowsDepositHistoryModal(true);
              }}
            >
              <ListIcon fontSize="medium" />
            </IconButton>
          </>
        );
      }
      if (invoiceResult) {
        return paymentSubInvoice.map((v, index) => {
          return (
            <Box
              key={v.id}
              sx={{
                pt: index === 0 ? undefined : 1,
                pb: index === 0 ? undefined : 1,
              }}
            >
              {getDepositStatusInfo(invoiceResult)}

              {/* 첫번째 항목에만 버튼을 보여준다. */}
              {index === 0 && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setBillingAmount(Number(finalPrice));
                    setInvoiceId(v.invoiceId);
                    setShowsDepositHistoryModal(true);
                  }}
                >
                  <ListIcon fontSize="medium" />
                </IconButton>
              )}
            </Box>
          );
        });
      }

      return "-";
    },
    []
  );

  const getCommentButton = useCallback(
    (
      paymentSubInvoice: PaymentSubInvoiceItem[],
      invoiceType: InvoiceType,
      invoiceId: number | undefined
    ) => {
      if (!isEmptyObjectOrArray(paymentSubInvoice) && invoiceId) {
        return paymentSubInvoice.map((v) => {
          return (
            <Button
              key={v.id}
              onClick={() => {
                setInvoiceType(invoiceType);
                setInvoiceId(invoiceId);
                setPaymentInvoiceId(v.paymentInvoiceId);
                handleModalOpen();
              }}
            >
              {v.paymentInvoice.comment ? "확인" : "등록"}
            </Button>
          );
        });
      }

      return "-";
    },
    [handleModalOpen]
  );

  const handleMoveToTradingStatement = useCallback(
    (invoiceId: number | undefined, invoiceType: InvoiceType) => {
      return () => {
        setTrelloCardId(trelloDetailData.id);
        sessionStorage.setItem(
          "invoiceId",
          invoiceId ? invoiceId.toString() : "none"
        );
        history.push(`/tradingStatement/${invoiceType}/${trelloDetailData.id}`);
      };
    },
    [history, setTrelloCardId, trelloDetailData.id]
  );

  const handleMoveToSalesManagement = useCallback(() => {
    setTrelloCardId(trelloDetailData.id);
    history.push("/settlement/salesManagement");
  }, [history, setTrelloCardId, trelloDetailData.id]);

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    if (APP_NAME === "partner-admin") {
      return [
        {
          id: "invoiceType",
          disablePadding: false,
          label: "구분",
          width: 120,
          verticalAlign: "middle",
        },
        {
          id: "downloadKey",
          disablePadding: false,
          label: "다운로드",
          width: 120,
          verticalAlign: "middle",
        },
        {
          id: "totalPrice",
          disablePadding: false,
          label: "공급가액",
          width: 120,
          verticalAlign: "middle",
        },
        {
          id: "vatPrice",
          disablePadding: false,
          label: "부가세",
          width: 120,
          verticalAlign: "middle",
        },
        {
          id: "taxFee",
          disablePadding: false,
          label: "대납세금",
          width: 120,
          verticalAlign: "middle",
        },
        {
          id: "finalPrice",
          disablePadding: false,
          label: "합계",
          width: 120,
          verticalAlign: "middle",
        },
      ];
    }

    return [
      {
        id: "invoiceType",
        disablePadding: false,
        label: "구분",
        width: 120,
        verticalAlign: "middle",
      },
      {
        id: "downloadKey",
        disablePadding: false,
        label: "다운로드",
        width: 120,
        verticalAlign: "middle",
      },
      {
        id: "isCustomSended",
        disablePadding: false,
        label: "발송처리",
        width: 100,
        verticalAlign: "middle",
      },
      {
        id: "isTemporary",
        disablePadding: false,
        label: "상태",
        width: 100,
        verticalAlign: "middle",
      },
      {
        id: "totalPrice",
        disablePadding: false,
        label: "공급가액",
        width: 120,
        verticalAlign: "middle",
      },
      {
        id: "vatPrice",
        disablePadding: false,
        label: "부가세",
        width: 120,
        verticalAlign: "middle",
      },
      {
        id: "taxFee",
        disablePadding: false,
        label: "대납세금",
        width: 120,
        verticalAlign: "middle",
      },
      {
        id: "finalPrice",
        disablePadding: false,
        label: "합계",
        width: 120,
        verticalAlign: "middle",
      },
      {
        id: "name",
        disablePadding: false,
        label: "이름",
        width: 100,
        verticalAlign: "middle",
      },
      {
        id: "lastUpdatedAt",
        disablePadding: false,
        label: "업데이트 일시",
        width: 110,
        verticalAlign: "middle",
      },
      {
        id: "invoiceResult",
        disablePadding: false,
        label: "입금(내역)",
        width: 150,
        verticalAlign: "top",
      },
      {
        id: "comment",
        disablePadding: false,
        label: "입금메모",
        width: 100,
        verticalAlign: "top",
      },
      {
        id: "issuedInvoices",
        disablePadding: false,
        label: "계산서",
        width: 120,
        verticalAlign: "top",
      },
      {
        id: "isIssued",
        disablePadding: false,
        label: "발행",
        width: 120,
        verticalAlign: "middle",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!trelloSalesManagementTableData) return [];

    if (APP_NAME === "partner-admin") {
      return trelloSalesManagementTableData.map((v) => {
        const row: TableBodyRow<CellId> = {
          invoiceType: (
            <Button
              variant="text"
              disabled={true}
              onClick={handleMoveToTradingStatement(v.invoiceId, v.invoiceType)}
            >
              {changeInvoiceTypeToKr(v.invoiceType)}
            </Button>
          ),
          downloadKey: (
            <Button
              size="small"
              disabled={!v.downloadKey}
              variant="outlined"
              onClick={handleFileDownload(v.downloadKey)}
            >
              다운로드
            </Button>
          ),
          totalPrice: v.totalPrice,
          vatPrice: v.vatPrice,
          taxFee: v.taxFee,
          finalPrice: v.finalPrice,
        };

        return row;
      });
    }

    return trelloSalesManagementTableData.map((v) => {
      const row: TableBodyRow<CellId> = {
        invoiceType: (
          <Button
            variant="text"
            onClick={handleMoveToTradingStatement(v.invoiceId, v.invoiceType)}
          >
            {changeInvoiceTypeToKr(v.invoiceType)}
          </Button>
        ),
        downloadKey: (
          <Button
            size="small"
            disabled={!v.downloadKey}
            variant="outlined"
            onClick={handleFileDownload(v.downloadKey)}
          >
            다운로드
          </Button>
        ),
        isCustomSended: checkIsCustomSended({
          isCustomSended: v.isCustomSended,
          isSended: v.isSended,
          invoiceType: v.invoiceType,
        }),
        isTemporary: checkInvoiceStatus(v.isTemporary),
        totalPrice: v.totalPrice,
        vatPrice: v.vatPrice,
        taxFee: v.taxFee,
        finalPrice: v.finalPrice,
        name: v.name,
        lastUpdatedAt: toFormattedDate(v.lastUpdatedAt, "YYYY.MM.DD hh:mm:ss"),
        invoiceResult: getInvoiceResult({
          paymentSubInvoice: v.paymentSubInvoice || [],
          invoiceResult: v.invoiceResult,
          finalPrice: removeComma(v.finalPrice || 0),
          invoiceId: v.invoiceId as number,
          isSended: v.isSended,
        }),

        comment: getCommentButton(
          v.paymentSubInvoice || [],
          v.invoiceType,
          v.invoiceId
        ),
        issuedInvoices: v.issuedInvoices ? (
          <Button
            variant="text"
            color="error"
            onClick={handleCancelInvoiceModalOpen(v.invoiceType)}
          >
            취소 요청
          </Button>
        ) : (
          <Button variant="text" onClick={handleInvoiceIssueClick(v.invoiceId)}>
            발행 요청
          </Button>
        ),
        isIssued: v.isIssued ? "발행완료" : "-",
      };

      return row;
    });
  }, [
    getCommentButton,
    getInvoiceResult,
    handleCancelInvoiceModalOpen,
    handleFileDownload,
    handleInvoiceIssueClick,
    handleMoveToTradingStatement,
    trelloSalesManagementTableData,
  ]);

  return (
    <Grid container direction="column" sx={{ marginBottom: "16px" }}>
      <Grid item xs={1}>
        <Typography
          variant="subtitle1"
          component="div"
          onClick={handleMoveToSalesManagement}
          sx={{ color: blue[700], cursor: "pointer" }}
        >
          매출 관리
        </Typography>
      </Grid>

      <Grid item>
        <Table headCells={headCells} rows={rows} />
      </Grid>

      {APP_NAME === "shipda-admin" && (
        <Grid item>
          <Button variant="text" onClick={handlePopoverOpen}>
            추가
          </Button>

          <Popover
            disableScrollLock={true}
            open={Boolean(showsInvoicePopover)}
            anchorEl={showsInvoicePopover}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Paper>
              <MenuList autoFocusItem>
                <MenuItem id="warehouseReceipt" onClick={handleMenuItemClick}>
                  창고료
                </MenuItem>
                <MenuItem id="etcDeposit" onClick={handleMenuItemClick}>
                  기타 입금
                </MenuItem>
              </MenuList>
            </Paper>
          </Popover>
        </Grid>
      )}

      {showsCancelInvoiceModal && invoiceType && (
        <CancelInvoiceModal
          showsCancelInvoiceModal={showsCancelInvoiceModal}
          setShowsCancelInvoiceModal={setShowsCancelInvoiceModal}
          bidId={trelloDetailData.id}
          invoiceType={invoiceType}
        />
      )}

      {showsDepositHistoryModal && (
        <DepositHistoryModal
          setShowsDepositHistoryModal={setShowsDepositHistoryModal}
          showsDepositHistoryModal={showsDepositHistoryModal}
          invoiceId={invoiceId}
          billingAmount={billingAmount}
          recipient={trelloDetailData.user.company}
          bidId={trelloDetailData.id}
        />
      )}

      {showsDepositCommentModal && commentModalProps && (
        <DepositCommentModal
          showsDepositCommentModal={showsDepositCommentModal}
          handleModalClose={handleModalClose}
          paymentInvoiceId={paymentInvoiceId}
          amount={commentModalProps?.amount}
          name={commentModalProps?.name}
          currency={"KRW"}
          commentData={commentModalProps?.comment}
          refetchListData={() =>
            queryClient.invalidateQueries(
              TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({
                bidId: trelloDetailData.id,
              })
            )
          }
        />
      )}

      {ResponseHandlerOfRequestInvoiceIssue}

      {UploadResponseSnackBar}
    </Grid>
  );
}

export default SalesManagement;
