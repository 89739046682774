import { useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { ADMIN_BID_SCHEDULE_STATUS_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_AUTH_SELECTORS } from "@sellernote/_shared/src/states/forwarding/auth";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { PartnerListItem } from "@sellernote/_shared/src/types/forwarding/partner";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { changeBidProjectStatusNameToKr } from "@sellernote/_shared/src/utils/forwarding/bid";
import {
  checkIsBlNumberByBLType,
  checkIsSettlement,
} from "@sellernote/_shared/src/utils/forwarding/trello";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

import BasicDescription from "./BasicDescription";
import MoveToFinishedStatusConfirmModal from "./MoveToFinishedStatusConfirmModal";
import RejectSettlementModal from "./RejectSettlementModal";
import UpdateBLNumberAndLiner from "./UpdateBLNumberAndLiner";

const BasicInfo = ({
  trelloDetail,
  partnerList,
}: {
  trelloDetail: TrelloBidDetail;
  partnerList?: PartnerListItem[];
}) => {
  const queryClient = useQueryClient();

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const [showRejectSettlementModal, setShowRejectSettlementModal] =
    useState(false);

  const [showsConfirmModal, setShowsConfirmModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");

  const {
    UploadResponseSnackBar,
    setShowsSuccessSnackBar,
    setSuccessMessage,
    setErrorMessage,
    setShowsErrorSnackBar,
  } = useUploadResponseSnackBar();

  const {
    mutate: moveTrelloToFinishedStatus,
    ResponseHandler: ResponseHandlerOfMoveTrelloToFinishedStatus,
  } = TRELLO_BID_QUERY.useMoveTrelloToFinishedStatus({
    bidId: trelloDetail.id,
    onSuccess: () => {
      setShowsConfirmModal(false);
      setConfirmMessage("");
      setSuccessMessage("종료 단계로 변경되었습니다.");
      setShowsSuccessSnackBar(true);

      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({
          bidId: trelloDetail.id,
        })
      );
    },
  });

  const {
    mutate: moveTrelloStatus,
    ResponseHandler: ResponseHandlerOfMoveTrelloStatus,
  } = TRELLO_BID_QUERY.useMoveTrelloStatus({
    bidId: trelloDetail.id,
    onSuccess: () => {
      setSuccessMessage("운송 상태가 변경되었습니다.");
      setShowsSuccessSnackBar(true);

      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({
          bidId: trelloDetail.id,
        })
      );
    },
  });

  const isRequiredStatus =
    trelloDetail.projectStatus === "portEntryAndPrepareCustoms" ||
    trelloDetail.projectStatus === "payment" ||
    trelloDetail.projectStatus === "completeCustoms" ||
    trelloDetail.projectStatus === "delivering";

  const currentUser = useRecoilValue(FORWARDING_AUTH_SELECTORS.CURRENT_MANAGER);

  const history = useHistory();

  const { data: linerData } = COMMON_QUERY.useGetLinerList();

  const isScheduleStatus =
    trelloDetail.projectStatus === "scheduling" ||
    trelloDetail.projectStatus === "containerCarryOut" ||
    trelloDetail.projectStatus === "containerPickup" ||
    trelloDetail.projectStatus === "gateIn" ||
    trelloDetail.projectStatus === "loaded";

  return (
    <Grid container direction={"column"} spacing={2}>
      <Grid item container>
        <Grid item>
          <Button
            onClick={() => {
              setTrelloCardId(trelloDetail.id);
              history.push(
                trelloDetail.team.isPrivate
                  ? `/users/list/${trelloDetail.userId}`
                  : `/team/${trelloDetail.teamId}`
              );
            }}
            style={{ marginRight: 8, cursor: "pointer" }}
          >
            {trelloDetail.team.isPrivate
              ? trelloDetail.user.company
              : trelloDetail.team.company}
          </Button>
        </Grid>

        <Grid item>
          <Button
            onClick={() => {
              setTrelloCardId(trelloDetail.id);
              history.push(`/bid/detail/${trelloDetail.id}`);
            }}
          >
            BID ID:{trelloDetail.id}
          </Button>
        </Grid>

        {checkIsSettlement(trelloDetail.projectStatus) &&
          APP_NAME === "shipda-admin" &&
          currentUser?.authority !== "finance" && (
            <Grid item>
              <Button
                variant="outlined"
                sx={{ marginLeft: "16px" }}
                onClick={() => setShowRejectSettlementModal(true)}
                color="error"
              >
                반려 요청
              </Button>
            </Grid>
          )}
      </Grid>

      {APP_NAME === "shipda-admin" && !!trelloDetail.poMapping?.length && (
        <Grid item sx={{ display: "flex" }}>
          <Typography>
            PO No.:{" "}
            {trelloDetail.poMapping.map((v) => {
              if (!v.purchaseOrder) return null;

              return (
                <Box
                  key={v.poId}
                  sx={{ display: "inline-flex", marginRight: "10px" }}
                >
                  <span>{v.purchaseOrder.poNumber}</span>

                  <OpenInNewIcon
                    onClick={() => {
                      setTrelloCardId(trelloDetail.id);
                      history.push(`/order/${v.poId}`);
                    }}
                    sx={{ cursor: "pointer", color: blue["500"] }}
                  />
                </Box>
              );
            })}
          </Typography>
        </Grid>
      )}

      <Grid container item>
        {isScheduleStatus && APP_NAME === "shipda-admin" ? (
          <Grid container item spacing={3} alignItems={"center"}>
            <Grid item>
              <Typography>상태: </Typography>
            </Grid>

            <Grid item>
              <FormControl>
                <InputLabel>수동처리</InputLabel>

                <Select
                  label={"수동처리"}
                  value={trelloDetail.projectStatus}
                  onChange={(e) => {
                    if (
                      e.target.value === "moving" &&
                      !checkIsBlNumberByBLType(trelloDetail.management)
                    ) {
                      setErrorMessage(
                        "BL(AWB)를 입력완료 해야 상태값 변경이 가능합니다."
                      );
                      setShowsErrorSnackBar(true);
                      return;
                    }
                    moveTrelloStatus({
                      status: e.target.value as Extract<
                        BidProjectStatus,
                        | "containerCarryOut"
                        | "containerPickup"
                        | "gateIn"
                        | "loaded"
                        | "moving"
                      >,
                    });
                  }}
                >
                  {ADMIN_BID_SCHEDULE_STATUS_OPTION_LIST.map((option) => {
                    return (
                      <MenuItem
                        disabled={
                          // FCL에서만 모든 상태가 활성화
                          (trelloDetail.freightType !== "FCL" &&
                            option.value !== "moving") ||
                          // 뒷 단계 상태일 때는 앞 단계는 선택 불가능하다.
                          (trelloDetail.projectStatus === "loaded" &&
                            (option.value === "gateIn" ||
                              option.value === "containerPickup" ||
                              option.value === "containerCarryOut" ||
                              option.value === "scheduling")) ||
                          (trelloDetail.projectStatus === "gateIn" &&
                            (option.value === "containerPickup" ||
                              option.value === "containerCarryOut" ||
                              option.value === "scheduling")) ||
                          (trelloDetail.projectStatus === "containerPickup" &&
                            (option.value === "containerCarryOut" ||
                              option.value === "scheduling")) ||
                          (trelloDetail.projectStatus === "containerCarryOut" &&
                            option.value === "scheduling")
                        }
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <Typography style={{ marginTop: "5px" }}>
            상태 :{changeBidProjectStatusNameToKr(trelloDetail.projectStatus)}
          </Typography>
        )}

        {isRequiredStatus && APP_NAME === "shipda-admin" && (
          <Button
            variant="contained"
            color="error"
            sx={{ marginLeft: 5 }}
            size="small"
            onClick={() => setShowsConfirmModal(true)}
          >
            강제 완료 변경
          </Button>
        )}
      </Grid>

      <Grid item>
        {linerData && (
          <UpdateBLNumberAndLiner
            freightType={trelloDetail.freightType}
            management={trelloDetail.management}
            projectStatus={trelloDetail.projectStatus}
            linerData={linerData}
          />
        )}
      </Grid>

      <BasicDescription trelloDetail={trelloDetail} partnerList={partnerList} />
      {showRejectSettlementModal && (
        <RejectSettlementModal
          setShowRejectSettlementModal={setShowRejectSettlementModal}
          showRejectSettlementModal={showRejectSettlementModal}
          bidId={trelloDetail.id}
        />
      )}

      {showsConfirmModal && APP_NAME === "shipda-admin" && (
        <MoveToFinishedStatusConfirmModal
          showsConfirmModal={showsConfirmModal}
          setShowsConfirmModal={setShowsConfirmModal}
          confirmMessage={confirmMessage}
          setConfirmMessage={setConfirmMessage}
          moveTrelloToFinishedStatus={moveTrelloToFinishedStatus}
        />
      )}

      {ResponseHandlerOfMoveTrelloToFinishedStatus}

      {ResponseHandlerOfMoveTrelloStatus}

      {UploadResponseSnackBar}
    </Grid>
  );
};

export default BasicInfo;
