import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";
import { AutoComplete, Button, Form, Input, message } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/lib/select";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { InlnadTransportType } from "@sellernote/_shared/src/types/common/common";
import { PartnerListItem } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  TrelloBidDetail,
  TrelloBidManagement,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { getInlandPartnerName } from "@sellernote/_shared/src/utils/forwarding/trello";
const InlandPartnerForm = ({
  setShowRegisterInlandInfoModal,
  bidId,
  management,
  partnerList,
  inlandType,
  bidAccountPayableId,
  trelloDetail,
}: {
  setShowRegisterInlandInfoModal: Dispatch<SetStateAction<boolean>>;
  bidId: number;
  management: TrelloBidManagement;
  partnerList?: PartnerListItem[];
  inlandType: InlnadTransportType;
  bidAccountPayableId: number;
  trelloDetail: TrelloBidDetail;
}) => {
  const queryClient = useQueryClient();

  const {
    mutate: registerInlandTransport,
    ResponseHandler: ResponseHandlerOfUseRegisterInlandTransport,
  } = TRELLO_BID_QUERY.useRegisterInlandTransport(bidId);

  const inlandPartnerBusinessArea = useMemo(() => {
    if (inlandType === "parcel") {
      return "parcel";
    }
    return "inland";
  }, [inlandType]);

  const inlandPartnerList = useMemo(() => {
    return partnerList
      ?.filter((v: PartnerListItem) => {
        return v.businessArea === inlandPartnerBusinessArea;
      })
      .map((n: PartnerListItem) => {
        return { value: n.name };
      });
  }, [inlandPartnerBusinessArea, partnerList]);

  const changePartnerNameToId = useCallback(
    (companyName: string) => {
      const findPartner = partnerList?.find((v: PartnerListItem) => {
        return (
          v.name === companyName && v.businessArea === inlandPartnerBusinessArea
        );
      });

      if (!findPartner) {
        return;
      }
      return findPartner.id;
    },
    [inlandPartnerBusinessArea, partnerList]
  );

  const handleInlandPartnerRegister = useCallback(
    (formData: {
      driverName?: string;
      driverPhone?: string;
      truckNum?: string;
      companyName: string;
      parcelNum?: string;
    }) => {
      registerInlandTransport(
        {
          ...formData,
          companyId: changePartnerNameToId(formData.companyName),
          detailFlag: true,
          inlandType: inlandType,
          bidAccountPayableId,
        },
        {
          onSuccess: () => {
            message.success("기사 상세 정보를 등록했습니다.");
            queryClient.invalidateQueries(
              TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({
                bidId,
              })
            );
          },

          onError: () => {
            message.error("에러가 있습니다 개발자에게 문의해주세요.");
          },
        }
      );
    },
    [
      bidAccountPayableId,
      bidId,
      changePartnerNameToId,
      inlandType,
      queryClient,
      registerInlandTransport,
    ]
  );

  const getFormInitialValue = useCallback(() => {
    if (inlandType === "parcel") {
      return {
        parcelNum: management.parcelNum,
        companyName: getInlandPartnerName(trelloDetail.accountPayables),
      };
    }
    return {
      driverName: management.driverName,
      driverPhone: management.driverPhone,
      truckNum: management.truckNum,
      companyName: getInlandPartnerName(trelloDetail.accountPayables),
    };
  }, [
    inlandType,
    management.driverName,
    management.driverPhone,
    management.parcelNum,
    management.truckNum,
    trelloDetail.accountPayables,
  ]);

  return (
    <>
      <Form
        layout="horizontal"
        onFinish={handleInlandPartnerRegister}
        initialValues={getFormInitialValue()}
      >
        <Form.Item
          hidden={inlandType === "parcel"}
          rules={
            inlandType === "parcel"
              ? undefined
              : [
                  {
                    required: true,
                    message: "필수 값입니다.",
                  },
                ]
          }
          label="기사명"
          name="driverName"
        >
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item
          hidden={inlandType === "parcel"}
          rules={
            inlandType === "parcel"
              ? undefined
              : [
                  {
                    required: true,
                    message: "필수 값입니다.",
                  },
                ]
          }
          label="연락처"
          name="driverPhone"
        >
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item
          hidden={inlandType === "parcel"}
          label="차량번호"
          name="truckNum"
        >
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "필수 값입니다.",
            },
          ]}
          label={inlandType === "parcel" ? "택배사" : "내륙운송사"}
          name="companyName"
        >
          <AutoComplete
            options={inlandPartnerList}
            filterOption={(
              inputValue: string,
              option: DefaultOptionType | BaseOptionType | undefined
            ) =>
              !!option?.value.toUpperCase().includes(inputValue.toUpperCase())
            }
          />
        </Form.Item>

        <Form.Item
          hidden={inlandType !== "parcel"}
          label="송장번호"
          name="parcelNum"
        >
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            저장
          </Button>
        </Form.Item>
      </Form>

      {ResponseHandlerOfUseRegisterInlandTransport}
    </>
  );
};

export default InlandPartnerForm;
