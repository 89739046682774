import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { Modal, Spin } from "antd";
import { useSetRecoilState } from "recoil";

import { GET_SHIP_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/ship";
import { APP_NAME } from "@sellernote/_shared/src/constants";
import { CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import {
  Country,
  Port,
  Zone,
} from "@sellernote/_shared/src/types/common/common";
import { PartnerListItem } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  FetchTrelloListParams,
  TrelloBidDetail,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { useWindowResize } from "@sellernote/_shared/src/utils/common/hook";
import { checkIsSettlement } from "@sellernote/_shared/src/utils/forwarding/trello";

import BasicInfo from "../pageContainers/trello/:serviceType/TrelloList/TrelloDetail/BasicInfo";
import CargoStatusTable from "../pageContainers/trello/:serviceType/TrelloList/TrelloDetail/CargoStatusTable";
import Profit from "../pageContainers/trello/:serviceType/TrelloList/TrelloDetail/Profit";
import PurchaseManagement from "../pageContainers/trello/:serviceType/TrelloList/TrelloDetail/PurchaseManagement";
import RegisterShipSchedule from "../pageContainers/trello/:serviceType/TrelloList/TrelloDetail/RegisterShipSchedule";
import SalesManagement from "../pageContainers/trello/:serviceType/TrelloList/TrelloDetail/SalesManagement";
import UploadDocuments from "../pageContainers/trello/:serviceType/TrelloList/TrelloDetail/UploadDocuments";
import useCommentList from "./useCommentList";

export default function useTrelloDetail({
  trelloDetailData,
  zoneList,
  shipList,
  exchangeRateList,
  partnerList,
  countryList,
  showModal,
  setShowModal,
  portList,
  isTrelloDetailFetching,
  trelloListData,
  fetchTrelloListParams,
}: {
  trelloDetailData: TrelloBidDetail | undefined;
  zoneList: Zone[] | undefined;
  shipList?: GET_SHIP_LIST_RES | undefined;
  exchangeRateList: ExchangeRate[] | undefined;
  partnerList?: PartnerListItem[];
  countryList: Country[] | undefined;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  portList: Port[];
  isTrelloDetailFetching?: boolean;
  trelloListData?: TrelloBidList[];
  fetchTrelloListParams?: FetchTrelloListParams;
}) {
  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const queryClient = useQueryClient();

  const history = useHistory();

  const [windowWidth] = useWindowResize();

  const { CommentList } = useCommentList({
    commentList: trelloDetailData?.management.comments,
    dataId: trelloDetailData?.id,
    dataType: "trello",
  });

  useEffect(() => {
    setTrelloCardId(undefined);
  }, [setTrelloCardId]);

  // 모달의 넓이를 최대한 넓게 해달라는 요청 반영
  const modalWidth = windowWidth - 50;

  const handleCloseModal = useCallback(() => {
    setShowModal(false);

    if (APP_NAME === "shipda-admin" && trelloListData && trelloDetailData) {
      const oldQueryData = queryClient.getQueryData(
        TRELLO_BID_QUERY_KEY_GEN.getTrelloBidList({
          ...fetchTrelloListParams,
          status: "inProgress",
          perPage: 300,
          serviceType:
            trelloDetailData.serviceType === "consolidation"
              ? "consolidation"
              : undefined,
        })
      );

      // 사전에 종료 데이터와 합쳐논 상태이기 때문에 데이터를 분리
      const notFinishedTrelloListData = [...trelloListData].filter((v) => {
        return v.projectStatus !== "finished";
      });

      // 변경한 의뢰번호를 확인해서 인덱스를 찾음
      const targetDataIndex = notFinishedTrelloListData.findIndex((v) => {
        return v.id === trelloDetailData.id;
      });

      notFinishedTrelloListData[targetDataIndex] = {
        accountPayables: trelloDetailData.accountPayables,
        attachmentFlag:
          notFinishedTrelloListData[targetDataIndex].attachmentFlag,
        attachments: notFinishedTrelloListData[targetDataIndex].attachments,
        containCustoms: trelloDetailData.containCustoms,
        endAddress: trelloDetailData.endAddress,
        endAddressDetail: trelloDetailData.endAddressDetail,
        endCountry: trelloDetailData.endCountry,
        invoices: notFinishedTrelloListData[targetDataIndex].invoices,
        freightType: trelloDetailData.freightType,
        id: trelloDetailData.id,
        incoterms: trelloDetailData.incoterms,
        management: trelloDetailData.management,
        poNumber: notFinishedTrelloListData[targetDataIndex].poNumber,
        projectStatus: trelloDetailData.projectStatus,
        serviceType: trelloDetailData.serviceType,
        startAddress: trelloDetailData.startAddress,
        startCountry: trelloDetailData.startCountry,
        team: trelloDetailData.team,
      };

      // oldQueryData의 타입이 unknown이라서 스프레드 사용 불가 타입체크를 추가
      if (typeof oldQueryData === "object") {
        queryClient.setQueryData(
          TRELLO_BID_QUERY_KEY_GEN.getTrelloBidList({
            ...fetchTrelloListParams,
            status: "inProgress",
            perPage: 300,
            serviceType:
              trelloDetailData.serviceType === "consolidation"
                ? "consolidation"
                : undefined,
          }),
          { ...oldQueryData, data: notFinishedTrelloListData }
        );
      }

      return;
    }

    if (checkIsSettlement(trelloDetailData?.projectStatus)) {
      history.push(`/bid/detail/${trelloDetailData?.id}`);
      return;
    }

    queryClient.invalidateQueries(
      CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.customsTrelloBidList()
    );
  }, [
    fetchTrelloListParams,
    history,
    queryClient,
    setShowModal,
    trelloDetailData,
    trelloListData,
  ]);

  const TrelloDetailModal = useMemo(() => {
    if (APP_NAME === "shipda-admin") {
      if (
        !trelloDetailData ||
        !zoneList ||
        !shipList ||
        !exchangeRateList ||
        !partnerList ||
        !countryList
      ) {
        return (
          <Modal
            destroyOnClose={true}
            style={{ fontFamily: "Roboto" }}
            bodyStyle={{ height: 800, overflow: "scroll" }}
            visible={showModal}
            width={modalWidth}
            onCancel={handleCloseModal}
            footer={null}
          >
            <Spin />
          </Modal>
        );
      }
      return (
        <Modal
          destroyOnClose={true}
          style={{ fontFamily: "Roboto" }}
          bodyStyle={{ height: 800, overflow: "scroll" }}
          visible={showModal}
          width={modalWidth}
          onCancel={handleCloseModal}
          footer={null}
        >
          <BasicInfo
            trelloDetail={trelloDetailData}
            partnerList={partnerList}
          />

          {isTrelloDetailFetching ? (
            <Skeleton height={200} />
          ) : (
            <RegisterShipSchedule
              bidId={trelloDetailData.id}
              shipList={shipList.list}
              management={trelloDetailData.management}
              trelloDetail={trelloDetailData}
            />
          )}

          <CargoStatusTable trelloDetail={trelloDetailData} />

          <UploadDocuments trelloDetail={trelloDetailData} />

          <SalesManagement trelloDetailData={trelloDetailData} />

          <PurchaseManagement
            trelloDetailData={trelloDetailData}
            partnerList={partnerList}
            exchangeRateList={exchangeRateList}
          />

          {APP_NAME === "shipda-admin" && (
            <Profit trelloDetail={trelloDetailData} />
          )}

          {CommentList}
        </Modal>
      );
    } else {
      if (!trelloDetailData || !zoneList || !exchangeRateList || !countryList) {
        return (
          <Modal
            destroyOnClose={true}
            style={{ fontFamily: "Roboto" }}
            bodyStyle={{ height: 800, overflow: "scroll" }}
            visible={showModal}
            width={modalWidth}
            onCancel={handleCloseModal}
            footer={null}
          >
            <Spin />
          </Modal>
        );
      }
      return (
        <Modal
          destroyOnClose={true}
          style={{ fontFamily: "Roboto" }}
          bodyStyle={{ height: 800, overflow: "scroll" }}
          visible={showModal}
          width={modalWidth}
          onCancel={handleCloseModal}
          footer={null}
        >
          <BasicInfo trelloDetail={trelloDetailData} />

          <RegisterShipSchedule
            bidId={trelloDetailData.id}
            management={trelloDetailData.management}
            trelloDetail={trelloDetailData}
          />

          <UploadDocuments trelloDetail={trelloDetailData} />

          <SalesManagement trelloDetailData={trelloDetailData} />

          <PurchaseManagement
            trelloDetailData={trelloDetailData}
            exchangeRateList={exchangeRateList}
          />

          {CommentList}
        </Modal>
      );
    }
  }, [
    CommentList,
    countryList,
    exchangeRateList,
    handleCloseModal,
    isTrelloDetailFetching,
    modalWidth,
    partnerList,
    shipList,
    showModal,
    trelloDetailData,
    zoneList,
  ]);

  return { TrelloDetailModal };
}
