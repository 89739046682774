import { MutableRefObject, RefObject, useMemo, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

import SelectButton from "../../form/SelectButton";
import Row from "./Row";
import Styled from "./index.styles";

type TableRow<CellKey extends string, RowKey extends string | number> = {
  [K in CellKey]: React.ReactNode;
} & {
  /**
   * row를 식별하는 unique한 key (id 등)
   */
  rowKey: RowKey;
  onRowClick?: () => void;
  /**
   * row의 배경색을 지정
   */
  rowBackgroundColor?: string;
  /**
   * subPanel이 필요한 경우, subPanel의 내용을 전달
   */
  subPanel?: React.ReactNode;
};

type SelectableTableRow<
  CellKey extends string,
  RowKey extends string | number
> = TableRow<CellKey, RowKey> & {
  isSelected?: boolean;
  isNotSelectable?: boolean;
  /**
   * Row 선택/체크 핸들러
   * - onRowClick과 구분됨에 유의
   */
  onRowSelect: () => void;
};

type TableCellHorizontalAlign = "center" | "left" | "right";

type TableCellVerticalAlign = "center" | "top" | "bottom";

interface TableCellInfo<CellKey extends string> {
  cellKey: CellKey;
  label: React.ReactNode;
  /**
   * 고정 width를 사용하고 싶을때 사용.
   * 미입력시 1fr이 적용됨
   */
  width?: string;
  /**
   * 반응형으로 적용되는 비율을 사용하고 싶을때 사용.
   * width와 동시에 입력하면 ratio는 무시되고 width가 사용됨
   * 미입력시 1이 적용됨 (grid 의 {n}fr의 형태로 사용됨)
   */
  minWidth?: string;
  /**
   * 반응형으로 작업시 줄어들 수 있는 최소 너비를 지정할 때 사용
   * ratio 미입력시 1이 적용됨 (grid 의 minmax(minWidth, {ratio}fr)의 형태로 사용됨)
   */
  ratio?: number;
  /**
   * 미입력시 'left'
   */
  horizontalAlign?: TableCellHorizontalAlign;
  /**
   * 미입력시 'top'
   */
  verticalAlign?: TableCellVerticalAlign;
  /**
   * 'TableHeaderFilter' 컴포넌트를 전달하여 사용
   */
  HeaderFilter?: React.ReactNode;
  /**
   * 'TableHeaderSort' 컴포넌트를 전달하여 사용
   */
  HeaderSort?: React.ReactNode;
  /**
   * 'TableHeaderTooltip' 컴포넌트를 전달하여 사용
   */
  HeaderTooltip?: React.ReactNode;
}

interface CommonTableProps<CellKey extends string> {
  /**
   * Table Cell에 대한 Meta 정보들
   * 배열의 순서가 중요함 (순서대로 데이터 표시함)
   */
  cellInfos: TableCellInfo<CellKey>[];
  className?: string;
  /**
   * string 타입이 아닌 경우 스타일을 작성한 jsx를 전달해야 합니다.
   *
   * 기본값 : "데이터가 없습니다"
   */
  labelForNoData?: React.ReactNode;
  isStickyHeader?: boolean;
  containerRef?: RefObject<HTMLDivElement>;
  tableDataCellRef?: MutableRefObject<(HTMLTableCellElement | null)[]>;
  tableHeaderRef?: MutableRefObject<HTMLTableSectionElement | null>;
}

type BasicTableProps<
  CellKey extends string,
  RowKey extends string | number
> = CommonTableProps<CellKey> & {
  selectableType?: undefined;
  rows: TableRow<CellKey, RowKey>[];
};

type SelectableTableProps<
  CellKey extends string,
  RowKey extends string | number
> = CommonTableProps<CellKey> & {
  /**
   * Row별 선택/체크이 필요할때 사용
   * - 단순히 cell에 체크박스 컴포넌트를 전달하는 방식으로 하려했으나(sds-v1의 방식),
   * (디자이너의 요청) 클릭 이벤트 영역이 특정 Cell 전체여야 한다고 하여 테이블 자체에서 구현.
   */
  selectableType: "single" | "multi";
  allSelectInfo?: {
    onAllSelect: () => void;
    isAllSelected: boolean;
  };
  rows: SelectableTableRow<CellKey, RowKey>[];
};

type TableProps<CellKey extends string, RowKey extends string | number> =
  | BasicTableProps<CellKey, RowKey>
  | SelectableTableProps<CellKey, RowKey>;

export type {
  SelectableTableRow,
  TableCellInfo,
  TableRow,
  TableCellHorizontalAlign,
  TableCellVerticalAlign,
};

export default function Table<
  CellKey extends string,
  RowKey extends string | number
>({
  cellInfos: cellInfoList,
  rows,
  className,
  containerRef,
  tableDataCellRef,
  tableHeaderRef,
  labelForNoData,
  isStickyHeader = false,
  ...propsByType
}: TableProps<CellKey, RowKey>) {
  /**
   * 원활한 grid사용을 위해 tr을 'display: contents'로 컨테이너효과를 없앴기때문에 css hover지원이 안 므로 JS로 row hover효과를 구현
   */
  const [hoveredRowKey, setHoveredRowKey] = useState<RowKey>();

  const { t } = useTranslation("sds-v2");

  const isSelectableTable = !!propsByType.selectableType;

  const isSubPanelTable = useMemo(() => {
    return rows.some((row) => !!row.subPanel);
  }, [rows]);

  const cellGridSizeList = useMemo(() => {
    const sizes = cellInfoList.map((cellInfo) => {
      return {
        width: cellInfo.width,
        ratio: cellInfo.ratio,
        minWidth: cellInfo.minWidth,
      };
    });

    return isSubPanelTable || isSelectableTable
      ? [
          ...(isSubPanelTable ? [{ width: "44px" }] : []),
          ...(isSelectableTable ? [{ width: "44px" }] : []),
          ...sizes,
        ]
      : sizes;
  }, [cellInfoList, isSubPanelTable, isSelectableTable]);

  return (
    <Styled.container
      ref={containerRef}
      className={`${className ? className : ""} table`}
    >
      <Styled.table>
        <Styled.tableHeader
          isStickyHeader={isStickyHeader}
          cellGridSizeList={cellGridSizeList}
          ref={tableHeaderRef}
        >
          <Styled.tableRow>
            {isSubPanelTable && (
              <Styled.tableHeaderCell
                key={"headCellOfSubPanelTable"}
                isSubPanelTable
              />
            )}

            {/* 선택 가능 한 테이블인 경우, headCell에 칼럼을 추가
            (전체 체크박스가 필요하면 allSelectInfo props 활용. 그 외에는 빈 칼럼) */}
            {isSelectableTable &&
              (propsByType.allSelectInfo ? (
                <Styled.tableHeaderCell key={"headCellOfSelectableType"}>
                  <SelectButton
                    uiType="checkboxOutlined"
                    size="default"
                    isLabelHidden
                    label="select button"
                    onSelect={() => {
                      if (propsByType.allSelectInfo?.onAllSelect) {
                        propsByType.allSelectInfo.onAllSelect();
                      }
                    }}
                    selected={propsByType.allSelectInfo.isAllSelected}
                    value=""
                  />
                </Styled.tableHeaderCell>
              ) : (
                <Styled.tableHeaderCell key={"headCellOfSelectableType"} />
              ))}
            {cellInfoList.map((cellInfo) => {
              return (
                <Styled.tableHeaderCell
                  key={cellInfo.cellKey}
                  horizontalAlign={cellInfo.horizontalAlign}
                >
                  {cellInfo.label}

                  {cellInfo.HeaderTooltip && cellInfo.HeaderTooltip}

                  {cellInfo.HeaderFilter && cellInfo.HeaderFilter}

                  {cellInfo.HeaderSort && cellInfo.HeaderSort}
                </Styled.tableHeaderCell>
              );
            })}
          </Styled.tableRow>
        </Styled.tableHeader>

        <Styled.tableBody cellGridSizeList={cellGridSizeList}>
          {rows.map((row, rowIndex) => {
            return (
              <Row
                key={row.rowKey}
                tableDataCellRef={tableDataCellRef}
                cellInfoList={cellInfoList}
                row={row}
                rowIndex={rowIndex}
                hoveredRowKey={hoveredRowKey}
                setHoveredRowKey={setHoveredRowKey}
                propsByType={propsByType}
              />
            );
          })}
        </Styled.tableBody>
      </Styled.table>

      {!rows?.length && !labelForNoData && (
        <Styled.noData>{t("sds-v2:Table_데이터_없음")}</Styled.noData>
      )}

      {!rows?.length && labelForNoData && (
        <>
          {typeof labelForNoData === "string" ? (
            <Styled.noData>{labelForNoData}</Styled.noData>
          ) : (
            labelForNoData
          )}
        </>
      )}
    </Styled.container>
  );
}
