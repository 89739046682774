import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Typography } from "@mui/material";
import { Button, Descriptions, List, message, Modal, Select } from "antd";
import { useSetRecoilState } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { BidStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { BID_STATUS_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";
import {
  changeBidProjectStatusNameToKr,
  checkHasTrelloCard,
} from "@sellernote/_shared/src/utils/forwarding/bid";
import { checkIsSettlement } from "@sellernote/_shared/src/utils/forwarding/trello";

import Styled from "./index.styles";

const { Option } = Select;
const { confirm } = Modal;

const BasicDesc = ({
  bidDetail,
  adminUserList,
}: {
  bidDetail: AdminBidDetail;
  adminUserList: ForwardingAdminUserListItem[];
}) => {
  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const history = useHistory();

  const queryClient = useQueryClient();

  const { data: forwardingManagerHistory } =
    ADMIN_BID_QUERY.useGetForwardingManagerHistory({
      teamId: bidDetail.team.id,
      bidId: bidDetail.id,
    });

  const {
    mutate: changeBidDetail,
    ResponseHandler: ResponseHandlerOfChangeBidDetail,
  } = ADMIN_BID_QUERY.useChangeBidDetail(bidDetail.id);

  const { mutate: changeBidStatusToWaitingForExporterInfo } =
    ADMIN_BID_QUERY.useChangeBidStatusToWaitingForExporterInfo(bidDetail.id);

  const getAdminName = useCallback(
    (adminId: number) => {
      return (
        adminUserList.find((n) => {
          return n.id === adminId;
        })?.name || "-"
      );
    },
    [adminUserList]
  );

  const handleForwardingManagerHistoryModalOpenClick = useCallback(() => {
    Modal.info({
      title: "담당자 변경 기록",
      content: (
        <List
          itemLayout="horizontal"
          dataSource={forwardingManagerHistory}
          renderItem={(item: {
            changedAt: Date;
            forwardingManagerId: number;
          }) => (
            <List.Item>
              <List.Item.Meta
                title={getAdminName(item.forwardingManagerId)}
                description={toFormattedDate(
                  item.changedAt,
                  "YYYY.MM.DD hh:mm:ss a"
                )}
              />
            </List.Item>
          )}
        />
      ),
    });
  }, [forwardingManagerHistory, getAdminName]);

  const openBidStatusToCanceledConfirmModal = useCallback(
    (status: BidStatus) => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: "이 운임을 완전종료하시겠습니까?",
        onOk() {
          changeBidDetail(
            {
              status,
              projectStatus: "canceled",
            },
            {
              onSuccess: () => {
                message.success("의뢰 취소로 변경했습니다.");
                queryClient.invalidateQueries(
                  ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                    bidId: bidDetail.id,
                  })
                );
              },

              onError: () => {
                message.error("에러가 있습니다 개발자에게 문의해주세요");
              },
            }
          );
        },
      });
    },
    [bidDetail.id, changeBidDetail, queryClient]
  );

  const openBidStatusToWaitingForExporterInfoModal = useCallback(() => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "제출한 견적서를 수락 처리 하시겠습니까?",
      onOk() {
        changeBidStatusToWaitingForExporterInfo(
          {},
          {
            onSuccess: () => {
              message.success("수출자 정보 입력 대기중으로 변경했습니다.");
              queryClient.invalidateQueries(
                ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                  bidId: bidDetail.id,
                })
              );
            },

            onError: () => {
              message.error("에러가 있습니다 개발자에게 문의해주세요");
            },
          }
        );
      },
    });
  }, [bidDetail.id, changeBidStatusToWaitingForExporterInfo, queryClient]);

  const handleBidStatusChangeSelect = useCallback(
    (status: BidStatus) => {
      if (status === "waitingForExporterInfo") {
        return openBidStatusToWaitingForExporterInfoModal();
      }

      if (status === "finished") {
        return openBidStatusToCanceledConfirmModal(status);
      }

      changeBidDetail(
        {
          status,
        },
        {
          onSuccess: () => {
            message.success("의뢰 상태를 변경했습니다.");
            queryClient.invalidateQueries(
              ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                bidId: bidDetail.id,
              })
            );
          },

          onError: () => {
            message.error("에러가 있습니다 개발자에게 문의해주세요");
          },
        }
      );
    },
    [
      bidDetail.id,
      changeBidDetail,
      openBidStatusToCanceledConfirmModal,
      openBidStatusToWaitingForExporterInfoModal,
      queryClient,
    ]
  );

  const handleMoveToTrelloPageClick = () => {
    if (checkHasTrelloCard(bidDetail.projectStatus)) {
      setTrelloCardId(bidDetail.id);

      if (
        checkIsSettlement(bidDetail.projectStatus) &&
        APP_NAME === "shipda-admin"
      ) {
        history.push("/settlement");
        return;
      }

      if (bidDetail.serviceType === "consolidation") {
        history.push("/trello/consolidation");
        return;
      }

      history.push("/trello/general");
      return;
    }
    message.warning("트렐로 카드가 없는 의뢰입니다.");
    return;
  };

  const handleMoveToUserDetailPageClick = useCallback(() => {
    if (bidDetail.team.isPrivate) {
      history.push(`/users/list/${bidDetail.userId}`);
      return;
    }
    history.push(`/team/${bidDetail.team.id}`);
  }, [bidDetail.team.id, bidDetail.team.isPrivate, bidDetail.userId, history]);

  const statusSelectDisabled = useCallback(
    (selectStatus: BidStatus | "all") => {
      const defaultDisabledStatus =
        selectStatus === "committed" ||
        selectStatus === "waiting" ||
        selectStatus === "inProgress";

      if (
        bidDetail.status === "committed" ||
        bidDetail.status === "estimating"
      ) {
        if (
          defaultDisabledStatus ||
          selectStatus === "waitingForExporterInfo"
        ) {
          return true;
        }
        return false;
      }

      if (defaultDisabledStatus) {
        return true;
      }
      return false;
    },
    [bidDetail.status]
  );

  return (
    <Styled.basicDescContainer>
      <Descriptions
        className="descriptions"
        key={"basicDesc"}
        column={4}
        title={
          <Button
            type={"link"}
            size={"large"}
            onClick={handleMoveToTrelloPageClick}
          >{`의뢰번호: ${bidDetail.id}`}</Button>
        }
      >
        <Descriptions.Item label="회사명">
          <Button
            className="user-company"
            onClick={handleMoveToUserDetailPageClick}
            type="link"
          >
            {bidDetail.team.company}
          </Button>
        </Descriptions.Item>

        <Descriptions.Item label="팀명">
          <Button
            className="user-company"
            onClick={handleMoveToUserDetailPageClick}
            type="link"
          >
            {bidDetail.team.name}
          </Button>
        </Descriptions.Item>

        <Descriptions.Item label="담당자 이름">
          {bidDetail.user.name}
        </Descriptions.Item>

        <Descriptions.Item label="연락처">
          {bidDetail.user.phone}
        </Descriptions.Item>

        <Descriptions.Item label="이메일">
          {bidDetail.user.email}
        </Descriptions.Item>

        <Descriptions.Item span={2} label="상태">
          <Select
            disabled={APP_NAME === "partner-admin"}
            value={bidDetail.status}
            className="status-select"
            onSelect={handleBidStatusChangeSelect}
          >
            {BID_STATUS_OPTION_LIST.map((v) => {
              return (
                <Option
                  disabled={statusSelectDisabled(v.value)}
                  hidden={v.value === "all"}
                  key={v.value}
                  value={v.value}
                >
                  {v.label}
                </Option>
              );
            })}
          </Select>
        </Descriptions.Item>

        <Descriptions.Item span={2} label="세부 상태">
          {changeBidProjectStatusNameToKr(bidDetail.projectStatus)}
        </Descriptions.Item>

        <Descriptions.Item span={2} label="포워딩 담당자">
          <Typography
            className="forwarding-manager"
            onClick={handleForwardingManagerHistoryModalOpenClick}
          >
            {getAdminName(
              !bidDetail.team ? 0 : bidDetail.team.forwardingManagerId
            )}
          </Typography>
        </Descriptions.Item>
      </Descriptions>

      {ResponseHandlerOfChangeBidDetail}
    </Styled.basicDescContainer>
  );
};

export default BasicDesc;
