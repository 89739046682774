import { ReactNode } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

function AdminDetailDescription({
  title,
  descriptionValueList,
  topBarItemList,
  isEditMode,
  subTitle,
}: {
  title?: string;
  descriptionValueList:
    | {
        label: string;
        value: ReactNode;
        /** MUI Grid item에 사이즈 설정 기본 값은 3이고 이때는 한줄에 최대 4개가 들어감*/
        gridSize?: number;
        editComponent?: ReactNode;
      }[]
    | undefined;
  /** 수정, 요청 등의 버튼 등 아이템을 추가할 수 있음 */
  topBarItemList?: JSX.Element[];
  isEditMode?: boolean;
  subTitle?: ReactNode;
}) {
  return (
    <Box padding={2}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item>
          {title && (
            <Typography variant="h6" component="span">
              {title}
            </Typography>
          )}
        </Grid>

        <Grid item>{subTitle}</Grid>

        {topBarItemList && (
          <Grid item container justifyContent={"flex-end"}>
            {topBarItemList.map((item, index) => (
              <Grid key={index}>{item}</Grid>
            ))}
          </Grid>
        )}

        <Grid item container spacing={2}>
          {descriptionValueList?.map((v, index) => (
            <Grid item container xs={v?.gridSize || 3} key={index}>
              <Grid
                item
                container
                spacing={1}
                alignItems={
                  isEditMode && v.editComponent ? "center" : "baseline"
                }
              >
                <Grid item>
                  <Typography color={grey[600]}>{v.label}: </Typography>
                </Grid>

                <Grid item xs={8}>
                  {isEditMode && v.editComponent ? v.editComponent : v.value}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminDetailDescription;
