import { useCallback, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useRecoilValue } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { FORWARDING_AUTH_SELECTORS } from "@sellernote/_shared/src/states/forwarding/auth";
import { ShipList } from "@sellernote/_shared/src/types/forwarding/ship";
import {
  TrelloBidDetail,
  TrelloBidManagement,
} from "@sellernote/_shared/src/types/forwarding/trello";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

import DatePicker from "../../../../../../components/DatePicker";

import EditShipScheduleModal from "./EditShipScheduleModal";
import RegisterShipImoModal from "./RegisterShipImoModal";
import ShipLetterAndShipImo from "./ShipLetterAndShipImo";
import ShipScheduleConfirmModal from "./ShipScheduleConfirmModal";

// TODO: 요청 값이 변경됨 API 반영(AB8948) 후 추가
const RegisterShipSchedule = ({
  bidId,
  shipList,
  management,
  trelloDetail,
}: {
  bidId: number;
  shipList?: ShipList[];
  management: TrelloBidManagement;
  trelloDetail: TrelloBidDetail;
}) => {
  const currentUser = useRecoilValue(FORWARDING_AUTH_SELECTORS.CURRENT_MANAGER);

  const {
    UploadResponseSnackBar,
    setShowsSuccessSnackBar,
    setShowsErrorSnackBar,
    setErrorMessage,
  } = useUploadResponseSnackBar();

  const [etd, setEtd] = useState<string | null>(management.ETD);
  const [eta, setEta] = useState<string | null>(management.ETA);
  const [cargoReady, setCargoReady] = useState<string | null>(
    management.cargoReady
  );
  const [voyageNumber, setVoyageNumber] = useState(management.voyageNumber);
  const [shipName, setShipName] = useState<string | undefined>(
    management.shipName
  );

  const [shipImo, setShipImo] = useState(management.shipIMO || 0);
  const [
    showsRegisterShipScheduleConfirmModal,
    setShowsRegisterShipScheduleConfirmModal,
  ] = useState(false);

  const [showsRegisterShipImoModal, setShowsRegisterShipImoModal] =
    useState(false);
  const [showsEditShipScheduleModal, setShowsEditShipScheduleModal] =
    useState(false);

  const shipNameList = useMemo(() => {
    return shipList?.map((v: ShipList) => {
      return { value: v.name, label: v.name };
    });
  }, [shipList]);

  const checkIfButtonIsDisabled = useCallback(() => {
    if (currentUser?.authority === "finance") {
      if (
        trelloDetail.projectStatus === "beforeContactPartner" ||
        trelloDetail.projectStatus === "contactingPartner" ||
        trelloDetail.projectStatus === "scheduling" ||
        trelloDetail.projectStatus === "moving"
      ) {
        return true;
      }
    }

    if (
      trelloDetail.projectStatus === "inSettlement" ||
      trelloDetail.projectStatus === "settlementConfirmed" ||
      trelloDetail.projectStatus === "settlementSecondConfirmed" ||
      trelloDetail.projectStatus === "settlementComplete" ||
      trelloDetail.projectStatus === "portEntryAndPrepareCustoms" ||
      trelloDetail.projectStatus === "payment" ||
      trelloDetail.projectStatus === "completeCustoms" ||
      trelloDetail.projectStatus === "delivering" ||
      trelloDetail.projectStatus === "finished" ||
      trelloDetail.projectStatus === "canceled" ||
      trelloDetail.projectStatus === "failed"
    ) {
      return true;
    }
    return false;
  }, [currentUser?.authority, trelloDetail.projectStatus]);

  const getShipImo = useCallback(
    (name: string | undefined) => {
      const findShipData = shipList?.find((v: ShipList) => {
        return v.name === name;
      });
      if (findShipData) {
        return findShipData.IMO;
      }
      return 0;
    },
    [shipList]
  );

  const handleShipScheduleConfirmModalClose = () => {
    setErrorMessage("취소되었습니다.");
    setShowsErrorSnackBar(true);
    setShowsRegisterShipScheduleConfirmModal(false);
  };

  const inputDisabled =
    management.firstScheduleEmailSent || APP_NAME !== "shipda-admin";

  return (
    <Box sx={{ padding: 1 }}>
      <ShipLetterAndShipImo
        trelloDetail={trelloDetail}
        setShowsErrorSnackBar={setShowsErrorSnackBar}
        setShowsSuccessSnackBar={setShowsSuccessSnackBar}
      />

      <Box sx={{ backgroundColor: grey[100] }}>
        <Grid container justifyContent={"space-between"} padding={2}>
          <Grid item container xs={6} spacing={2} alignItems={"center"}>
            <Grid item>
              <Typography variant="h5">선박 스케줄</Typography>
            </Grid>

            {trelloDetail.management.shipIMO === 0 && (
              <Grid item>
                <Typography variant="body1" color={"error"}>
                  *존재하지 않는 모선명입니다. 변경해 주세요
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid item>
            {management.firstScheduleEmailSent ? (
              <Button
                variant="contained"
                disabled={
                  checkIfButtonIsDisabled() || APP_NAME !== "shipda-admin"
                }
                onClick={() => setShowsEditShipScheduleModal(true)}
              >
                스케줄 수정하기
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled={
                  APP_NAME !== "shipda-admin" ||
                  !eta ||
                  !etd ||
                  (trelloDetail.freightType !== "AIR" && !shipName) ||
                  trelloDetail.projectStatus === "beforeContactPartner" ||
                  trelloDetail.management.shipIMO === 0
                }
                onClick={() => setShowsRegisterShipScheduleConfirmModal(true)}
              >
                스케줄 발송하기
              </Button>
            )}
          </Grid>
        </Grid>

        <Divider />

        <Grid container padding={2}>
          <Grid item>
            <Typography variant="subtitle1">
              필수 정보를 입력한 이후 "스케줄 발송하기" 버튼을 클릭하세요.
              (필수값: *)
            </Typography>
          </Grid>
        </Grid>

        <Grid container padding={2} spacing={2}>
          <Grid item>
            <Typography variant="body1">
              화물준비일정(Cargo Ready) 일정을 입력하세요.
            </Typography>
          </Grid>

          <Grid item container alignItems={"center"}>
            <Grid item md={1} lg={0.7} xl={0.6}>
              <Typography>카고레디:</Typography>
            </Grid>

            <Grid item xs={3}>
              <DatePicker
                disabled={inputDisabled}
                when={"start"}
                value={cargoReady}
                setDate={setCargoReady}
                fullWidth={true}
                isNotISOString={true}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container padding={2} spacing={2}>
          <Grid item>
            <Typography variant="body1">
              고객에게 안내할 스케줄을 입력하세요.
            </Typography>
          </Grid>

          <Grid item>
            {management.shippingScheduleUpdate?.includes("invalidSchedule") && (
              <Typography
                key="warning"
                color="error"
                variant="body2"
                component={"div"}
              >
                *유효하지 않은 스케줄입니다.
              </Typography>
            )}
          </Grid>

          <Grid item container alignItems={"center"}>
            <Grid item md={1} lg={0.7} xl={0.6}>
              <Typography>*ETD:</Typography>
            </Grid>

            <Grid item xs={3}>
              <DatePicker
                disabled={inputDisabled}
                when={"end"}
                value={etd}
                setDate={setEtd}
                fullWidth={true}
                isNotISOString={true}
              />
            </Grid>

            <Grid item md={1} lg={0.7} xl={0.6}>
              <Typography>*ETA:</Typography>
            </Grid>

            <Grid item xs={3}>
              <DatePicker
                disabled={inputDisabled}
                when={"start"}
                value={eta}
                setDate={setEta}
                fullWidth={true}
                isNotISOString={true}
              />
            </Grid>
          </Grid>

          <Grid item container direction={"column"} spacing={1}>
            {trelloDetail.freightType !== "AIR" && (
              <Grid item container alignItems={"center"}>
                <Grid item md={1} lg={1} xl={0.6}>
                  <Typography>*모선명</Typography>
                </Grid>

                {APP_NAME === "partner-admin" && (
                  <Grid item container xs={5}>
                    <TextField
                      disabled={inputDisabled}
                      fullWidth
                      value={management.shipName}
                      size="small"
                      sx={{
                        // 라벨 노치 제거
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                    />
                  </Grid>
                )}

                {shipNameList && (
                  <Grid item container xs={5}>
                    <Autocomplete
                      disabled={inputDisabled}
                      size="small"
                      fullWidth
                      options={shipNameList}
                      defaultValue={
                        management.shipName
                          ? {
                              value: management.shipName,
                              label: management.shipName,
                            }
                          : null
                      }
                      renderInput={(params) => (
                        <TextField
                          placeholder="모선명을 입력하세요."
                          {...params}
                          // 라벨 노치 제거 TODO: 쉽다 어드민 전용 텍스트필드 추가
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                        />
                      )}
                      onChange={(
                        event,
                        newValue: { value: string; label: string } | null
                      ) => {
                        setShipName(newValue?.value);
                        setShipImo(getShipImo(newValue?.value));
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                    />
                  </Grid>
                )}
              </Grid>
            )}

            <Grid item container alignItems={"center"}>
              <Grid item md={1} lg={0.7} xl={0.6}>
                <Typography>
                  {trelloDetail.freightType === "AIR" ? "편명" : "항차"}
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <TextField
                  disabled={inputDisabled}
                  fullWidth
                  onChange={(e) => setVoyageNumber(e.target.value)}
                  value={voyageNumber ?? undefined}
                  placeholder={`${
                    trelloDetail.freightType === "AIR" ? "편명" : "항차"
                  }를 입력해주세요.`}
                  size="small"
                  sx={{
                    // 라벨 노치 제거
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {showsRegisterShipImoModal && (
          <RegisterShipImoModal
            showsRegisterShipImoModal={showsRegisterShipImoModal}
            setShowsRegisterShipImoModal={setShowsRegisterShipImoModal}
          />
        )}

        {showsRegisterShipScheduleConfirmModal && (
          <ShipScheduleConfirmModal
            showsRegisterShipScheduleConfirmModal={
              showsRegisterShipScheduleConfirmModal
            }
            setShowsRegisterShipScheduleConfirmModal={
              setShowsRegisterShipScheduleConfirmModal
            }
            etd={etd}
            eta={eta}
            shipName={shipName}
            bidId={bidId}
            shipImo={shipImo}
            trelloDetail={trelloDetail}
            onClose={handleShipScheduleConfirmModalClose}
            voyageNumber={voyageNumber}
            cargoReady={cargoReady}
          />
        )}

        {showsEditShipScheduleModal && (
          <EditShipScheduleModal
            freightType={trelloDetail.freightType}
            setShowsEditShipScheduleModal={setShowsEditShipScheduleModal}
            showsEditShipScheduleModal={showsEditShipScheduleModal}
            bidId={bidId}
            management={management}
            shipList={shipList}
          />
        )}

        {UploadResponseSnackBar}
      </Box>
    </Box>
  );
};

export default RegisterShipSchedule;
