import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";

export type AutoCompleteWithReactHookFormProps<
  T extends FieldValues,
  K extends string
> = Omit<
  AutocompleteProps<
    K,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >,
  // renderInput을 받는 것보다 안에서 사용하는게 더 좋을 것 같다고 판단 사용 후 다른 의견 있을 시 수정
  "renderInput"
> & {
  label?: string;
  name: Path<T>;
  control?: Control<T>;
  options: K[];
  defaultValue?: PathValue<T, (string | undefined) & Path<T>>;
  required?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  handleEffectOnChange?: () => void;
};

export default function AutoCompleteWithReactHookForm<
  TFieldValues extends FieldValues,
  K extends string
>({
  label,
  name,
  options,
  control,
  defaultValue,
  errorMessage,
  disabled,
  required,
  handleEffectOnChange,
}: AutoCompleteWithReactHookFormProps<TFieldValues, K>) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required,
        }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            disabled={disabled}
            value={field.value || null}
            onChange={(_, data) => {
              field.onChange(data);
              handleEffectOnChange && handleEffectOnChange();
            }}
            getOptionLabel={(option) => option}
            options={options}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={label}
                onChange={(e) => field.onChange(e.target.value)}
                error={error !== undefined}
                helperText={
                  error &&
                  (error.type === "required"
                    ? "필수 입력 사항입니다."
                    : errorMessage)
                }
              />
            )}
          />
        )}
      />
    </>
  );
}
