import { useParams } from "react-router-dom";
import { BackTop, Divider } from "antd";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import CUSTOMS_ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_BID_QUERY";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useCommentList from "@sellernote/_shared-for-forwarding-admin/src/hooks/useCommentList";
import BasicDesc from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/:id/BasicDesc";
import ConsolidationCheckPointDesc from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/:id/ConsolidationCheckPointDesc";
import ExporterDesc from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/:id/ExporterDesc";
import TransportationInfo from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/:id/TransportationInfo";

import BidDetailTabs from "./BidDetailTabs";
import ConsolidationEndAddress from "./ConsolidationEndAddress";
import ConsolidationItem from "./ConsolidationItem";
import ConsolidationTotalDesc from "./ConsolidationTotalDesc";
import WarehouseInfoDesc from "./WarehouseInfoDesc";
import Styled from "./index.styles";

//TODO: 리팩토링 후 _shared-for-forwarding-admin으로 이동
const BidDetail = () => {
  const { id }: { id: string } = useParams();

  const { data: bidDetail } = CUSTOMS_ADMIN_BID_QUERY.useGetCustomsBidDetail({
    bidId: Number(id),
  });

  const { adminUserList } = ADMIN_USER_QUERY.useGetAdminUserList();

  const { data: portList } = COMMON_QUERY.useGetPortList();

  const { data: zoneList } = COMMON_QUERY.useGetZoneList();

  const { data: countryList } = COMMON_QUERY.useGetCountryList();

  const { data: exchangeRateList } = COMMON_QUERY.useGetExchangeRateList({
    all: true,
  });

  const { CommentList } = useCommentList({
    commentList: bidDetail?.management.comments,
    dataId: bidDetail?.id,
    dataType: "bid",
  });

  if (
    !bidDetail ||
    !adminUserList ||
    !portList ||
    !zoneList ||
    !countryList ||
    !exchangeRateList
  ) {
    return (
      <Layout breadcrumbs={["쉽다 의뢰"]} title={"의뢰 상세"}>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout breadcrumbs={["십다 의뢰"]} title={"의뢰 상세"}>
      <BackTop />

      <Styled.basicDescContainer>
        <div className="create-date">
          <span className="date-label">생성일:</span>

          {new Date(bidDetail.createdAt).toLocaleDateString()}
        </div>

        <BasicDesc bidDetail={bidDetail} adminUserList={adminUserList} />
      </Styled.basicDescContainer>

      <Styled.otherDescContainer>
        <div className="white-background">
          {bidDetail.serviceType === "consolidation" && (
            <div className="consolidation-item">
              <div className="title">수출자 및 화물 정보</div>

              <ConsolidationItem
                sellerData={bidDetail.sellerList}
                bidId={bidDetail.id}
              />
            </div>
          )}

          {bidDetail.serviceType !== "consolidation" && (
            <ExporterDesc bidDetail={bidDetail}></ExporterDesc>
          )}

          <Divider />

          {CommentList}

          {bidDetail.serviceType !== "consolidation" && <Divider />}

          {bidDetail.serviceType !== "consolidation" && (
            <TransportationInfo
              bidDetail={bidDetail}
              portData={portList}
              zoneData={zoneList}
              countryList={countryList}
            />
          )}

          {bidDetail.serviceType !== "consolidation" && <Divider />}

          {bidDetail.serviceType === "consolidation" && (
            <ConsolidationTotalDesc bidDetail={bidDetail} />
          )}

          {bidDetail.serviceType === "consolidation" && (
            <ConsolidationCheckPointDesc bidDetail={bidDetail} />
          )}

          {bidDetail.serviceType === "consolidation" && (
            <ConsolidationEndAddress bidDetail={bidDetail} />
          )}

          {bidDetail.serviceType === "consolidation" && (
            <WarehouseInfoDesc bidDetail={bidDetail} />
          )}

          <BidDetailTabs bidDetail={bidDetail} />
        </div>
      </Styled.otherDescContainer>
    </Layout>
  );
};

export default withRequireAuth(BidDetail);
