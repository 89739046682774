import { FixedSizeList as List } from "react-window";
import { Box } from "@mui/material";

import { GET_BID_EXPORTER_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/bidExporter";
import { bidExporterProps } from "@sellernote/_shared/src/types/forwarding/bidExporter";

import ExporterCard from "./ExporterCard";

interface RowProps {
  index: number;
  style: React.CSSProperties;
  data: {
    list: bidExporterProps[];
  };
}

const ModalContents = (data: GET_BID_EXPORTER_LIST_RES) => {
  const Row = ({ index, style, data }: RowProps) => (
    <div style={style}>
      <ExporterCard {...data.list[index]} />
    </div>
  );

  return (
    <Box sx={{ overflowY: "scroll", maxHeight: "450px" }}>
      <List
        width={450}
        height={450}
        itemCount={data?.list.length}
        itemSize={120}
        itemData={{ list: data?.list }}
      >
        {Row}
      </List>
    </Box>
  );
};

export default ModalContents;
