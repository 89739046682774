import { useMemo } from "react";

import { GET_TRELLO_ATTACHMENTS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";
import { APP_NAME } from "@sellernote/_shared/src/constants";
import { TrelloUploadDocumentTableData } from "@sellernote/_shared/src/types/forwarding/trello";

export default function useTrelloUploadDocumentsTableData(
  attachments: GET_TRELLO_ATTACHMENTS_RES | undefined
) {
  const trelloUploadDocumentTableData = useMemo(() => {
    const uploadDocumentTableData: TrelloUploadDocumentTableData[] = [
      {
        domain: "CI_PL",
        attachments: [],
      },
      {
        domain: "BL",
        attachments: [],
      },
      {
        domain: "CO",
        attachments: [],
      },
      {
        domain: "brn",
        attachments: [],
      },
      { domain: "certificate", attachments: [] },
      { domain: "etc", attachments: [] },
      {
        domain: "importTax",
        attachments: [],
      },
      {
        domain: "importDeclaration",
        attachments: [],
      },
      { domain: "houseDo", attachments: [] },
      {
        domain: "releaseRequest",
        attachments: [],
      },
      {
        domain: "parcelRequest",
        attachments: [],
      },
      {
        domain: "adminTemp",
        attachments: [],
      },
    ];

    if (!attachments) {
      return;
    }

    return attachments.reduce((arr, cur) => {
      arr.forEach((v) => {
        if (v.domain === cur.domain) {
          v.attachments = [...v.attachments, cur];
        }

        return;
      });

      return arr;
    }, uploadDocumentTableData);
  }, [attachments])?.filter((v) => {
    if (APP_NAME === "partner-admin") {
      if (
        v.domain === "parcelRequest" ||
        v.domain === "releaseRequest" ||
        v.domain === "houseDo" ||
        v.domain === "adminTemp"
      ) {
        return false;
      }
    }
    return true;
  });

  return { trelloUploadDocumentTableData };
}
