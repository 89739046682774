import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Button, CircularProgress } from "@mui/material";
import { message, Modal } from "antd";
import { useRecoilValue, useSetRecoilState } from "recoil";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_ADMIN_TRELLO_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminTrello";
import { FORWARDING_AUTH_SELECTORS } from "@sellernote/_shared/src/states/forwarding/auth";
import {
  Currency,
  FreightType,
  ResponseFailureInfo,
} from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  TrelloBidDetail,
  WithdrawalRequestDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDateToUTCDate } from "@sellernote/_shared/src/utils/common/date";
import { getUnitPriceByCurrencyOfFeeData } from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import {
  calculateVATPrice,
  changePurchaseDomainToTemplateCategory,
  getWithdrawalExchangeRate,
} from "@sellernote/_shared/src/utils/forwarding/trello";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";

import TemplateSearch from "../../../../../../../containers/TemplateSearch";

import DatePicker from "../../../../../../../components/DatePicker";

import CancelWithdrawModal from "./CancelWithdrawModal";
import CurrencyFilter from "./CurrencyFilter";
import PurchaseRequestForm from "./PurchaseRequestForm";
import Styled from "./index.styles";

const PurchaseRequestModal = ({
  showsPurchaseInvoiceRequestModal,
  setShowsPurchaseInvoiceRequestModal,
  bidId,
  companyType,
  freightType,
  companyId,
  exchangeRateList,
  bidAccountPayableId,
  setShowsErrorSnackBar,
  setShowsSuccessSnackBar,
  trelloDetailData,
}: {
  freightType: FreightType;
  bidId: number;
  companyType: PartnerBusinessArea;
  showsPurchaseInvoiceRequestModal: boolean;
  companyId: number;
  setShowsPurchaseInvoiceRequestModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  exchangeRateList: ExchangeRate[];
  bidAccountPayableId: number;
  setShowsSuccessSnackBar: Dispatch<SetStateAction<boolean>>;
  setShowsErrorSnackBar: Dispatch<SetStateAction<boolean>>;
  trelloDetailData: TrelloBidDetail;
}) => {
  const currentUser = useRecoilValue(FORWARDING_AUTH_SELECTORS.CURRENT_MANAGER);

  const setIsLoadedWithdrawalTemplateData = useSetRecoilState(
    FORWARDING_ADMIN_TRELLO_ATOMS.IS_LOADED_WITHDRAWAL_TEMPLATE_DATA
  );

  const queryClient = useQueryClient();

  const [currency, setCurrency] = useState<Currency>("USD");
  const [isNewCurrency, setIsNewCurrency] = useState(false);
  const [isInputCurrency, setIsInputCurrency] = useState(false);
  const [inputCurrency, setInputCurrency] = useState(0);
  const [showCancelWithdrawModal, setShowCancelWithdrawModal] = useState(false);
  const [withdrawalInvoiceId, setWithdrawalInvoiceId] = useState<string>("");
  const [withdrawalInvoiceDate, setWithdrawalInvoiceDate] = useState<
    null | string
  >(null);

  const {
    basicWithdrawalRequestList,
    isSave,
    withdrawalRequestData,
    withdrawalItemList,
  } = TRELLO_BID_QUERY.useGetWithdrawalBasicList({
    bidAccountPayableId,
  });

  /**
   * useGetWithdrawalBasicList의 onSuccess 사용시 withdrawalInvoiceDate가 리렌더되지 않는 버그 발생
   * TODO: onSuccess 로직으로 해결, useEffect 삭제
   */
  useEffect(() => {
    if (withdrawalRequestData) {
      setWithdrawalInvoiceId(withdrawalRequestData.withdrawalInvoiceId ?? "");

      if (!withdrawalRequestData.withdrawalInvoiceDate) {
        setWithdrawalInvoiceDate(null);
        return;
      }

      setWithdrawalInvoiceDate(
        toFormattedDateToUTCDate(withdrawalRequestData.withdrawalInvoiceDate)
      );
    }
  }, [withdrawalRequestData]);

  const {
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ApplyBidFormData>();

  useEffect(() => {
    const getItemUnitMeasurement = () => {
      if (freightType === "FCL") {
        return "CNTR";
      }

      if (freightType === "LCL") {
        return "R.TON";
      }
      return "C/W";
    };

    if (basicWithdrawalRequestList) {
      const newBasicWithDrawalList =
        basicWithdrawalRequestList.map<WithdrawalRequestDetail>((v) => {
          if (!v.itemUnitMeasurement) {
            return {
              ...v,
              itemUnitMeasurement: getItemUnitMeasurement(),
            };
          }
          return v;
        });
      reset({ withdrawalData: newBasicWithDrawalList });
    }
  }, [basicWithdrawalRequestList, freightType, reset]);

  useEffect(() => {
    if (withdrawalRequestData) {
      setIsNewCurrency(true);
      setCurrency(withdrawalRequestData.currency);
      setWithdrawalInvoiceId(withdrawalRequestData.withdrawalInvoiceId);
    }
  }, [withdrawalRequestData]);

  const { mutate: requestWithdrawal, isLoading } =
    TRELLO_BID_QUERY.useRequestWithdrawal();

  const withdrawalData = watch("withdrawalData");

  const isOnlyKrCurrency = useMemo(() => {
    return withdrawalData?.find((v) => {
      return v.currency !== "KRW";
    })
      ? false
      : true;
  }, [withdrawalData]);

  const exchangeRate = useMemo(() => {
    const findCurrencyData = exchangeRateList?.find((v: ExchangeRate) => {
      return v.currency === currency;
    });
    if (findCurrencyData) {
      return findCurrencyData.rate;
    }
    return 0;
  }, [currency, exchangeRateList]);

  const saveExchangeRate = useMemo(() => {
    if (!withdrawalRequestData) {
      return 0;
    }
    return withdrawalRequestData.exchangeRate;
  }, [withdrawalRequestData]);

  const changeWithdrawalCurrencyAfterTemplateUpdate = (currency: Currency) => {
    setCurrency(currency);
    setIsNewCurrency(false);
  };

  const changeCurrency = useCallback(
    (e: Currency) => {
      let newWithdrawalData: WithdrawalRequestDetail[] = [...withdrawalData];

      newWithdrawalData = withdrawalData?.map<WithdrawalRequestDetail>((n) => {
        if (n.currency === "KRW") {
          return {
            ...n,
          };
        }
        return {
          ...n,
          currency: e,
        };
      });

      if (isNewCurrency) {
        setIsNewCurrency(false);
      }
      reset({ withdrawalData: newWithdrawalData });
    },
    [isNewCurrency, reset, withdrawalData]
  );

  const handleWithdrawalUpdateCurrencyClick = useCallback(() => {
    setIsNewCurrency(false);
    setIsInputCurrency(false);
    setInputCurrency(0);

    const newWithdrawalData = withdrawalData.map(
      (v: WithdrawalRequestDetail) => {
        const totalPrice = Number(
          (
            getUnitPriceByCurrencyOfFeeData(exchangeRateList, v) * v.amount
          ).toFixed(0)
        );

        const vatPrice = calculateVATPrice(v, totalPrice, companyType);
        const finalPrice = totalPrice + vatPrice;
        if (companyType === "foreign") {
          return { ...v, finalPrice: totalPrice, vatPrice: 0, totalPrice: 0 };
        }
        return { ...v, totalPrice, finalPrice, vatPrice };
      }
    );

    reset({ withdrawalData: newWithdrawalData });
  }, [companyType, exchangeRateList, reset, withdrawalData]);

  const getTotalPrice = useCallback(
    (v: WithdrawalRequestDetail) => {
      if (v.currency === "KRW") {
        // KRW일 때는 exchangeRate를 곱해줄 필요가 없음
        return Number((v.amount * v.unitPrice).toFixed(0));
      }

      if (!inputCurrency) {
        if (withdrawalRequestData) {
          setInputCurrency(saveExchangeRate);
          return Number((saveExchangeRate * v.amount * v.unitPrice).toFixed(0));
        }
        setInputCurrency(exchangeRate);
        return Number((exchangeRate * v.amount * v.unitPrice).toFixed(0));
      }
      return Number((inputCurrency * v.amount * v.unitPrice).toFixed(0));
    },
    [exchangeRate, inputCurrency, saveExchangeRate, withdrawalRequestData]
  );

  const handleWithdrawalCurrencyClick = useCallback(() => {
    setIsNewCurrency(false);
    setIsInputCurrency(true);
    const newWithdrawalData = withdrawalData.map<WithdrawalRequestDetail>(
      (v) => {
        const totalPrice = getTotalPrice(v);

        const vatPrice = calculateVATPrice(v, totalPrice, companyType);
        const finalPrice = totalPrice + vatPrice;

        if (companyType === "foreign") {
          return { ...v, finalPrice: totalPrice, totalPrice: 0, vatPrice: 0 };
        }
        return { ...v, totalPrice, finalPrice, vatPrice };
      }
    );

    reset({ withdrawalData: newWithdrawalData });
  }, [withdrawalData, reset, getTotalPrice, companyType]);

  const getPartnerNameKr = useCallback((text: PartnerBusinessArea) => {
    switch (text) {
      case "parcel":
        return "화물택배사 지불금액";
      case "foreign":
        return "해외파트너 지불금액";
      case "domestic":
        return "국내파트너 지불금액";
      case "customs":
        return "관세사 지불금액";
      case "customsDuty":
        return "세관 지불금액";
      case "inland":
        return "내륙운송사 지불금액";
      case "wareHouse":
        return "창고 지불금액";
      case "shipping":
        return "선사 지불금액";
      case "etc":
        return "기타1 지불금액";
      case "etc2":
        return "기타2 지불금액";
      default:
        return "-";
    }
  }, []);

  const isForeign = useMemo(() => {
    return companyType === "foreign" ? true : false;
  }, [companyType]);

  const handleWithdrawalRequestClick = useCallback(
    (requestWithdrawalFlag: boolean) => {
      const detailList = withdrawalData.map((v: WithdrawalRequestDetail) => {
        return {
          name: v.name,
          currency: v.currency,
          unitPrice: v.unitPrice,
          itemUnitMeasurement: v.itemUnitMeasurement,
          amount: v.amount,
          totalPrice: v.totalPrice,
          vatPrice: v.vatPrice,
          finalPrice: v.finalPrice,
          note: v.note,
          isVAT: v.isVAT,
        };
      });

      //여러개인 각 항목들의 가격들을 합친다.
      let totalPrice = 0;
      let finalPrice = 0;
      let vatPrice = 0;
      let foreignFinalPrice = 0;

      withdrawalData.forEach((v: WithdrawalRequestDetail) => {
        totalPrice += v.totalPrice;
        finalPrice += v.finalPrice;
        vatPrice += v.vatPrice;
        if (v.currency !== "KRW") {
          foreignFinalPrice += v.unitPrice * v.amount;
        }
      });

      requestWithdrawal(
        {
          detailList,
          bidId,
          companyType,
          requestWithdrawalFlag,
          foreignFinalPrice: isOnlyKrCurrency
            ? 0
            : Number(foreignFinalPrice.toFixed(2)),
          currency: isOnlyKrCurrency ? "KRW" : currency,
          exchangeRate: isOnlyKrCurrency
            ? 0
            : getWithdrawalExchangeRate({
                isNewCurrency,
                inputCurrency,
                isInputCurrency,
                saveExchangeRate,
                exchangeRate,
              }),

          totalPrice: isForeign
            ? Number(finalPrice.toFixed(0))
            : Number(totalPrice.toFixed(0)),
          vatPrice: isForeign ? 0 : Number(vatPrice.toFixed(0)),
          finalPrice: Number(finalPrice.toFixed(0)),
          companyId: companyId,
          bidAccountPayableId,
          withdrawalInvoiceId,
          ...(withdrawalInvoiceDate && { withdrawalInvoiceDate }),
        },
        {
          onSuccess: () => {
            message.success(
              requestWithdrawalFlag
                ? "출금을 요청했습니다."
                : "출금을 저장했습니다."
            );
            setWithdrawalInvoiceId("");
            setWithdrawalInvoiceDate(null);

            queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.all());
          },

          onError: ({ response }) => {
            const failureInfo =
              response?.data as unknown as ResponseFailureInfo;
            if (failureInfo.errorCode === "E074") {
              message.error("인보이스를 업데이트 해주세요.");
              return;
            }

            if (failureInfo.errorCode === "E075") {
              message.error("이미 요청한 상태입니다.");
              return;
            }
            message.error("에러가 발생했습니다 개발자에게 문의해주세요.");
            return;
          },
        }
      );
    },
    [
      bidAccountPayableId,
      bidId,
      companyId,
      companyType,
      currency,
      exchangeRate,
      inputCurrency,
      isForeign,
      isInputCurrency,
      isNewCurrency,
      isOnlyKrCurrency,
      queryClient,
      requestWithdrawal,
      saveExchangeRate,
      withdrawalData,
      withdrawalInvoiceDate,
      withdrawalInvoiceId,
    ]
  );

  return (
    <Modal
      destroyOnClose={true}
      width={1350}
      title={getPartnerNameKr(companyType)}
      visible={showsPurchaseInvoiceRequestModal}
      onCancel={() => {
        setIsInputCurrency(false);
        setIsLoadedWithdrawalTemplateData(false);
        setShowsPurchaseInvoiceRequestModal(false);
      }}
      footer={null}
    >
      <Styled.container>
        <DatePicker
          label={"INV. Date"}
          when="end"
          value={withdrawalInvoiceDate}
          setDate={setWithdrawalInvoiceDate}
        />

        <TextField
          sx={{ width: 280 }}
          label={"INV No."}
          value={withdrawalInvoiceId}
          onChange={(e) => {
            setWithdrawalInvoiceId(e.target.value);
          }}
        />

        <TemplateSearch
          bidId={bidId}
          templateCategory={changePurchaseDomainToTemplateCategory(companyType)}
          reset={reset}
          watch={watch}
          setValue={setValue}
          feeDataType={"withdrawalData"}
          setShowsErrorSnackBar={setShowsErrorSnackBar}
          setShowsSuccessSnackBar={setShowsSuccessSnackBar}
          templateType="withdrawal"
          bidAccountPayableId={bidAccountPayableId}
          exchangeRateList={exchangeRateList}
          changeWithdrawalCurrencyAfterTemplateUpdate={
            changeWithdrawalCurrencyAfterTemplateUpdate
          }
        />

        <CurrencyFilter
          currency={currency}
          exchangeRateList={exchangeRateList}
          setCurrency={setCurrency}
          changeCurrency={changeCurrency}
          handleWithdrawalUpdateCurrencyClick={
            handleWithdrawalUpdateCurrencyClick
          }
          exchangeRate={exchangeRate}
          saveExchangeRate={saveExchangeRate}
          isNewCurrency={isNewCurrency}
          setIsInputCurrency={setIsInputCurrency}
          inputCurrency={inputCurrency}
          setInputCurrency={setInputCurrency}
          isInputCurrency={isInputCurrency}
          handleWithdrawalCurrencyClick={handleWithdrawalCurrencyClick}
        />

        {withdrawalData && (
          <PurchaseRequestForm
            withdrawalData={withdrawalData}
            control={control}
            errors={errors}
            currency={currency}
            companyType={companyType}
            bidId={bidId}
            setValue={setValue}
            isNewCurrency={isNewCurrency}
            saveExchangeRate={saveExchangeRate}
            exchangeRateList={exchangeRateList}
            reset={reset}
            withdrawalItemList={withdrawalItemList}
            trelloDetailData={trelloDetailData}
          />
        )}

        <div className="button-container">
          <Button
            variant="outlined"
            disabled={isSave || isLoading}
            size="large"
            sx={{ mr: 1 }}
            onClick={() => handleWithdrawalRequestClick(false)}
          >
            {isLoading ? <CircularProgress size={25} /> : "저장"}
          </Button>

          {isSave ? (
            <Button
              variant="outlined"
              color="error"
              disabled={currentUser?.authority === "finance"}
              onClick={() => setShowCancelWithdrawModal(true)}
              size="large"
            >
              취소 요청
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              disabled={isLoading}
              onClick={() => handleWithdrawalRequestClick(true)}
              size="large"
            >
              {isLoading ? <CircularProgress size={25} /> : " 저장&출금요청"}
            </Button>
          )}
        </div>

        {showCancelWithdrawModal && (
          <CancelWithdrawModal
            bidId={bidId}
            partnerCompanyId={companyId}
            setShowCancelWithdrawModal={setShowCancelWithdrawModal}
            showCancelWithdrawModal={showCancelWithdrawModal}
          />
        )}
      </Styled.container>
    </Modal>
  );
};

export default PurchaseRequestModal;
