import { useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";
import { useRecoilValue } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_AUTH_SELECTORS } from "@sellernote/_shared/src/states/forwarding/auth";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  checkIfHaveAuthorityToChangeTrelloDetail,
  returnEndAddress,
} from "@sellernote/_shared/src/utils/forwarding/trello";

import ZoneAddressTextFiled from "../../../../../../../containers/ZoneAddressTextField";

function EndInland({
  trelloDetail,
  bidAccountPayableId,
}: {
  trelloDetail: TrelloBidDetail;
  bidAccountPayableId: number | undefined;
}) {
  const history = useHistory();

  const currentUser = useRecoilValue(FORWARDING_AUTH_SELECTORS.CURRENT_MANAGER);

  const queryClient = useQueryClient();

  const [zoneName, setZoneName] = useState("");
  const [zoneId, setZoneId] = useState(0);
  const [endAddressDetail, setEndAddressDetail] = useState("");
  const [inlandInputChange, setInlandInputChange] = useState(false);

  const {
    mutate: changeInland,
    ResponseHandler: ResponseHandlerOfChangeInland,
  } = TRELLO_BID_QUERY.useChangeInland(trelloDetail.id, {
    handleConfirmSuccess: (initQuery) => {
      initQuery();
      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({
          bidId: trelloDetail.id,
        })
      );
      history.push(`/tradingStatement/invoice/${trelloDetail.id}`);
    },

    customizeMessage: () => ({
      title: "내륙운송을 수정했습니다.",
    }),
  });

  const {
    mutate: cancelInlandTransport,
    ResponseHandler: ResponseHandlerOfUseCancelInlandTransport,
  } = TRELLO_BID_QUERY.useCancelInlandTransport(trelloDetail.id, {
    handleConfirmSuccess: (initQuery) => {
      initQuery();
      setInlandInputChange(false);
      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({
          bidId: trelloDetail.id,
        })
      );
    },

    customizeMessage: () => ({
      title: "내륙 운송을 취소했습니다.",
    }),
  });

  const {
    mutate: editInlandType,
    ResponseHandler: ResponseHandlerOfEditInlandType,
  } = TRELLO_BID_QUERY.useEditInlandType({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setInlandInputChange(false);
        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({
            bidId: trelloDetail.id,
          })
        );
      },

      customizeMessage: () => ({
        title: "내륙운송을 수정했습니다.",
      }),
    },
    bidId: trelloDetail.id,
  });

  const handleInlandTypeChangeAndMoveToTradingStatement = () => {
    changeInland({
      bidAccountPayableId,
      inlandFlag: true,
      zoneId: !zoneId ? trelloDetail.zoneId : zoneId,
      endAddress: !zoneName ? endAddress : zoneName,
      endAddressDetail: !endAddressDetail
        ? trelloDetail.endAddressDetail
        : endAddressDetail,
    });
  };

  const handleInlandTransportChange = () => {
    // 내륙주소, 상세 주소가 없이 요청했을 때는 내륙운송을 취소 요청을한다.
    if (!zoneName && !endAddressDetail && bidAccountPayableId) {
      cancelInlandTransport({
        bidAccountPayableId,
        inlandFlag: false,
      });

      return;
    }

    editInlandType({
      bidAccountPayableId,
      detailFlag: false,
      zoneId: !zoneId ? trelloDetail.zoneId : zoneId,
      endAddress: !zoneName ? endAddress : zoneName,
      endAddressDetail: addressDetailForInlandTypeChangeRequest,
    });
  };

  const endAddress = trelloDetail.endAddressDetail
    ? trelloDetail.endAddress
    : trelloDetail.zone?.name || "";

  const addressDetailForInlandTypeChangeRequest = !endAddressDetail
    ? trelloDetail.endAddressDetail
    : endAddressDetail;

  const cancelRegisterInlandUpdate = () => {
    setInlandInputChange(false);
    setZoneId(0);
    setEndAddressDetail("");
  };

  const getDisabledInlandUpdateButton = () => {
    if (trelloDetail.bidInvoice) {
      if (trelloDetail.bidInvoice.issuedInvoices.length > 0) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <Box>
      {inlandInputChange ? (
        <Box>
          <ZoneAddressTextFiled
            zoneName={endAddress}
            pageType="trello"
            setTrelloZoneNameState={setZoneName}
            setTrelloZoneIdState={setZoneId}
          />

          <TextField
            fullWidth
            onChange={(e) => {
              setEndAddressDetail(e.target.value);
            }}
            defaultValue={
              trelloDetail.serviceType === "consolidation" &&
              !trelloDetail.endAddressDetail
                ? trelloDetail.endAddress
                : trelloDetail.endAddressDetail
            }
          />

          {!trelloDetail.endAddress && trelloDetail.endAddress !== "" ? (
            <Button
              disabled={checkIfHaveAuthorityToChangeTrelloDetail(
                currentUser?.authority,
                trelloDetail.projectStatus
              )}
              onClick={handleInlandTypeChangeAndMoveToTradingStatement}
            >
              확인
            </Button>
          ) : (
            <Button
              disabled={checkIfHaveAuthorityToChangeTrelloDetail(
                currentUser?.authority,
                trelloDetail.projectStatus
              )}
              onClick={handleInlandTransportChange}
            >
              확인
            </Button>
          )}

          <Button
            color="error"
            disabled={checkIfHaveAuthorityToChangeTrelloDetail(
              currentUser?.authority,
              trelloDetail.projectStatus
            )}
            onClick={() => cancelRegisterInlandUpdate()}
          >
            취소
          </Button>
        </Box>
      ) : (
        returnEndAddress(trelloDetail)
      )}

      {!inlandInputChange &&
        !trelloDetail.endAddress &&
        APP_NAME === "shipda-admin" && (
          <Button
            disabled={
              checkIfHaveAuthorityToChangeTrelloDetail(
                currentUser?.authority,
                trelloDetail.projectStatus
              ) || getDisabledInlandUpdateButton()
            }
            onClick={() => setInlandInputChange(true)}
          >
            등록
          </Button>
        )}

      {!inlandInputChange &&
        trelloDetail.endAddress &&
        APP_NAME === "shipda-admin" && (
          <Button
            disabled={
              checkIfHaveAuthorityToChangeTrelloDetail(
                currentUser?.authority,
                trelloDetail.projectStatus
              ) || getDisabledInlandUpdateButton()
            }
            onClick={() => setInlandInputChange(true)}
          >
            수정
          </Button>
        )}

      {ResponseHandlerOfEditInlandType}

      {ResponseHandlerOfChangeInland}

      {ResponseHandlerOfUseCancelInlandTransport}
    </Box>
  );
}

export default EndInland;
