import {
  DatePicker as MUIDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import {
  toFormattedDate,
  transformUTCDateToLocalDateTime,
} from "@sellernote/_shared/src/utils/common/date";

import HighlightTextField from "../HighlightTextField";

function DatePicker({
  disabled,
  value,
  setDate,
  when,
  label,
  width,
  fullWidth,
  showsHighlightsText,
  isNotISOString,
  isDisablePast,
}: {
  disabled?: boolean;
  value: string | null;
  setDate: (v: string | null) => void;
  when: "start" | "end";
  label?: string;
  width?: number;
  fullWidth?: boolean;
  showsHighlightsText?: boolean;
  isNotISOString?: boolean;
  isDisablePast?: boolean;
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUIDatePicker
        disablePast={isDisablePast}
        disabled={disabled}
        componentsProps={{
          actionBar: { actions: ["clear"] },
        }}
        inputFormat="yyyy-MM-dd"
        value={value}
        label={label || "날짜를 입력해주세요."}
        onChange={(v) => {
          if (!v) {
            setDate(v);
            return;
          }

          if (isNotISOString) {
            setDate(toFormattedDate(v, "YYYY-MM-DD"));
            return;
          }

          setDate(
            transformUTCDateToLocalDateTime({
              utcDateTime: v,
              timeZone: "Asia/Seoul",
              when,
            })?.toISOString() || ""
          );
        }}
        renderInput={(params) => (
          <HighlightTextField
            {...params}
            onKeyDown={(event) => {
              event.preventDefault();
            }}
            sx={{ width: fullWidth ? "max-width" : width || 200 }}
            size="small"
            showshighlightstext={showsHighlightsText}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
