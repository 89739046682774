import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Descriptions,
  Input,
  message,
  Modal,
  Select,
  Switch,
} from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/lib/select";
import { useRecoilValue } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { FORWARDING_AUTH_SELECTORS } from "@sellernote/_shared/src/states/forwarding/auth";
import {
  Country,
  Port,
  Zone,
} from "@sellernote/_shared/src/types/common/common";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { CountryCode } from "@sellernote/_shared/src/utils/common/country";
import { getCustomsPartnerName } from "@sellernote/_shared/src/utils/forwarding/adminBid";
import { getAdminBidPortName } from "@sellernote/_shared/src/utils/forwarding/bid";
import { changeBusinessManagementExcelDataServiceTypeToNameKr } from "@sellernote/_shared/src/utils/forwarding/businessManagement";
import {
  changeInlandTypeToNameKr,
  changeTransPortTypeToKr,
} from "@sellernote/_shared/src/utils/forwarding/trello";

import ZoneAddressTextFiled from "../../../../../containers/ZoneAddressTextField";

const { Option } = Select;

const { confirm } = Modal;

const TransportationInfo = ({
  bidDetail,
  portData,
  zoneData,
  countryList,
}: {
  bidDetail: AdminBidDetail;
  portData: Port[];
  zoneData: Zone[];
  countryList: Country[];
}) => {
  const currentUser = useRecoilValue(FORWARDING_AUTH_SELECTORS.CURRENT_MANAGER);

  const queryClient = useQueryClient();

  const [changeBid, setChangeBid] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [incoterms, setIncoterms] = useState(false);
  const [startType, setStartType] = useState(bidDetail.startType);
  const [endType, setEndType] = useState(bidDetail.endType);

  const {
    mutate: changeBidDetail,
    ResponseHandler: ResponseHandlerOfChangeBidDetail,
  } = ADMIN_BID_QUERY.useChangeBidDetail(bidDetail.id);

  const { mutate: checkPriceReset } = ADMIN_BID_QUERY.useCheckPriceReset(
    bidDetail.id
  );

  const handleTransportationInfoChangeClick = useCallback(() => {
    confirm({
      title: "가격 재계산 혹은 리셋이 될 가능성이 있습니다.",
      icon: <ExclamationCircleOutlined />,
      content: "수정하시겠습니까??",
      onOk() {
        checkPriceReset(updateData, {
          onSuccess: () => {
            changeBidDetail(updateData, {
              onSuccess: () => {
                message.success("의뢰 정보를 변경했습니다.");
                setIncoterms(false);
                setChangeBid(false);
                setUpdateData({});
                queryClient.invalidateQueries(
                  ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                    bidId: bidDetail.id,
                  })
                );
              },

              onError: () => {
                message.error("에러가 있습니다. 개발자에게 문의해주세요.");
              },
            });
          },

          onError: () => {
            message.error("에러가 있습니다. 개발자에게 문의해주세요.");
          },
        });
      },
    });
  }, [bidDetail.id, changeBidDetail, checkPriceReset, queryClient, updateData]);

  const checkDisabledAuthority = () => {
    if (bidDetail.projectStatus === "settlementComplete") {
      if (currentUser?.authority !== "master") {
        return true;
      }
      return false;
    }
    return false;
  };

  const portNameListForAutoCompleteOptions = useMemo(
    () =>
      portData.map((v) => {
        return { value: v.nameEN };
      }),
    [portData]
  );

  const countryNameListForAutoCompleteOptions = useMemo(
    () =>
      countryList.map((v) => {
        return { value: v.nameKR };
      }),
    [countryList]
  );

  const endAddress = useMemo(() => {
    if (bidDetail.endAddressDetail) {
      return bidDetail.endAddress;
    }
    return bidDetail.zone?.name || "";
  }, [bidDetail.endAddress, bidDetail.endAddressDetail, bidDetail.zone?.name]);

  const getCountryName = useCallback(
    (countryName: string) => {
      return (
        countryList.find((v) => {
          return v.nameKR === countryName;
        })?.name || ""
      );
    },
    [countryList]
  );

  const changePortNameToId = useCallback(
    (portName: string) => {
      return (
        portData.find((v) => {
          return v.nameEN === portName;
        })?.id || null
      );
    },
    [portData]
  );

  const changeCheckpointValueToKr = useCallback((value: boolean | null) => {
    switch (value) {
      case true:
        return "포함";
      case false:
        return "미포함";
      default:
        return "-";
    }
  }, []);

  const changeContainerAccessableValueToKr = useCallback(
    (value: boolean | null) => {
      switch (value) {
        case true:
          return "가능";
        case false:
          return "불가능";
        default:
          return "-";
      }
    },
    []
  );

  const StartingPointDescriptionsItem = useMemo(() => {
    if (changeBid) {
      if (startType === "sea" || startType === "air") {
        return (
          <Descriptions.Item
            contentStyle={{ marginTop: -5 }}
            label="출발지 항구/공항 :"
            span={2}
          >
            <AutoComplete
              allowClear
              style={{ width: "100%", maxWidth: 237 }}
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  startPortId: changePortNameToId(e),
                  startAddress: null,
                  startViaPortId: null,
                });
              }}
              defaultValue={getAdminBidPortName(bidDetail, "startPort")}
              options={portNameListForAutoCompleteOptions}
              filterOption={(
                inputValue: string,
                option: DefaultOptionType | BaseOptionType | undefined
              ) =>
                !!option?.value.toUpperCase().includes(inputValue.toUpperCase())
              }
            />
          </Descriptions.Item>
        );
      }
      if (startType === "inland") {
        return (
          <>
            <Descriptions.Item
              contentStyle={{ marginTop: -5 }}
              label="출발지 내륙 :"
            >
              <Input
                style={{ maxWidth: 266 }}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    startAddress: e.target.value,
                    startPortId: null,
                  });
                }}
                defaultValue={bidDetail.startAddress}
              ></Input>
            </Descriptions.Item>

            <Descriptions.Item
              contentStyle={{ marginTop: -5 }}
              label="출발지 경유지 :"
            >
              <AutoComplete
                allowClear
                style={{ width: "100%", maxWidth: 254 }}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    startViaPortId: changePortNameToId(e),
                  });
                }}
                defaultValue={bidDetail.startViaPort?.nameEN}
                options={portNameListForAutoCompleteOptions}
                filterOption={(
                  inputValue: string,
                  option: DefaultOptionType | BaseOptionType | undefined
                ) =>
                  !!option?.value
                    .toUpperCase()
                    .includes(inputValue.toUpperCase())
                }
              />
            </Descriptions.Item>
          </>
        );
      }
    }
    if (startType === "sea" || startType === "air") {
      return (
        <Descriptions.Item label="출발지 항구/공항 :" span={2}>
          {getAdminBidPortName(bidDetail, "startPort")}
        </Descriptions.Item>
      );
    }
    return (
      <>
        <Descriptions.Item label="출발지 내륙 :">
          {getAdminBidPortName(bidDetail, "startPort")}
        </Descriptions.Item>

        <Descriptions.Item label="출발지 경유지 :">
          {bidDetail.startViaPort?.nameEN}
        </Descriptions.Item>
      </>
    );
  }, [
    bidDetail,
    changeBid,
    changePortNameToId,
    portNameListForAutoCompleteOptions,
    startType,
    updateData,
  ]);

  const DestinationDescription = useMemo(() => {
    if (changeBid) {
      if (endType === "sea" || endType === "air") {
        return (
          <Descriptions.Item
            contentStyle={{ marginTop: -5 }}
            label="도착지 항구/공항 :"
            span={2}
          >
            <AutoComplete
              allowClear
              style={{ width: "100%", maxWidth: 237 }}
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  endPortId: changePortNameToId(e),
                  endAddress: null,
                  endViaPortId: null,
                });
              }}
              defaultValue={getAdminBidPortName(bidDetail, "endPort")}
              options={portNameListForAutoCompleteOptions}
              filterOption={(
                inputValue: string,
                option: DefaultOptionType | BaseOptionType | undefined
              ) =>
                !!option?.value.toUpperCase().includes(inputValue.toUpperCase())
              }
            />
          </Descriptions.Item>
        );
      }
      if (endType === "inland") {
        return (
          <>
            <Descriptions.Item
              contentStyle={{ marginTop: -5 }}
              label="도착지 내륙 :"
            >
              <ZoneAddressTextFiled
                zoneName={endAddress}
                pageType="bid"
                setBidUpdateState={setUpdateData}
                updateData={updateData}
              />
            </Descriptions.Item>

            <Descriptions.Item
              contentStyle={{ marginTop: -5 }}
              label="도착지 상세주소 :"
            >
              <Input
                allowClear
                style={{ width: "100%", maxWidth: 266 }}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    endAddressDetail: e.target.value,
                  });
                }}
                defaultValue={bidDetail.endAddressDetail}
              />
            </Descriptions.Item>

            <Descriptions.Item
              contentStyle={{ marginTop: -5 }}
              label="도착지 경유지 :"
            >
              <AutoComplete
                allowClear
                style={{ width: "100%", maxWidth: 254 }}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    endViaPortId: changePortNameToId(e),
                  });
                }}
                defaultValue={bidDetail.endViaPort?.nameEN}
                options={portNameListForAutoCompleteOptions}
                filterOption={(
                  inputValue: string,
                  option: DefaultOptionType | BaseOptionType | undefined
                ) =>
                  !!option?.value
                    .toUpperCase()
                    .includes(inputValue.toUpperCase())
                }
              />
            </Descriptions.Item>

            <Descriptions.Item span={2} label="zoneId :">
              {bidDetail.zoneId || "없음"}
            </Descriptions.Item>
          </>
        );
      }
    }
    if (endType === "sea" || endType === "air") {
      return (
        <Descriptions.Item label="도착지 항구/공항 :" span={2}>
          {getAdminBidPortName(bidDetail, "endPort")}
        </Descriptions.Item>
      );
    }
    return (
      <>
        <Descriptions.Item label="도착지 주소 :">
          {getAdminBidPortName(bidDetail, "endPort")}
        </Descriptions.Item>

        <Descriptions.Item label="도착지 상세주소 :">
          {bidDetail.endAddressDetail}
        </Descriptions.Item>

        <Descriptions.Item label="도착 경유지 항구">
          {bidDetail.endViaPort?.nameEN}
        </Descriptions.Item>

        <Descriptions.Item span={2} label="zoneId">
          {bidDetail.zoneId || "없음"}
        </Descriptions.Item>
      </>
    );
  }, [
    changeBid,
    endType,
    bidDetail,
    portNameListForAutoCompleteOptions,
    updateData,
    changePortNameToId,
    endAddress,
  ]);

  const EditButton = useMemo(() => {
    if (APP_NAME === "shipda-admin") {
      return changeBid ? (
        <>
          <Button
            onClick={handleTransportationInfoChangeClick}
            type="primary"
            style={{
              width: 154,
              backgroundColor: "rgba(24, 144, 255, 0.1)",
              color: "#1890FF",
              fontWeight: "bold",
            }}
          >
            완료
          </Button>

          <Button
            danger
            onClick={() => {
              setChangeBid(false);
              setUpdateData({});
            }}
            style={{
              width: 154,
              fontWeight: "bold",
            }}
          >
            취소
          </Button>
        </>
      ) : (
        <Button
          style={{
            width: 154,
            backgroundColor: "#1890FF",
            fontWeight: "bold",
          }}
          type="primary"
          onClick={() => {
            if (checkDisabledAuthority()) {
              message.warning("정산완료에서는 수정할 수 없습니다.");
            } else {
              setChangeBid(true);
            }
          }}
        >
          수정
        </Button>
      );
    }
    return;
  }, [changeBid, checkDisabledAuthority, handleTransportationInfoChangeClick]);

  return (
    <div>
      <Descriptions
        extra={EditButton}
        key="containerAccessableFalse"
        title={<div style={{ fontWeight: "bold", fontSize: 20 }}>운송정보</div>}
        colon={false}
        contentStyle={{ fontWeight: "bold" }}
      >
        <Descriptions.Item
          label={
            <div style={{ color: "#1890FF", fontWeight: "bold", fontSize: 16 }}>
              ‣ 운영
            </div>
          }
          span={3}
        >
          {}
        </Descriptions.Item>

        <Descriptions.Item label="유형 :" span={3}>
          {changeBusinessManagementExcelDataServiceTypeToNameKr(
            bidDetail.serviceType,
            bidDetail.isExpress
          )}
        </Descriptions.Item>

        <Descriptions.Item label="국가 :">
          {changeBid ? (
            <AutoComplete
              allowClear
              style={{
                width: "100%",
                maxWidth: 306,
                fontWeight: "normal",
                marginTop: -5,
              }}
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  startCountry: getCountryName(e),
                });
              }}
              defaultValue={CountryCode(bidDetail.startCountry)}
              options={countryNameListForAutoCompleteOptions}
              filterOption={(
                inputValue: string,
                option: DefaultOptionType | BaseOptionType | undefined
              ) =>
                !!option?.value.toUpperCase().includes(inputValue.toUpperCase())
              }
            />
          ) : (
            CountryCode(bidDetail.startCountry)
          )}
        </Descriptions.Item>

        <Descriptions.Item label="인코텀즈 :">
          {changeBid ? (
            <Select
              style={{
                width: "100%",
                maxWidth: 281,
                fontWeight: "normal",
                marginTop: -5,
              }}
              defaultValue={bidDetail.incoterms}
              onChange={(e) => {
                setIncoterms(true);
                setUpdateData({
                  ...updateData,
                  incoterms: e === bidDetail.incoterms ? undefined : e,
                });
              }}
            >
              <Option value="FOB">FOB</Option>
              <Option value="EXW">EXW</Option>
              <Option value="FCA">FCA</Option>
              <Option value="CIF">CIF</Option>
              <Option value="CFR">CFR</Option>
            </Select>
          ) : (
            bidDetail.incoterms
          )}
        </Descriptions.Item>

        <Descriptions.Item>{}</Descriptions.Item>

        <Descriptions.Item label="출발지 유형 :">
          {incoterms ? (
            <Select
              style={{
                width: "100%",
                maxWidth: 266,
                marginTop: -5,
                fontWeight: "normal",
              }}
              defaultValue={bidDetail.startType}
              onChange={(e) => {
                setUpdateData({ ...updateData, startType: e });
                setStartType(e);
              }}
            >
              <Option value="sea">해상</Option>
              <Option value="air">항공</Option>
              <Option value="inland">내륙</Option>
            </Select>
          ) : (
            changeTransPortTypeToKr(bidDetail.startType)
          )}
        </Descriptions.Item>

        {StartingPointDescriptionsItem}

        <Descriptions.Item label="도착지 유형 :">
          {incoterms ? (
            <Select
              style={{
                width: "100%",
                maxWidth: 266,
                marginTop: -5,
                fontWeight: "normal",
              }}
              defaultValue={bidDetail.endType}
              onChange={(e) => {
                setUpdateData({ ...updateData, endType: e });
                setEndType(e);
              }}
            >
              <Option value="sea">해상</Option>
              <Option value="air">공항</Option>
              <Option value="inland">내륙</Option>
            </Select>
          ) : (
            changeTransPortTypeToKr(bidDetail.endType)
          )}
        </Descriptions.Item>

        {DestinationDescription}

        <Descriptions.Item
          label={
            <div style={{ color: "#1890FF", fontWeight: "bold", fontSize: 16 }}>
              ‣ 체크포인트
            </div>
          }
          span={3}
        >
          {}
        </Descriptions.Item>

        <Descriptions.Item label="국내부대비용 포함 :">
          {changeBid ? (
            <Switch
              defaultChecked={bidDetail.containDomesticFee}
              onChange={(checked) => {
                setUpdateData({ ...updateData, containDomesticFee: checked });
              }}
            ></Switch>
          ) : (
            changeCheckpointValueToKr(bidDetail.containDomesticFee)
          )}
        </Descriptions.Item>

        {bidDetail.freightType !== "AIR" && (
          <Descriptions.Item label="OCEAN SURCHARGE 포함:">
            {changeBid ? (
              <Switch
                defaultChecked={bidDetail.containOceanSurcharge}
                onChange={(checked) => {
                  setUpdateData({
                    ...updateData,
                    containOceanSurcharge: checked,
                  });
                }}
              ></Switch>
            ) : (
              changeCheckpointValueToKr(bidDetail.containOceanSurcharge)
            )}
          </Descriptions.Item>
        )}

        <Descriptions.Item label="LSS :">
          {changeBid ? (
            <Switch
              defaultChecked={bidDetail.containLss}
              onChange={(checked) => {
                setUpdateData({ ...updateData, containLss: checked });
              }}
            ></Switch>
          ) : (
            changeCheckpointValueToKr(bidDetail.containLss)
          )}
        </Descriptions.Item>

        <Descriptions.Item label="적하보험 :">
          {changeBid ? (
            <Switch
              defaultChecked={bidDetail.hopeCargoInsurance}
              onChange={(checked) => {
                setUpdateData({ ...updateData, hopeCargoInsurance: checked });
              }}
            ></Switch>
          ) : (
            changeCheckpointValueToKr(bidDetail.hopeCargoInsurance)
          )}
        </Descriptions.Item>

        <Descriptions.Item label="통관의뢰 :">
          {changeBid ? (
            <Switch
              defaultChecked={bidDetail.containCustoms}
              onChange={(checked) => {
                setUpdateData({ ...updateData, containCustoms: checked });
              }}
            ></Switch>
          ) : (
            `${
              bidDetail.containCustoms
                ? changeCheckpointValueToKr(bidDetail.containCustoms)
                : `미포함 (${getCustomsPartnerName(bidDetail.accountPayables)})`
            }`
          )}
        </Descriptions.Item>

        {bidDetail.freightType === "FCL" && (
          <Descriptions.Item label="도착지 컨테이너 진입여부 :">
            {changeBid ? (
              <Switch
                defaultChecked={bidDetail.containerAccessable}
                onChange={(checked) => {
                  setUpdateData({
                    ...updateData,
                    containerAccessable: checked,
                  });
                }}
              ></Switch>
            ) : (
              changeContainerAccessableValueToKr(bidDetail.containerAccessable)
            )}
          </Descriptions.Item>
        )}

        {bidDetail.locale === "SG" && (
          <Descriptions.Item label="IOR :">
            {changeBid ? (
              <Switch
                defaultChecked={bidDetail.needIORAgency}
                onChange={(checked) => {
                  setUpdateData({ ...updateData, needIORAgency: checked });
                }}
              ></Switch>
            ) : (
              changeCheckpointValueToKr(bidDetail.needIORAgency)
            )}
          </Descriptions.Item>
        )}

        <Descriptions.Item label="FTA C/O :">
          {changeBid ? (
            <Switch
              defaultChecked={bidDetail.needFTACertificateAgency}
              onChange={(checked) => {
                setUpdateData({
                  ...updateData,
                  needFTACertificateAgency: checked,
                });
              }}
            ></Switch>
          ) : (
            changeCheckpointValueToKr(bidDetail.needFTACertificateAgency)
          )}
        </Descriptions.Item>

        {bidDetail.freightType !== "FCL" && (
          <Descriptions.Item label="합차/독차 :">
            {changeInlandTypeToNameKr(bidDetail.inlandType)}
          </Descriptions.Item>
        )}

        <Descriptions.Item span={3} label="유저 코멘트 :">
          {bidDetail.userNote}
        </Descriptions.Item>
      </Descriptions>

      {ResponseHandlerOfChangeBidDetail}
    </div>
  );
};

export default TransportationInfo;
