import { selector } from "recoil";

import { ForwardingAdminUserAuthority } from "../../../types/forwarding/adminUser";

import { PARTNER_ADMIN_USER_INFO } from "../../../api-interfaces/shipda-api/auth";
import { FORWARDING_AUTH_ATOMS } from ".";

const CURRENT_MANAGER = selector<
  | { id: number; authority: ForwardingAdminUserAuthority; accountId: string; }
  | undefined
>({
  key: "forwarding/auth/selectors/CURRENT_MANAGER",
  get: ({ get }) => {
    const loggedIn = get(FORWARDING_AUTH_ATOMS.ADMIN_LOGGED_IN);

    if (!loggedIn) return undefined;

    if (typeof window === "undefined") return undefined;

    const data = window.localStorage.getItem("currentUser");

    if (!data) return undefined;

    return JSON.parse(data);
  },
});

const CURRENT_PARTNER = selector<
  | Pick<
      PARTNER_ADMIN_USER_INFO,
      "company" | "id" | "name" | "partnerCompanyId"
    >
  | undefined
>({
  key: "forwarding/auth/selectors/CURRENT_PARTNER",
  get: ({ get }) => {
    const loggedIn = get(FORWARDING_AUTH_ATOMS.PARTNER_ADMIN_LOGGED_IN);

    if (!loggedIn) return undefined;

    if (typeof window === "undefined") return undefined;

    const data = window.localStorage.getItem("currentUser");

    if (!data) return undefined;

    return JSON.parse(data);
  },
});

export default { CURRENT_MANAGER, CURRENT_PARTNER };
