import { Country, FreightType, Port } from "../../types/common/common";
import { Bid, BidProjectStatus } from "../../types/forwarding/bid";
import {
  InvoiceData,
  InvoiceDataFeeItem,
  TradingStatementElem,
  TradingStatementInvoice,
} from "../../types/forwarding/tradingStatement";
import {
  ExchangeRate,
  InvoiceType,
  TrelloBidDetail,
  TrelloBidManagement,
  WithdrawalRequestDetail,
} from "../../types/forwarding/trello";

import {
  FARECON_DOMESTIC_PARTNER_ID,
  FARECON_FOREIGN_PARTNER_ID,
  GLOBELINK_KOREA_PARTNER_ID,
  GLOBELINK_SHANGHAI_EXPRESS_PARTNER_ID,
  GLOBELINK_SHANGHAI_FCL_PARTNER_ID,
  GLOBELINKSHA_PARTNER_ID,
} from "./partner";

const getTotalItemPriceOfFeeData = (feeData: TradingStatementElem[]) => {
  const totalPriceOfFeeData = feeData.reduce((accumulator, currentValue) => {
    if (currentValue.itemPrice && !currentValue.isTaxExempt) {
      return accumulator + Number(currentValue.itemPrice);
    }
    return accumulator;
  }, 0);

  return totalPriceOfFeeData;
};

export const getAllTotalItemPriceOfFeeData = (
  freight: TradingStatementElem[],
  domestic: TradingStatementElem[],
  local: TradingStatementElem[],
  inland: TradingStatementElem[],
  other: TradingStatementElem[],
  tax: TradingStatementElem[]
) => {
  let totalItemPriceOfFreightFeeData = 0;
  let totalItemPriceOfDomesticFeeData = 0;
  let totalItemPriceOfLocalFeeData = 0;
  let totalItemPriceOfInlandFeeData = 0;
  let totalItemPriceOfOtherFeeData = 0;
  let totalItemPriceOfTaxFeeData = 0;

  if (freight) {
    totalItemPriceOfFreightFeeData = getTotalItemPriceOfFeeData(freight);
  }
  if (domestic) {
    totalItemPriceOfDomesticFeeData = getTotalItemPriceOfFeeData(domestic);
  }
  if (local) {
    totalItemPriceOfLocalFeeData = getTotalItemPriceOfFeeData(local);
  }
  if (inland) {
    totalItemPriceOfInlandFeeData = getTotalItemPriceOfFeeData(inland);
  }
  if (other) {
    totalItemPriceOfOtherFeeData = getTotalItemPriceOfFeeData(other);
  }
  if (tax) {
    totalItemPriceOfTaxFeeData = getTotalItemPriceOfFeeData(tax);
  }

  if (totalItemPriceOfFreightFeeData < 0) {
    totalItemPriceOfFreightFeeData = 0;
  }
  return (
    totalItemPriceOfLocalFeeData +
    totalItemPriceOfDomesticFeeData +
    totalItemPriceOfFreightFeeData +
    totalItemPriceOfInlandFeeData +
    totalItemPriceOfOtherFeeData +
    totalItemPriceOfTaxFeeData
  );
};

const getTotalVatPriceOfFeeData = (feeData: TradingStatementElem[]) => {
  const totalVatPriceOfFeeData = feeData.reduce((accumulator, currentValue) => {
    if (currentValue.itemPrice && currentValue.isVAT) {
      return accumulator + Math.floor(currentValue.itemPrice * 0.1);
    }
    return accumulator;
  }, 0);

  return totalVatPriceOfFeeData;
};

/**
 * 각 운임의 vat 항목 총합 값을 구하는 함수
 */
export const getAllTotalVatPriceOfFeeData = (
  freight: TradingStatementElem[],
  domestic: TradingStatementElem[],
  local: TradingStatementElem[],
  inland: TradingStatementElem[],
  other: TradingStatementElem[],
  tax: TradingStatementElem[]
) => {
  let totalVatPriceOfFreightFeeData = 0;
  let totalVatPriceOfDomesticFeeData = 0;
  let totalVatPriceOfLocalFeeData = 0;
  let totalVatPriceOfInlandFeeData = 0;
  let totalVatPriceOfOtherFeeData = 0;
  let totalVatPriceOfTaxFeeData = 0;

  if (freight) {
    totalVatPriceOfFreightFeeData = getTotalVatPriceOfFeeData(freight);
  }
  if (domestic) {
    totalVatPriceOfDomesticFeeData = getTotalVatPriceOfFeeData(domestic);
  }
  if (local) {
    totalVatPriceOfLocalFeeData = getTotalVatPriceOfFeeData(local);
  }
  if (inland) {
    totalVatPriceOfInlandFeeData = getTotalVatPriceOfFeeData(inland);
  }
  if (other) {
    totalVatPriceOfOtherFeeData = getTotalVatPriceOfFeeData(other);
  }
  if (tax) {
    totalVatPriceOfTaxFeeData = getTotalVatPriceOfFeeData(tax);
  }
  return (
    totalVatPriceOfLocalFeeData +
    totalVatPriceOfDomesticFeeData +
    totalVatPriceOfFreightFeeData +
    totalVatPriceOfInlandFeeData +
    totalVatPriceOfOtherFeeData +
    totalVatPriceOfTaxFeeData
  );
};

export const getUnitPriceByCurrencyOfFeeData = (
  exchangeData: ExchangeRate[],
  feeData: TradingStatementElem | WithdrawalRequestDetail
) => {
  const currencyIndex = exchangeData.findIndex((v: ExchangeRate) => {
    return (
      v.currency === feeData.currency ||
      (v.currency === "CNH" && feeData.currency === "CNY")
    );
  });

  if (feeData.currency === "KRW") {
    return feeData.unitPrice;
  }
  if (currencyIndex >= 0) {
    const itemPrice = feeData.unitPrice * exchangeData[currencyIndex].rate;
    return itemPrice;
  }
  return feeData.unitPrice;
};

/**
 * RTon을 계산
 * @param {*} volume // cbm과 volume은 같은 값이다 cbm처럼 m단위로 계산된 volume이어야 함
 * @param {*} weight // ton단위여야함
 */
export const calculateRTonForTradingStatement = (
  bidData: Bid | TrelloBidDetail,
  cbm: string | number,
  ton: string | number
) => {
  let volume = Number(cbm);
  let weight = Number(ton) / 1000;

  if (bidData.freightType === "AIR") {
    volume = 0;
  }
  if (bidData.isExpress && bidData.freightType !== "AIR") {
    weight = Number(ton) / 250;
  }
  if (bidData.wareHouse) {
    if (
      bidData.wareHouse?.nameEN === "YIWU" ||
      bidData.wareHouse?.nameEN === "WEIHAI"
    ) {
      weight = Number(ton) / 1000;
    } else {
      weight = Number(ton) / 250;
    }
  }

  const returnValue = volume > weight ? volume : weight;

  if (bidData.serviceType !== "oceanTicket") {
    if (bidData.freightType === "AIR") {
      return returnValue * 1000;
    }
    if (returnValue < 1) {
      return 1;
    }
    return returnValue;
  }
  return returnValue;
};

export const checkIfTradingStatementCanBeSendToUser = (
  projectStatus: BidProjectStatus
) => {
  if (
    projectStatus === "beforeContactPartner" ||
    projectStatus === "contactingPartner" ||
    projectStatus === "scheduling" ||
    projectStatus === "moving" ||
    projectStatus === "portEntryAndPrepareCustoms"
  ) {
    return true;
  }
  return false;
};

export const checkIfRefundTradingStatementCanBeSend = (
  projectStatus: BidProjectStatus
) => {
  if (
    projectStatus === "beforeContactPartner" ||
    projectStatus === "contactingPartner" ||
    projectStatus === "scheduling"
  ) {
    return true;
  }
  return false;
};

export const checkIfTradingStatementCanBeSendToCustomsBroker = (
  bidData: Bid | TrelloBidDetail
) => {
  if (bidData.projectStatus === "moving") {
    if (bidData.management?.detailStatus === null) {
      return true;
    }
    return false;
  }
  if (
    bidData.projectStatus === "beforeContactPartner" ||
    bidData.projectStatus === "contactingPartner" ||
    bidData.projectStatus === "scheduling"
  ) {
    return true;
  }
  return false;
};

export const checkIsIssuedInvoices = (
  invoice: TradingStatementInvoice | undefined | null
) => {
  if (invoice) {
    if (invoice.issuedInvoices.length >= 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const findExchangeRate = (
  exchangeRateList: ExchangeRate[] | undefined,
  currency: string
) => {
  const specificExchangeRate = exchangeRateList?.find((v) => {
    return v.currency === currency;
  });
  if (specificExchangeRate) {
    return specificExchangeRate.rate === 0
      ? 1
      : Number(specificExchangeRate.rate);
  } else {
    return 1;
  }
};

export const getTradingStatementArrivalData = (
  invoiceData: InvoiceData | undefined,
  management: TrelloBidManagement
) => {
  return invoiceData?.arrivalDate || management.arrivalDate || "";
};

export const getTradingStatementComment = (
  freightType: FreightType,
  invoiceType: InvoiceType
) => {
  if (freightType === "AIR") {
    return "・입금일을 기준으로 해당 월에 계산서 발행됩니다. 변경을 원하시면 담당 매니저님에게 연락 부탁드립니다.";
  }
  if (invoiceType === "refund") {
    return "";
  }
  return "・창고보관료는 반입일로부터 일주일 간 무료입니다 \n.・입금일을 기준으로 해당 월에 계산서 발행됩니다. 변경을 원하시면 담당 매니저님에게 연락 부탁드립니다.";
};

export const getTradingStatementPortName = (
  portId: number,
  portData: Port[]
) => {
  return portData.find((v) => v.id === portId)?.nameEN || "-";
};

const getCbmStringIndex = (string: string) => {
  return string.indexOf(" CBM");
};

const getKgsStringIndex = (string: string) => {
  return string.indexOf(" kgs");
};

const getSlashStringIndex = (string: string) => {
  return string.indexOf("/");
};

export const getInvoiceDataCbm = (string: string) => {
  const cbmIndex = getCbmStringIndex(string);
  return string.substring(0, cbmIndex);
};

export const getInvoiceDataWeight = (string: string, freightType: string) => {
  if (freightType === "AIR") {
    const slashIndex = getKgsStringIndex(string);
    return string.substring(0, slashIndex).replace(",", "");
  }
  const slashIndex = getSlashStringIndex(string);
  return string.substring(slashIndex + 2, string.length - 4).replace(",", "");
};

export const getBidDataCbmOrWeight = (
  bidDetailData: TrelloBidDetail,
  type: "cbm" | "weight"
) => {
  if (bidDetailData.freightType === "LCL") {
    if (bidDetailData.serviceType === "consolidation") {
      return type === "cbm"
        ? bidDetailData.totalCBM
        : bidDetailData.totalWeight;
    }

    return bidDetailData.productsInfo.reduce((acc, cur) => {
      return (acc += cur[type]);
    }, 0);
  }
  return 0;
};

export const getTradingStatementCountryName = (
  countryList: Country[],
  country: string
) => {
  const countryData = countryList.find((n) => {
    return n.name === country;
  });
  if (countryData) {
    if (countryData.nameEN === "Republic of Korea") {
      return "korea";
    }
    return countryData.nameEN;
  }
  return "-";
};

export const getPartnerNameOrBRNNumber = (
  invoiceType: InvoiceType,
  bidDetailData: TrelloBidDetail,
  type: "BRNNumber" | "name"
) => {
  if (invoiceType === "refund") {
    const domesticPartners = bidDetailData.accountPayables.filter((v) => {
      return (
        v.partner?.id === GLOBELINK_KOREA_PARTNER_ID ||
        v.partner?.id === FARECON_DOMESTIC_PARTNER_ID
      );
    });

    const foreignPartners = bidDetailData.accountPayables.filter((v) => {
      return (
        v.partner?.id === GLOBELINK_SHANGHAI_EXPRESS_PARTNER_ID ||
        v.partner?.id === GLOBELINKSHA_PARTNER_ID ||
        v.partner?.id === GLOBELINK_SHANGHAI_FCL_PARTNER_ID ||
        v.partner?.id === FARECON_FOREIGN_PARTNER_ID
      );
    });

    // 국내와 해외 둘 다 있을 경우는 국내가 우선이다.
    // 두개 이상이 있을 때 맨 처음 파트너가 표시되어야 한다.
    if (domesticPartners.length > 0) {
      return type === "name"
        ? domesticPartners[0].partner.name
        : domesticPartners[0].partner.BRNNumber;
    }

    if (foreignPartners.length > 0) {
      return type === "name"
        ? foreignPartners[0].partner.name
        : foreignPartners[0].partner.BRNNumber;
    }
  }

  if (type === "name") {
    return bidDetailData.team.isPrivate
      ? bidDetailData.user.company
      : bidDetailData.team.company;
  }

  return bidDetailData.team.BRN || "";
};

export const REFUND_FREIGHT_FEE_ITEM: TradingStatementElem = {
  key: "bookingCommission",
  item: "BOOKING COMMISSION",
  note: "",
  isVAT: true,
  amount: 1,
  atCost: false,
  currency: "USD",
  itemPrice: 1000,
  unitPrice: 1,
  itemUnitMeasurement: "R.TON",
  isTaxExempt: false,
};

export const WAREHOUSE_RECEIPT_FEE_ITEM: TradingStatementElem = {
  key: "warehouseReceiptFee",
  item: "창고료",
  note: "",
  isVAT: true,
  amount: 1,
  atCost: false,
  currency: "KRW",
  itemPrice: 1,
  unitPrice: 1,
  itemUnitMeasurement: "B/L",
  isTaxExempt: false,
};

export const changeFeeDataStringValueToNumber = (v: InvoiceDataFeeItem[]) => {
  if (v) {
    const feeData = v.map((n) => {
      return {
        ...n,
        amount: n.amount ? Number(n.amount) : 0,
        itemPrice:
          typeof n.itemPrice === "number"
            ? n.itemPrice
            : Number(n.itemPrice.replace(/,/g, "")),
        unitPrice:
          typeof n.unitPrice === "number"
            ? n.unitPrice
            : Number(n.unitPrice.replace(/,/g, "").replace(".00", "")),
        note: n.atCost ? n.note.replace("/실비청구", "") : n.note,
      };
    });
    return feeData;
  }
  return [];
};

export const getWeightOfInvoiceData = (
  string: string,
  freightType: FreightType
) => {
  if (freightType === "AIR") {
    const slashIndex = getKgsStringIndex(string);
    return string.substring(0, slashIndex).replace(",", "");
  }
  const slashIndex = getSlashStringIndex(string);
  return string.substring(slashIndex + 2, string.length - 4).replace(",", "");
};

export const getCbmOfInvoiceData = (string: string) => {
  const cbmIndex = getCbmStringIndex(string);
  return string.substring(0, cbmIndex);
};

export const getInvoiceTypeKR = (invoiceType: string) => {
  switch (invoiceType) {
    case "invoice":
      return "거래명세서";
    case "refund":
      return "부킹 커미션 명세서";
    case "warehouseReceipt":
      return "창고료 명세서";
    case "etcDeposit":
      return "기타입금 명세서";
    default:
      return "-";
  }
};

export const getTotalTaxExemptPriceOfFeeData = (
  feeData: TradingStatementElem[]
) => {
  const taxExemptPriceOfFeeData = feeData.reduce(
    (accumulator, currentValue) => {
      if (currentValue.isTaxExempt) {
        return accumulator + Number(currentValue.itemPrice);
      }
      return accumulator;
    },
    0
  );

  return taxExemptPriceOfFeeData;
};
