import { useCallback, useMemo } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Grid, Typography } from "@mui/material";

import { AdminBidList } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { CountryCode } from "@sellernote/_shared/src/utils/common/country";
import { isNotEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import { getSupplyType } from "@sellernote/_shared/src/utils/forwarding/bid";

import TagForAdminBidList from "./TagForAdminBidList";

function BidBasicItemList(
  adminBidListData: Pick<
    AdminBidList,
    | "startPort"
    | "startViaPort"
    | "endPort"
    | "endViaPort"
    | "incoterms"
    | "startCountry"
    | "endCountry"
    | "endAddress"
    | "startAddress"
    | "freightType"
    | "supply"
  >
) {
  const {
    startPort,
    startViaPort,
    endPort,
    endViaPort,
    incoterms,
    startCountry,
    endCountry,
    endAddress,
    startAddress,
    freightType,
  } = adminBidListData;

  const startCountryAndPortName = useCallback(() => {
    if (incoterms === "CIF" || incoterms === "CFR" || incoterms === "CPT") {
      const startCountryName = !startPort
        ? "-"
        : CountryCode(startPort.country);
      const endViaPortName = !endViaPort
        ? "-"
        : endViaPort.name || endViaPort.nameEN;

      const startPortName = !startPort
        ? "-"
        : `${startPort.name || startPort.nameEN}`;
      return `(${startCountryName} ${startPortName}), ${endViaPortName}`;
    }

    const startCountryName = CountryCode(startCountry);

    if (startPort && isNotEmptyObjectOrArray(startPort)) {
      return `${startCountryName} ${startPort.name || startPort.nameEN}`;
    }

    if (startAddress && startViaPort && isNotEmptyObjectOrArray(startViaPort)) {
      if (incoterms === "EXW" || incoterms === "FCA" || incoterms === "DDP") {
        return `${startCountryName} 내륙(${
          startViaPort.name || startViaPort.nameEN
        })`;
      }

      return `${startCountryName} 내륙(${
        startViaPort.name || startViaPort.nameEN
      })`;
    }
  }, [
    endViaPort,
    incoterms,
    startAddress,
    startCountry,
    startPort,
    startViaPort,
  ]);

  const endCountryAndPortName = useCallback(() => {
    const endCountryName = CountryCode(endCountry);

    if (incoterms === "CIF" || incoterms === "CFR" || incoterms === "CPT") {
      if (startViaPort && isNotEmptyObjectOrArray(startViaPort)) {
        return startViaPort.name || startViaPort.nameEN;
      }

      if (endPort && isNotEmptyObjectOrArray(endPort)) {
        return endPort.name || endPort.nameEN;
      }

      if (endAddress && endViaPort && isNotEmptyObjectOrArray(endViaPort)) {
        return `${endCountryName} 내륙:${endAddress}`;
      }

      return "-";
    }

    if (endPort && isNotEmptyObjectOrArray(endPort)) {
      return `${endCountryName} ${endPort.name || endPort.nameEN}`;
    }

    if (endAddress && endViaPort && isNotEmptyObjectOrArray(endViaPort)) {
      if (incoterms === "EXW" || incoterms === "FCA" || incoterms === "DDP") {
        return ` ${endCountryName} 내륙(${
          endViaPort.name || endViaPort.nameEN
        })`;
      }

      return `${endCountryName} 내륙(${endViaPort.name || endViaPort.nameEN})`;
    }
    return "-";
  }, [endAddress, endCountry, endPort, endViaPort, incoterms, startViaPort]);

  const FreightTypeTag = useMemo(() => {
    if (adminBidListData.freightType === "FCL") {
      return (
        <TagForAdminBidList color="orange">
          {adminBidListData.freightType}
        </TagForAdminBidList>
      );
    }
    if (adminBidListData.freightType === "LCL") {
      return (
        <TagForAdminBidList color="blue">
          {adminBidListData.freightType}
        </TagForAdminBidList>
      );
    }
    return (
      <TagForAdminBidList color="green">
        {adminBidListData.freightType}
      </TagForAdminBidList>
    );
  }, [adminBidListData.freightType]);

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      spacing={0.3}
    >
      <Grid
        item
        container
        direction={"row"}
        spacing={0.5}
        textAlign={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        justifyItems={"center"}
      >
        <Grid item>
          <Typography>{startCountryAndPortName()}</Typography>
        </Grid>

        <Grid item>
          <DoubleArrowIcon sx={{ marginTop: 0.5 }} color={"disabled"} />
        </Grid>

        <Grid item>
          <Typography>{endCountryAndPortName()}</Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        spacing={0.5}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item>
          <TagForAdminBidList color="pink">
            {adminBidListData.incoterms}
          </TagForAdminBidList>
        </Grid>

        <Grid item>{FreightTypeTag}</Grid>

        <Grid item>
          <TagForAdminBidList color="purple">
            {adminBidListData.supply}

            {getSupplyType(freightType)}
          </TagForAdminBidList>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BidBasicItemList;
