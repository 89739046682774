import React from "react";
import { useRecoilValue } from "recoil";

import AUTH_QUERY from "@sellernote/_shared/src/queries/forwarding/AUTH_QUERY";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import LoginForAdmin from "@sellernote/_shared-for-admin/src/components/LoginForAdmin";

export default function Login() {
  const loggedIn = useRecoilValue(
    FORWARDING_AUTH_ATOMS.PARTNER_ADMIN_LOGGED_IN
  );

  const {
    mutate: login,
    ResponseHandler: ResponseHandlerOfLogin,
    isLoading,
  } = AUTH_QUERY.usePartnerAdminLogin();

  return (
    <LoginForAdmin
      title="파트너 로그인"
      login={({ id, password }) => {
        login({ accountId: id, password: password });
      }}
      isLoadingOfLogin={isLoading}
      isLoggedIn={loggedIn}
      ResponseHandlerOfLogin={ResponseHandlerOfLogin}
    />
  );
}
