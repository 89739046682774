import * as SentryReact from "@sentry/react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

import {
  SellernoteAppLanguage,
  SellernoteAppName,
  SellernoteAppRegion,
  SendRequestOptions,
  SendRequestOptionsData,
} from "../types/common/common";
import { MINUTE_AS_MILLISECONDS } from "../utils/common/date";
import { getBaseURLByAPIType } from "../utils/common/etc";
import {
  createReducerWithSagaBundle,
  CreateReducerWithSagaBundleProps,
} from "../utils/common/redux";

import {
  APP_CURRENT_LANGUAGE,
  APP_NAME,
  APP_REGION,
  IS_UNDER_LOCAL_DEVELOPMENT,
} from "../constants";

const API = axios.create({
  baseURL: getDefaultBaseURLByAppName({ appName: APP_NAME }),
  timeout: MINUTE_AS_MILLISECONDS,
  responseType: "json",
});

API.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, requestErrorHandler);

function requestErrorHandler(error: AxiosError<unknown>) {
  if (error.response?.status === 401) {
    return noAuthHandler(error);
  }

  if (checkNeedToErrorReport(error, error.response?.status)) {
    /**
     * axios 요청 관련 에러로도 sentry로 리포트 함
     * (SentryReact지만 Next.js에서도 잘 됨)
     *
     * API관련 오류는 백엔드에서 따로 모니터링하고 있으므로 참고용으로 전송
     */
    SentryReact.captureException(error, {
      tags: {
        errorType: "API",
        level: "info",
      },
    });
  }

  return Promise.reject(error);
}

function checkNeedToErrorReport(
  error: AxiosError<unknown>,
  responseStatus?: number
): boolean {
  if (IS_UNDER_LOCAL_DEVELOPMENT) return false;

  /**
   * XHR요청이 취소 되는 경우
   * (ex. response 전에 페이지 이동 or request 취소)
   */
  if (axios.isCancel(error)) {
    return false;
  }

  /**
   * XHR요청이 abort되는 경우
   * - response 전에 페이지 이동 or request 취소
   * - timeout
   */
  // abort되는 경우를 모두 리포트 하지 않는건 과할 수 있어 보류함
  // if (error.code === "ECONNABORTED") {
  //   return false;
  // }

  if (responseStatus === 404) {
    return false;
  }

  return true;
}

async function noAuthHandler(error: AxiosError<unknown>) {
  const isAppNeedToRefreshToken = getIsAppNeedToRefreshToken();

  const newToken = isAppNeedToRefreshToken ? await getNewToken() : null;

  if (!newToken) {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("refreshToken");

    window.sessionStorage.removeItem("accessToken");
    window.sessionStorage.removeItem("refreshToken");

    const backTo = window?.history?.state?.as;

    if (backTo) {
      window.location.href = `/login?backTo=${backTo}`;
    } else {
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }

  const { config } = error;

  const failedRequestConfig: AxiosRequestConfig = {
    baseURL: config.baseURL,
    method: config.method,
    params: config.params,
    data: config.data,
    url: config.url,
    headers: {
      ...config.headers,
      authorization: `Bearer ${newToken}`,
    },
  };

  return API(failedRequestConfig);
}

async function getNewToken() {
  const isAutoLogin = window.localStorage.getItem("accessToken");

  const accessToken =
    window.localStorage.getItem("accessToken") ||
    window.sessionStorage.getItem("accessToken");
  const refreshToken =
    window.localStorage.getItem("refreshToken") ||
    window.sessionStorage.getItem("refreshToken");

  if (!accessToken || !refreshToken) {
    return null;
  }

  try {
    const { data } = await axios.post(
      `${getDefaultBaseURLByAppName({
        appName: APP_NAME,
        isNew: true,
      })}/auth/refresh`,
      {
        accessToken,
        refreshToken,
      }
    );

    if (isAutoLogin) {
      window.localStorage.setItem("accessToken", data.accessToken);
    } else {
      window.sessionStorage.setItem("accessToken", data.accessToken);
    }

    // 초대내역 모달을 띄우기 위한 flag
    window.localStorage.setItem("needCheckTeamInvitation", "TRUE");

    return data.accessToken;
  } catch (e) {
    return null;
  }
}

export async function sendRequest(options: SendRequestOptions) {
  const headers: {
    authorization?: string;
    pragma?: string;
    locale?: string;
    region?: SellernoteAppRegion;
    lang?: SellernoteAppLanguage;
    "Content-Type"?: string;
  } = {
    pragma: "no-cache",
    locale: options.locale || APP_REGION,
    region: options.region || APP_REGION,
    lang: options.lang || APP_CURRENT_LANGUAGE,
    ...(options.contentType
      ? {
          "Content-Type": options.contentType,
        }
      : {}),
  };

  const accessToken =
    typeof window !== "undefined" &&
    (window.localStorage.getItem("accessToken") ||
      window.sessionStorage.getItem("accessToken"));

  if (accessToken) {
    headers.authorization = `Bearer ${accessToken}`;
  }

  const config: AxiosRequestConfig = {
    method: options.method,
    params: options.params,
    /**
     * TODO: _customPath사용하는 곳 없어지면 삭제 (pathParams를 사용하는 방식으로 수정중)
     * request할때 _customPath를 보내면 그것을 사용
     */
    url: options.data?._customPath || options.path,
    data: getValidRequestData(options.data),
    headers,
    responseType: options.responseType ?? "json",
    signal: options.signal,
  };

  if (options.isLocalMiddleWare) {
    delete config?.headers?.authorization;
    delete config?.headers?.pragma;
    delete config?.headers?.locale;
  }

  // apiType을 특정하는 경우 baseURL을 바꿔준다
  if (options.apiType) {
    config.baseURL = getBaseURLByAPIType(options.apiType);
  }

  return API(config);
}

function getValidRequestData(requestData?: SendRequestOptionsData) {
  if (!requestData) return;

  // 실제 요청 payload와 관계없는 속성은 삭제
  delete requestData.pathParams;
  // TODO: _customPath사용하는 곳 없어지면 삭제 (pathParams를 사용하는 방식으로 수정중)
  delete requestData._customPath;

  return requestData;
}

export function createReducerWithSagaBundleForBoful<
  KEY extends string,
  REQUEST_PAYLOAD,
  RESPONSE_PAYLOAD,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SLICE_STATE extends { [P in string]: any }
>({
  sliceName,
  parentStateName,
  actionTypeKey,
  getRequestOptions,
  customActionBeforeSaga,
  customInitHandler,
  customSuccessHandler,
  customFailureHandler,
  loadingActions,
}: Omit<
  CreateReducerWithSagaBundleProps<
    KEY,
    REQUEST_PAYLOAD,
    RESPONSE_PAYLOAD,
    SLICE_STATE
  >,
  "sendRequestFunction"
>) {
  return createReducerWithSagaBundle<
    KEY,
    REQUEST_PAYLOAD,
    RESPONSE_PAYLOAD,
    SLICE_STATE
  >({
    sendRequestFunction: sendRequest,
    loadingActions,
    sliceName,
    parentStateName,
    actionTypeKey,
    getRequestOptions,
    customActionBeforeSaga,
    customInitHandler,
    customSuccessHandler,
    customFailureHandler,
  });
}

export function createReducerWithSagaBundleForShipDa<
  KEY extends string,
  REQUEST_PAYLOAD,
  RESPONSE_PAYLOAD,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SLICE_STATE extends { [P in string]: any }
>({
  sliceName,
  parentStateName,
  actionTypeKey,
  getRequestOptions,
  customActionBeforeSaga,
  customInitHandler,
  customSuccessHandler,
  customFailureHandler,
  loadingActions,
}: Omit<
  CreateReducerWithSagaBundleProps<
    KEY,
    REQUEST_PAYLOAD,
    RESPONSE_PAYLOAD,
    SLICE_STATE
  >,
  "sendRequestFunction"
>) {
  return createReducerWithSagaBundle<
    KEY,
    REQUEST_PAYLOAD,
    RESPONSE_PAYLOAD,
    SLICE_STATE
  >({
    sendRequestFunction: sendRequest,
    loadingActions,
    sliceName,
    parentStateName,
    actionTypeKey,
    getRequestOptions,
    customActionBeforeSaga,
    customInitHandler,
    customSuccessHandler,
    customFailureHandler,
  });
}

export function createReducerWithSagaBundleForContentAdmin<
  KEY extends string,
  REQUEST_PAYLOAD,
  RESPONSE_PAYLOAD,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SLICE_STATE extends { [P in string]: any }
>({
  sliceName,
  parentStateName,
  actionTypeKey,
  getRequestOptions,
  customActionBeforeSaga,
  customInitHandler,
  customSuccessHandler,
  customFailureHandler,
  loadingActions,
}: Omit<
  CreateReducerWithSagaBundleProps<
    KEY,
    REQUEST_PAYLOAD,
    RESPONSE_PAYLOAD,
    SLICE_STATE
  >,
  "sendRequestFunction"
>) {
  return createReducerWithSagaBundle<
    KEY,
    REQUEST_PAYLOAD,
    RESPONSE_PAYLOAD,
    SLICE_STATE
  >({
    sendRequestFunction: sendRequest,
    loadingActions,
    sliceName,
    parentStateName,
    actionTypeKey,
    getRequestOptions,
    customActionBeforeSaga,
    customInitHandler,
    customSuccessHandler,
    customFailureHandler,
  });
}

export function createReducerWithSagaBundleForAdmin<
  KEY extends string,
  REQUEST_PAYLOAD,
  RESPONSE_PAYLOAD,
  SLICE_STATE extends { [P in string]: any }
>({
  sliceName,
  parentStateName,
  actionTypeKey,
  getRequestOptions,
  customActionBeforeSaga,
  customInitHandler,
  customSuccessHandler,
  customFailureHandler,
  loadingActions,
}: Omit<
  CreateReducerWithSagaBundleProps<
    KEY,
    REQUEST_PAYLOAD,
    RESPONSE_PAYLOAD,
    SLICE_STATE
  >,
  "sendRequestFunction"
>) {
  return createReducerWithSagaBundle<
    KEY,
    REQUEST_PAYLOAD,
    RESPONSE_PAYLOAD,
    SLICE_STATE
  >({
    sendRequestFunction: sendRequest,
    loadingActions,
    sliceName,
    parentStateName,
    actionTypeKey,
    getRequestOptions,
    customActionBeforeSaga,
    customInitHandler,
    customSuccessHandler,
    customFailureHandler,
  });
}

/**
 * App별로 기본 BaseURL를 가져온다
 */
function getDefaultBaseURLByAppName({
  appName,
  isNew,
}: {
  appName: SellernoteAppName;
  isNew?: boolean;
}): string {
  switch (appName) {
    case "boful-admin": {
      return process.env.REACT_APP_API_URL || "";
    }

    case "boful-worker-pda": {
      return process.env.REACT_APP_API_URL || "";
    }

    case "boful-worker-web": {
      return process.env.REACT_APP_API_URL || "";
    }

    case "shipda-admin": {
      return process.env.REACT_APP_BASE_URL || "";
    }

    case "shipda-kr":
    case "bringoodz-web":
    case "shipda-sg": {
      return (
        (isNew
          ? process.env.NEXT_PUBLIC_API_URL_NEW
          : process.env.NEXT_PUBLIC_API_URL) || ""
      );
    }

    case "content-admin": {
      return process.env.REACT_APP_CONTENT_API_URL || "";
    }

    case "partner-admin": {
      return process.env.REACT_APP_API_URL || "";
    }

    default:
      return "";
  }
}

/**
 * 자동로그인이 되면 안되는 앱은 새 토큰을 받아서 재요청하는 처리를 하지 않는다.
 */
function getIsAppNeedToRefreshToken(): boolean {
  switch (APP_NAME) {
    case "content-admin":
    case "partner-admin":
    case "boful-worker-web":
    case "boful-worker-pda":
    case "boful-admin": {
      return false;
    }
    case "bringoodz-web":
    case "shipda-sg":
    case "shipda-kr": {
      return true;
    }

    default:
      return false;
  }
}
