import styled, { css } from "styled-components";

import {
  TooltipBodyTargetInfo,
  ToolTipPositionV2,
  ToolTipTriggerTargetInfo,
} from "@sellernote/_shared/src/headlessComponents/useToolTip";

import { COLOR } from "../../styles/colors";
import { setElevationStyle } from "../../styles/elevation";
import { setFontStyle } from "../../styles/typography";

const container = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: inline-block;

  > .trigger {
    display: flex;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "help")};

    > * {
      /* trigger가 inline 요소면 정상작동 하지 않으므로 변환 */
      display: inline-block;
    }
  }
`;

const tooltipBody = styled.div<{
  isVisibleToolTipBody: boolean;
  position: ToolTipPositionV2;
  triggerTargetInfo: ToolTipTriggerTargetInfo;
  tooltipBodyTargetInfo: TooltipBodyTargetInfo;
  /**
   * Viewport Width. 모바일용 width 계산에 사용
   */
  vw: number;
  isMobile?: boolean;
  width?: number;
}>`
  position: absolute;
  display: inline-flex;
  z-index: 7;
  justify-content: space-between;

  visibility: ${({ isVisibleToolTipBody }) =>
    isVisibleToolTipBody ? "visible" : "hidden"};
  height: auto;
  overflow: ${({ isVisibleToolTipBody }) =>
    isVisibleToolTipBody ? "visible" : "hidden"};
  color: ${COLOR.wh};
  padding: 12px;
  width: ${(props) => {
    if (props.isMobile) {
      // 모바일의 경우 고정 width
      const sideMarginTotal = 32; // 바깥 좌우 마진 16px 반영

      // 모바일의 경우 width가 길어 의도치 않은 가로 스크롤이 생길 수 있음 레이아웃을 해치지 않도록 0으로 지정
      return props.isVisibleToolTipBody ? `${props.vw - sideMarginTotal}px` : 0;
    }

    return props.width ? `${props.width}px` : "max-content";
  }};
  border-radius: 3px;
  background-color: ${COLOR.grayScale_900};
  ${setElevationStyle("level2")};

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background: ${COLOR.grayScale_900};
    box-shadow: 1px -19px 30px -19px rgba(0, 0, 0, 0.05);
    z-index: -1;
    border-radius: 2px;
  }

  ${(props) => {
    if (props.isMobile) {
      /**
       * <'모바일 여부 판단'을 media query로 하지 않는 이유>
       * - React컴포넌트에서 체크하는 값(isMobile)으로 통일해 사용하기 위함
       * - JS로직에서 변수로 사용하기 위함
       */
      return css`
        top: ${props.triggerTargetInfo.height + 4}px;
        // 바깥 좌우 마진 16px 반영
        left: -${props.triggerTargetInfo.left - 16}px;
        &::before {
          display: none;
        }
      `;
    }

    switch (props.position) {
      case "topCenter": {
        return css`
          top: ${props.triggerTargetInfo.height + 8}px;
          left: ${-(props.tooltipBodyTargetInfo.width || 0) / 2 +
          props.triggerTargetInfo.width / 2 -
          5}px;

          &::before {
            content: "";
            top: -4px;
            left: 50%;
          }
        `;
      }
      case "topLeft": {
        return css`
          top: ${props.triggerTargetInfo.height + 8}px;
          left: ${props.triggerTargetInfo.width / 2 - 35}px;

          &::before {
            content: "";
            top: -4px;
            left: 30px;
          }
        `;
      }
      case "topRight": {
        return css`
          top: ${props.triggerTargetInfo.height + 8}px;
          right: ${props.triggerTargetInfo.width / 2 - 35}px;

          &::before {
            content: "";
            top: -4px;
            right: 30px;
          }
        `;
      }
      case "bottomCenter": {
        return css`
          bottom: ${props.triggerTargetInfo.height + 8}px;
          left: ${-(props.tooltipBodyTargetInfo.width || 0) / 2 +
          props.triggerTargetInfo.width / 2 -
          5}px;

          &::before {
            content: "";
            bottom: -4px;
            left: 50%;
          }
        `;
      }
      case "bottomLeft": {
        return css`
          bottom: ${props.triggerTargetInfo.height + 8}px;
          left: ${props.triggerTargetInfo.width / 2 - 35}px;

          &::before {
            content: "";
            bottom: -4px;
            left: 30px;
          }
        `;
      }
      case "bottomRight": {
        return css`
          bottom: ${props.triggerTargetInfo.height + 8}px;
          right: ${props.triggerTargetInfo.width / 2 - 35}px;

          &::before {
            content: "";
            bottom: -4px;
            right: 30px;
          }
        `;
      }
      case "sideRightCenter": {
        return css`
          bottom: ${-(
            (props.tooltipBodyTargetInfo.height || 0) / 2 -
            props.triggerTargetInfo.height / 2
          )}px;
          right: ${props.triggerTargetInfo.width + 8}px;

          &::before {
            content: "";
            bottom: ${(props.tooltipBodyTargetInfo.height || 0) / 2 - 6}px;
            right: -4px;
          }
        `;
      }
      case "sideRightTop": {
        return css`
          top: ${-((props.tooltipBodyTargetInfo.height || 0) - 26) +
          props.triggerTargetInfo.height / 2}px;
          right: ${props.triggerTargetInfo.width + 8}px;

          &::before {
            content: "";
            top: ${(props.tooltipBodyTargetInfo.height || 0) - 32}px;
            right: -4px;
          }
        `;
      }
      case "sideRightBottom": {
        return css`
          bottom: ${-((props.tooltipBodyTargetInfo.height || 0) - 28) +
          props.triggerTargetInfo.height / 2}px;
          right: ${props.triggerTargetInfo.width + 8}px;

          &::before {
            content: "";
            bottom: ${(props.tooltipBodyTargetInfo.height || 0) - 32}px;
            right: -4px;
          }
        `;
      }
      case "sideLeftCenter": {
        return css`
          bottom: ${-(
            (props.tooltipBodyTargetInfo.height || 0) / 2 -
            props.triggerTargetInfo.height / 2
          )}px;
          left: ${props.triggerTargetInfo.width + 8}px;

          &::before {
            content: "";
            bottom: ${(props.tooltipBodyTargetInfo.height || 0) / 2 - 6}px;
            left: -4px;
          }
        `;
      }
      case "sideLeftTop": {
        return css`
          top: ${-((props.tooltipBodyTargetInfo.height || 0) - 26) +
          props.triggerTargetInfo.height / 2}px;
          left: ${props.triggerTargetInfo.width + 8}px;

          &::before {
            content: "";
            top: ${(props.tooltipBodyTargetInfo.height || 0) - 32}px;
            left: -4px;
          }
        `;
      }
      case "sideLeftBottom": {
        return css`
          bottom: ${-((props.tooltipBodyTargetInfo.height || 0) - 28) +
          props.triggerTargetInfo.height / 2}px;
          left: ${props.triggerTargetInfo.width + 8}px;

          &::before {
            content: "";
            bottom: ${(props.tooltipBodyTargetInfo.height || 0) - 32}px;
            left: -4px;
          }
        `;
      }
    }
  }}

  > .contents {
    > .title-container {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      > .icon {
        margin-right: 4px;
      }

      > .title {
        ${setFontStyle("SubHead2")};
      }
    }
  }

  > .close {
    margin-left: 12px;
  }
`;

const desc = styled.div`
  ${setFontStyle("Body3")};

  > .desc-list {
    > .item {
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
`;

export default {
  container,
  tooltipBody,
  desc,
};
