import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../services/query";

import {
  QueryResponseHandlerFailureModalInfo,
  QueryResponseHandlerSuccessModalInfo,
} from "../../components/QueryResponseHandler";

import {
  DELETE_TEAM_REQ,
  DELETE_TEAM_RES,
  GET_ADMIN_TEAM_DETAIL_RES,
  GET_ADMIN_TEAM_LIST_REQ,
  GET_ADMIN_TEAM_LIST_RES,
  GET_ADMIN_TEAM_MEMBER_LIST,
  GET_ADMIN_TEAM_NAME_LIST_REQ,
  GET_ADMIN_TEAM_NAME_LIST_RES,
  UPDATE_ASSIGN_TEAM_MANAGER_REQ,
  UPDATE_ASSIGN_TEAM_MANAGER_RES,
  UPDATE_TEAM_COMMENT_REQ,
  UPDATE_TEAM_COMMENT_REQ_PATH_PARAMS,
  UPDATE_TEAM_COMMENT_RES,
  UPDATE_TEAM_DETAIL_REQ,
  UPDATE_TEAM_DETAIL_REQ_PATH_PARAMS,
  UPDATE_TEAM_DETAIL_RES,
} from "../../api-interfaces/shipda-api/adminTeam";

export const ADMIN_TEAM_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/ADMIN_TEAM_QUERY" }] as const,

  getAdminTeamList: (params: GET_ADMIN_TEAM_LIST_REQ) =>
    [
      {
        ...ADMIN_TEAM_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminTeamList",
      },
    ] as const,

  getAdminTeamDetail: (teamId: number) =>
    [
      {
        ...ADMIN_TEAM_QUERY_KEY_GEN.all()[0],
        teamId,
        entity: "getAdminTeamDetail",
      },
    ] as const,

  getAdminTeamNameList: (params: GET_ADMIN_TEAM_NAME_LIST_REQ) =>
    [
      {
        ...ADMIN_TEAM_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getAdminTeamNameList",
      },
    ] as const,

  getAdminTeamMemberList: (teamId: number) =>
    [
      {
        ...ADMIN_TEAM_QUERY_KEY_GEN.all()[0],
        teamId,
        entity: "getAdminTeamMemberList",
      },
    ] as const,
};

function useGetAdminTeamList({
  params,
  onSuccess,
  enabled,
}: {
  params: GET_ADMIN_TEAM_LIST_REQ;
  onSuccess?: (res: GET_ADMIN_TEAM_LIST_RES) => void;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_TEAM_QUERY_KEY_GEN.getAdminTeamList>,
    GET_ADMIN_TEAM_LIST_RES
  >({
    queryKey: ADMIN_TEAM_QUERY_KEY_GEN.getAdminTeamList(params),
    requestOptions: {
      method: "get",
      path: "/team/list",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    onSuccess,
    enabled,
  });

  return { ...queryResult };
}

function useGetAdminTeamDetail(teamId: number) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_TEAM_QUERY_KEY_GEN.getAdminTeamDetail>,
    GET_ADMIN_TEAM_DETAIL_RES
  >({
    queryKey: ADMIN_TEAM_QUERY_KEY_GEN.getAdminTeamDetail(teamId),
    requestOptions: {
      method: "get",
      path: `/team/${teamId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useUpdateTeamComment() {
  const mutation = useAppMutation<
    UPDATE_TEAM_COMMENT_REQ,
    UPDATE_TEAM_COMMENT_RES,
    UPDATE_TEAM_COMMENT_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ teamId }) => `/team/${teamId}/comments`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useUpdateTeamDetail({
  successModalInfo,
  failureModalInfo,
}: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
}) {
  const mutation = useAppMutation<
    UPDATE_TEAM_DETAIL_REQ,
    UPDATE_TEAM_DETAIL_RES,
    UPDATE_TEAM_DETAIL_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ teamId }) => `/team/${teamId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    successModalInfo,

    failureModalInfo,
  });

  return { ...mutation };
}

function useAssignTeamManager(teamId: number) {
  const mutation = useAppMutation<
    UPDATE_ASSIGN_TEAM_MANAGER_REQ,
    UPDATE_ASSIGN_TEAM_MANAGER_RES
  >({
    requestOptions: {
      method: "put",
      path: `/team/assignManager/${teamId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetAdminTeamNameList(params: GET_ADMIN_TEAM_NAME_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_TEAM_QUERY_KEY_GEN.getAdminTeamNameList>,
    GET_ADMIN_TEAM_NAME_LIST_RES
  >({
    queryKey: ADMIN_TEAM_QUERY_KEY_GEN.getAdminTeamNameList(params),
    requestOptions: {
      method: "get",
      path: "/team",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useDeleteTeam({
  successModalInfo,
  failureModalInfo,
  teamId,
}: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
  teamId: number;
}) {
  const mutation = useAppMutation<DELETE_TEAM_REQ, DELETE_TEAM_RES>({
    requestOptions: {
      method: "delete",
      path: `/team/${teamId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    successModalInfo,

    failureModalInfo,
  });

  return { ...mutation };
}

function useGetAdminTeamMemberList(teamId: number) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof ADMIN_TEAM_QUERY_KEY_GEN.getAdminTeamMemberList>,
    GET_ADMIN_TEAM_MEMBER_LIST
  >({
    queryKey: ADMIN_TEAM_QUERY_KEY_GEN.getAdminTeamMemberList(teamId),
    requestOptions: {
      method: "get",
      path: `/team/member/${teamId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

const ADMIN_TEAM_QUERY = {
  useGetAdminTeamList,
  useGetAdminTeamDetail,
  useUpdateTeamComment,
  useUpdateTeamDetail,
  useAssignTeamManager,
  useGetAdminTeamNameList,
  useDeleteTeam,
  useGetAdminTeamMemberList,
};

export default ADMIN_TEAM_QUERY;
