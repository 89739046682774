import React from "react";

import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import CUSTOMS_ADMIN_TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import { Port } from "@sellernote/_shared/src/types/common/common";

import useTrelloDetail from "../../../../../../hooks/useTrelloDetail";

const PartnerAdminTrelloDetail = ({
  showModal,
  setShowModal,
  bidId,
  portList,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  bidId: number | undefined;
  portList: Port[];
}) => {
  const { data: trelloDetailData } =
    CUSTOMS_ADMIN_TRELLO_BID_QUERY.useGetCustomsTrelloBidDetail({
      bidId,
    });

  const { data: zoneList } = COMMON_QUERY.useGetZoneList();

  const { data: exchangeRateList } = COMMON_QUERY.useGetExchangeRateList({
    all: true,
  });

  const { data: countryList } = COMMON_QUERY.useGetCountryList();

  const { TrelloDetailModal } = useTrelloDetail({
    trelloDetailData,
    zoneList,
    exchangeRateList,
    countryList,
    showModal,
    setShowModal,
    portList,
  });

  return TrelloDetailModal;
};

export default PartnerAdminTrelloDetail;
