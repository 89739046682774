import { useQueryClient } from "react-query";
import { Box, Button, Grid, Typography } from "@mui/material";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import AdminDetailDescription from "@sellernote/_shared-for-admin/src/components/AdminDetailDescription";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

const ShipScheduleConfirmModal = ({
  showsRegisterShipScheduleConfirmModal,
  setShowsRegisterShipScheduleConfirmModal,
  etd,
  eta,
  shipName,
  bidId,
  shipImo,
  trelloDetail,
  onClose,
  voyageNumber,
  cargoReady,
}: {
  showsRegisterShipScheduleConfirmModal: boolean;
  setShowsRegisterShipScheduleConfirmModal: (value: boolean) => void;
  etd: string | null;
  eta: string | null;
  shipName: string | undefined;
  bidId: number;
  shipImo: number;
  trelloDetail: TrelloBidDetail;
  voyageNumber: number | string | null;
  onClose: () => void;
  cargoReady?: string | null;
}) => {
  const queryClient = useQueryClient();

  const { UploadResponseSnackBar, setShowsErrorSnackBar, setErrorMessage } =
    useUploadResponseSnackBar();

  const {
    mutate: registerShipSchedule,
    ResponseHandler: ResponseHandlerOfRegisterShipSchedule,
  } = TRELLO_BID_QUERY.useRegisterShipSchedule({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setShowsRegisterShipScheduleConfirmModal(false);
        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({
            bidId: trelloDetail.id,
          })
        );
      },
      customizeMessage: () => ({
        title: "스케줄이 발송되었습니다.",
      }),
    },
    bidId: trelloDetail.id,
  });

  return (
    <>
      <Modal
        isOpened={showsRegisterShipScheduleConfirmModal}
        handleClose={() => setShowsRegisterShipScheduleConfirmModal(false)}
        modalBody={
          <Box>
            <Typography align="center" variant="h5">
              이 스케줄을 선택하시겠습니까?
            </Typography>

            <AdminDetailDescription
              descriptionValueList={[
                {
                  label: "POL",
                  value: !trelloDetail.startPort
                    ? trelloDetail.startViaPort.nameEN
                    : trelloDetail.startPort.nameEN,
                  gridSize: 12,
                },
                {
                  label: "POD",
                  value: !trelloDetail.endPort
                    ? trelloDetail.endViaPort.nameEN
                    : trelloDetail.endPort.nameEN,
                  gridSize: 12,
                },
                { label: "ETD", value: etd, gridSize: 12 },
                { label: "ETA", value: eta, gridSize: 12 },

                { label: "카고레디", value: cargoReady, gridSize: 12 },
              ]}
            />

            {trelloDetail.freightType !== "AIR" && (
              <AdminDetailDescription
                descriptionValueList={[
                  {
                    label: "모선명",
                    value: !shipName ? "-" : shipName,
                    gridSize: 12,
                  },
                  {
                    label: "항차",
                    value: !voyageNumber ? "-" : voyageNumber,
                    gridSize: 12,
                  },
                  {
                    label: "shipImo",
                    value: !shipImo ? "-" : shipImo,
                    gridSize: 12,
                  },
                ]}
              />
            )}

            {trelloDetail.freightType === "AIR" && (
              <AdminDetailDescription
                descriptionValueList={[
                  {
                    label: "편명",
                    value: !voyageNumber ? "-" : voyageNumber,
                    gridSize: 12,
                  },
                ]}
              />
            )}

            <Grid container justifyContent={"space-between"}>
              <Button onClick={onClose} variant="outlined">
                취소
              </Button>

              <Button
                onClick={() => {
                  if (trelloDetail.freightType === "AIR") {
                    registerShipSchedule({
                      ETD: etd,
                      ETA: eta,
                      voyageNumber,
                      cargoReady,
                    });
                    return;
                  }

                  if (!shipImo) {
                    setErrorMessage("shipIMO가 없습니다.");
                    setShowsErrorSnackBar(true);
                    return;
                  }

                  registerShipSchedule({
                    shipName,
                    shipIMO: shipImo,
                    ETD: etd,
                    ETA: eta,
                    voyageNumber,
                    cargoReady,
                  });
                }}
                variant="contained"
              >
                선택 완료
              </Button>
            </Grid>
          </Box>
        }
      />

      {ResponseHandlerOfRegisterShipSchedule}

      {UploadResponseSnackBar}
    </>
  );
};

export default ShipScheduleConfirmModal;
