import { atom } from "recoil";

const ADMIN_LOGGED_IN = atom<undefined | boolean>({
  key: "forwarding/auth/atoms/ADMIN_LOGGED_IN",
  default: undefined,
});

const PARTNER_ADMIN_LOGGED_IN = atom<undefined | boolean>({
  key: "forwarding/auth/atoms/PARTNER_ADMIN_LOGGED_IN",
  default: undefined,
});

const USER_LOGGED_IN = atom<undefined | boolean>({
  key: "forwarding/auth/atoms/USER_LOGGED_IN",
  default: undefined,
});

const CURRENT_USER_ID = atom<undefined | number>({
  key: "forwarding/auth/atoms/CURRENT_USER_ID",
  default: undefined,
});

export default {
  ADMIN_LOGGED_IN,
  PARTNER_ADMIN_LOGGED_IN,
  USER_LOGGED_IN,
  CURRENT_USER_ID,
};
