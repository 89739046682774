import { LOCAL_STORAGE_KEY_FOR_LATEST_USED_TEAM_ID } from "../utils/common/team";

/**
 * 브라우저에서 사용하는 storage (Local Storage, Session Storage)를 초기화
 * - 이 함수의 사용은 가급적 지양하며, 꼭 써야한다면 유지해야할 것이 있는지 확인하여 exceptionList로 전달해야합니다.
 * @param exceptionList 초기화하지 말아야할 key 리스트
 */
function clearBrowserStorage(exceptionList?: string[]) {
  clearLocalStorage(exceptionList);
  clearSessionStorage(exceptionList);
}

/**
 * Local Storage를 초기화
 * - 이 함수의 사용은 가급적 지양하며, 꼭 써야한다면 유지해야할 것이 있는지 확인하여 exceptionList로 전달해야합니다.
 * @param exceptionList 초기화하지 말아야할 key 리스트
 */
function clearLocalStorage(exceptionList?: string[]) {
  removeAllStorageData(window.localStorage, exceptionList);
}

/**
 * Session Storage를 초기화
 * - 이 함수의 사용은 가급적 지양하며, 꼭 써야한다면 유지해야할 것이 있는지 확인하여 exceptionList로 전달해야합니다.
 * @param exceptionList 초기화하지 말아야할 key 리스트
 */
function clearSessionStorage(exceptionList?: string[]) {
  removeAllStorageData(window.sessionStorage, exceptionList);
}

/**
 * exceptionList를 제외한 storage의 모든 키를 삭제한다
 * @param storage
 * @param exceptionList
 */
function removeAllStorageData(storage: Storage, exceptionList?: string[]) {
  const keyListToRemove = Object.keys(storage).filter(
    (v) => !exceptionList?.includes(v)
  );

  keyListToRemove.forEach((key) => {
    storage.removeItem(key);
  });
}

/**
 * '쉽다 사용자사이트 전용' local storage clear 함수
 * - 쉽다 사용자사이트에서 local storage를 clear할때는 반드시 이 함수를 사용해야함 (예외: 회원탈퇴)
 */
function clearLocalStorageForForwardingShipdaWeb() {
  clearLocalStorage([LOCAL_STORAGE_KEY_FOR_LATEST_USED_TEAM_ID]);
}

export {
  clearBrowserStorage,
  clearLocalStorage,
  clearSessionStorage,
  clearLocalStorageForForwardingShipdaWeb,
};
