import { Port } from "../../types/common/common";
import { TeamBid, TeamSearchLog } from "../../types/forwarding/adminTeam";
import { POBid } from "../../types/forwarding/bid";

import { CountryCode } from "../common/country";

/**
 * 마케팅용으로 사용하는 port code를 반환
 */
function toPortCodeForMarketing(port?: Port) {
  if (!port) return "";

  if (port.type === "air") {
    return `${port.country}${port.code}_${port.type.toUpperCase()}`;
  }

  return `${port.code}_${port.type.toUpperCase()}`;
}

function getPortName(portList: Port[] | undefined, startPortId: number) {
  const portName =
    portList?.find((list: Port) => list.id === startPortId)?.[
      "name" || "nameEN" || "nameC"
    ] || null;

  return portName;
}

function changePortIdToPortName({
  portData,
  portId,
}: {
  portData?: Port[];
  portId: number;
}) {
  if (!portData) return "";

  const portFoundByPortId = portData.find((port) => {
    return portId === port.id;
  });

  if (!portFoundByPortId) return "";

  return portFoundByPortId.name || portFoundByPortId.nameEN;
}

const getPortNameAndOrigin = (port: Port | undefined) => {
  const origin = port?.country ? CountryCode(port?.country) : "";
  const portName = port?.nameEN;

  return { origin, portName };
};

const getPortNameForDetailBidList = (
  infoData: TeamBid | TeamSearchLog | POBid,
  type: "startPortId" | "endPortId",
  portList: Port[] | undefined
) => {
  let origin;
  let name;
  let address = "";

  if (infoData[type]) {
    const portInfo = portList?.find((v) => {
      return v.id === infoData[type];
    });

    origin = getPortNameAndOrigin(portInfo).origin;
    name = getPortNameAndOrigin(portInfo).portName;
  }

  if (type === "startPortId") {
    if (infoData.startAddress) {
      address = `내륙 ${infoData.startAddress}/`;
    }
  }

  if (type === "endPortId") {
    if (infoData.endAddress) {
      address = `내륙 ${infoData.endAddress}/`;
    }
  }

  return `${origin} ${address}${name}`;
};

export {
  toPortCodeForMarketing,
  getPortName,
  changePortIdToPortName,
  getPortNameForDetailBidList,
};
