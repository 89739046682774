import { Dispatch, forwardRef, SetStateAction } from "react";

import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

import InputText, { InputTextBorderType } from "../input/InputText";
import { Time } from ".";

// value, onClick 은 라이브러리에서 지원하는 기본 props 입니다.
function CustomInput(
  {
    value,
    dateValue,
    inputLabel,
    inputBorderType,
    isIncludedTime,
    setDateValue,
    date,
    setDate,
    time,
    setTime,
    onClick,
    ...rest
  }: {
    value?: string;
    dateValue: string;
    inputLabel?: string;
    inputBorderType?: InputTextBorderType;
    isIncludedTime?: boolean;
    setDateValue: (val: string) => void;
    date: Date | null | undefined;
    setDate: Dispatch<SetStateAction<Date | null | undefined>>;
    time: Time;
    setTime: Dispatch<SetStateAction<Time>>;
    onClick?: () => void;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  function getIncludedTimeDate() {
    if (!date) return value;

    const includedTimeDate = date;

    includedTimeDate.setHours(time.hour ?? 0);
    includedTimeDate.setMinutes(time.minute ?? 0);

    if (time.hour && time.minute)
      return `${toFormattedDate(includedTimeDate as Date, "YYYY-MM-DD HH:mm")}`;
    else return `${toFormattedDate(includedTimeDate as Date, "YYYY-MM-DD")}`;
  }

  return (
    <div ref={ref}>
      <InputText
        label={inputLabel}
        name="date"
        borderType={inputBorderType ?? "filled"}
        valueType="string"
        value={
          isIncludedTime
            ? `${toFormattedDate(dateValue, "YYYY-MM-DD HH:mm")}`
            : value
        }
        className="example-custom-input"
        handleFocusIn={onClick}
        icon="calendar"
        visiblePostFix
        {...rest}
      />
    </div>
  );
}

export default forwardRef(CustomInput);
